export const GEO_LOCATION_REQUEST = `
<Trias version="1.2" xmlns="http://www.vdv.de/trias" xmlns:siri="http://www.siri.org.uk/siri" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.vdv.de/trias TRIAS-1.2/Trias.xsd">
    <ServiceRequest>
        <siri:RequestTimestamp>$TIMESTAMP</siri:RequestTimestamp>
        <siri:RequestorRef>$REQUESTOR</siri:RequestorRef>
    <RequestPayload>
        <LocationInformationRequest>
        <InitialInput>
            <!-- <LocationName>Krefeld</LocationName> -->
            <!-- <GeoPosition>
            <Longitude>9.074843232924374</Longitude>
            <Latitude>47.72040435630456</Latitude>
            </GeoPosition> -->
            <GeoRestriction>
            <Circle>
                <Center>
                <Longitude>$LONGITUDE</Longitude>
                <Latitude>$LATITUDE</Latitude>
                </Center>
                <Radius>
                500
                </Radius>
            </Circle>
            </GeoRestriction>
        </InitialInput>
        <Restrictions>
            <Type>stop</Type>
            <LocalityRef>
            08335043;22; #Konstanz
            </LocalityRef>
            <LocalityRef>
            23021671;22; #Kreuzlingen
            </LocalityRef>
            <NumberOfResults>
            20
            </NumberOfResults>
        </Restrictions>
        </LocationInformationRequest>
    </RequestPayload>
    </ServiceRequest>
</Trias>
`

export const TEXT_LOCATION_REQUEST = `
<Trias version="1.2" xmlns="http://www.vdv.de/trias" xmlns:siri="http://www.siri.org.uk/siri" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.vdv.de/trias TRIAS-1.2/Trias.xsd">
    <ServiceRequest>
            <siri:RequestTimestamp>$TIMESTAMP</siri:RequestTimestamp>
            <siri:RequestorRef>$REQUESTOR</siri:RequestorRef>
        <RequestPayload>
        <LocationInformationRequest>
            <InitialInput>
            <LocationName>$LOCATIONNAME</LocationName>
            <!-- <GeoPosition>
                <Longitude>9.074843232924374</Longitude>
                <Latitude>47.72040435630456</Latitude>
            </GeoPosition> -->
            </InitialInput>
            <Restrictions>
            <Type>stop</Type>
            $LOCALITYREF
            <NumberOfResults>
                20
            </NumberOfResults>
            </Restrictions>
        </LocationInformationRequest>
        </RequestPayload>
    </ServiceRequest>
</Trias>
`

export const BUS_LINES_REQUEST = `
<Trias version="1.2" xmlns="http://www.vdv.de/trias" xmlns:siri="http://www.siri.org.uk/siri" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.vdv.de/trias TRIAS-1.2/Trias.xsd">
    <ServiceRequest>
          <siri:RequestTimestamp>$TIMESTAMP</siri:RequestTimestamp>
          <siri:RequestorRef>$REQUESTOR</siri:RequestorRef>
      <RequestPayload>
          
          
        <TripRequest>
            <Origin>
                <LocationRef>
                    <StopPointRef>$OriginStopPointRef</StopPointRef>
                    <LocationName><Text>$OriginStopPointName</Text></LocationName>
                </LocationRef>
                <IndividualTransportOptions>
                    <Mode>walk</Mode>
                    <MaxDistance>900</MaxDistance>
                </IndividualTransportOptions>
                $DepartureTime
                <!-- 2021-10-15T09:00:00 -->
            </Origin>
            <Destination>
                <LocationRef>
                    <StopPointRef>$DestinationStopPointRef</StopPointRef>
                    <LocationName><Text>$DestinationStopPointName</Text></LocationName>
                </LocationRef>
                <IndividualTransportOptions>
                    <Mode>walk</Mode>
                    <MaxDistance>900</MaxDistance>
                </IndividualTransportOptions>
                $ArrivalTime
        </Destination>
            <Params>
                

                $TransferOptions

                <PtModeFilter>
                    <Exclude>true</Exclude>
                    <BusSubmode>regionalBus</BusSubmode>
                    <WaterSubmode>unknown</WaterSubmode>
                </PtModeFilter>

                <!-- <NumberOfResultsBefore>12</NumberOfResultsBefore>
                <NumberOfResultsAfter>1</NumberOfResultsAfter>  -->

                $NumberOfResults

                <IncludeTrackSections>true</IncludeTrackSections>
                <IncludeLegProjection>true</IncludeLegProjection>
                <IncludeEstimatedTimes>true</IncludeEstimatedTimes>
                <IncludeSituationInfo>true</IncludeSituationInfo>
                <IncludeIntermediateStops>true</IncludeIntermediateStops>
            </Params>
        </TripRequest>
      </RequestPayload>
    </ServiceRequest>
  </Trias>
`

export default GEO_LOCATION_REQUEST;
