<template>
  <div class="vertical-component">
    <div class="trip-overview"></div>
    <div
      class="vertical"
      v-bind:style="{
        'min-height': verticalMinHeight,
        display: !detailOpen ? 'block' : 'none',
      }"
    >
      <div class="background-grid"
        v-bind:style="{
          minWidth: gridMinWidth
        }"
      >
        <!-- <div class="left-el">

        </div> -->
        <div class="nowEl" v-if="nowElement" 
        :style="{ top: nowElement, minWidth: gridMinWidth }">
          <div class="slot-label">Jetzt</div>
        </div>
        <div
          v-for="(gridSlot, index) of gridSlots"
          v-bind:key="index"
          class="line"
          v-bind:style="{
            height: gridSlot['height'],
          }"
          v-bind:class="{
            now: gridSlot.type == 'now',
            'fat-line': gridSlot.type == 'fat-line',
          }"
        >
          <div class="slot-label">
            {{ gridSlot.label ? gridSlot.label : "&nbsp;" }}
          </div>
        </div>
      </div>
      <div class="timeslots">
        <div class="slot-wrapper">
          <div class="slot first"
          >
            <!-- v-bind:style="{paddingBottom: usedGridMode.height+'px'}" -->
            <div class="slot-label" v-on:click="$emit('resultsBefore')"
              :tabindex="0"
              @keyup.13="$emit('resultsBefore')"
            >Früher</div>
          </div>
          <div
            class="slot"
            v-for="(trip, index) of computedTrips.trips"
            :key="index"
            v-bind:class="{
              'vertical-dateElement': trip.isDateElement,
            }"
          >
            <div class="slot-label">
              <div v-if="!trip.isDateElement">
                <b>{{ trip.dauerStdMin }}</b>
                <span>{{ trip.zeitenFrom }} - {{ trip.zeitenTo }}</span>
              </div>
              <div v-else>
                <!-- <b>{{ trip.dauerStdMin }}</b> -->
                <span>{{ trip.formattedDate }}</span>
              </div>
            </div>
            <div
              class="times-wrap"
              v-bind:style="{
                marginTop: trip.offsetTopPx,
              }"
              v-on:click="
                openDetailComponent(trip['key']);
                detailOpen = true;
              "
              :tabindex="0"
              @keyup.13="openDetailComponent(trip['key']);
                detailOpen = true;"
              :aria-label="'Verbindung Nr. ' + (index + 1) + ' anzeigen'"
            >
              <div
                v-for="(leg, index) of trip.legs"
                :key="index"
                :style="{
                  top: leg.offsetTopPx,
                  height: leg.heightPx,
                  backgroundColor: leg.iconColor,
                }"
                :class="{
                  'waiting-time': leg.mode && leg.mode == 'waitingTime',
                }"
              >
                <img
                  class="bus-icon-normal"
                  :src="leg.iconSrc"
                  alt=""
                  v-if="leg.icon == 'walk' || leg.icon == 'cycle'"
                />
                <img
                class="bus-icon-contrast"
                  :src="leg.iconContrastSrc"
                  alt=""
                  v-if="leg.icon == 'walk' || leg.icon == 'cycle'"
                />
                {{
                  leg.icon != "walk" && leg.icon != "cycle" ? getInterchangeTranslationFnc(leg.icon) : ""
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="slot-wrapper alternatives">
          <div
            class="slot"
            v-for="(trip, index) of computedTrips.alternatives"
            :key="index"
          >
            <div class="slot-label">
              <div>
                <b>{{ trip.durationStdMin }}</b>
                <span>{{ trip.zeitenFrom }}</span>
              </div>
            </div>
            <div
              class="times-wrap"
              v-bind:style="{
                marginTop: trip.offsetTopPx,
              }"
              
            >
              <div
                v-for="(leg, index) of trip.legs"
                :key="index"
                :style="{
                  top: leg.offsetTopPx,
                  height: leg.heightPx,
                  backgroundColor: leg.iconColor,
                }"
                :class="{
                  'waiting-time': leg.mode && leg.mode == 'waitingTime',
                  'transition-bottom':leg.transitionBottom
                }"
                v-on:click="openLink(trip.link)"
                :tabindex="0"
                @keyup.13="openLink(trip.link)"
              >
                <img
                  :src="leg.iconSrc"
                  alt=""
                  v-if="leg.icon == 'walk' || leg.icon == 'cycle'"
                />
                {{
                  leg.icon != "walk" && leg.icon != "cycle" ? leg.icon : ""
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="after-absolute-bottom">
            <div class="slot-label" v-on:click="$emit('resultsAfter')" :tabindex="0" @keyup.13="$emit('resultsAfter')">Später</div>
        </div>
      </div>
    </div>
    <div class="vertical-detail" v-if="detailOpen">
      <div class="back-button" v-on:click="detailOpen = false" :tabindex="0" @keyup.13="detailOpen = false">
        <div
          class="chevron-left"
        ></div>
        zur Übersicht
      </div>
      <div v-for="(trip, index) of computedTrips.trips" :key="index">
        <list-expansion-component
          v-show-slide="expandedObjects[trip['key']]"
          v-bind:trip="trip"
          v-bind:expandedObjects="expandedObjects"
        >
        </list-expansion-component>
      </div>
    </div>
    <div class="center">
      <LoadSpinnerComponent
        v-if="laterLoading"
      ></LoadSpinnerComponent>
    </div>
  </div>
</template>

<script lang="ts">
import ListExpansionComponent from "./ListExpansionComponent.vue";
import LoadSpinnerComponent from "./LoadSpinnerComponent.vue";
// eslint-disable-next-line no-unused-vars
import {getInterchangeTranslation} from "../services/RequestServiceFile.vue"
export default {
  name: "VerticalComponent",
  components: {
    ListExpansionComponent,
    LoadSpinnerComponent
  },
  props: {
    trips: {
      default: [],
    },
    alternatives: {
      default: [],
    },
    earlierLoading: {
      default: false,
    },
    laterLoading: {
      default: false,
    },
  },
  data: () => {
    return {
      gridSlotMode: 5, //5, 10, 30
      gridSlots: [],
      nowElement: null,
      verticalMinHeight: '77px',
      expandedObjects: {},
      detailOpen: false,
      gridMinWidth: '0px',
      usedGridMode: {height: 20}
    };
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank").focus();
    },
    getInterchangeTranslationFnc(key){
      return getInterchangeTranslation(key);
    },
    openDetailComponent(key) {
      for (let key in this.expandedObjects) {
        delete this.expandedObjects[key];
      }
      this.expandedObjects[key] = true;
    },
  },
  computed: {
    computedTrips: (vue_el) => {
      console.log(`vue_el.alternatives`, vue_el.alternatives);

      console.log("computedTrips called", vue_el.trips);

      // get min max time for building the time grid
      let minStartTime = 0;
      let maxEndTime = 0;

      for (let trip of vue_el.trips) {
        if(typeof trip.isDateElement !== 'undefined') continue;
        let startTime = new Date(
          trip["trias:Trip"]["trias:StartTime"]
        ).getTime();
        let endTime = new Date(trip["trias:Trip"]["trias:EndTime"]).getTime();
        if (startTime < minStartTime || !minStartTime) {
          minStartTime = startTime;
        }
        if (endTime > maxEndTime || !maxEndTime) {
          maxEndTime = endTime;
        }
      }

      for (let alt of vue_el.alternatives) {
        if (alt.startTimeMS < minStartTime) {
          minStartTime = alt.startTimeMS;
        }
      }

      console.log(`minStartTime`, new Date(minStartTime).toISOString());
      console.log(`maxEndTime`, new Date(maxEndTime).toISOString());

      // ### GRID START ###
      const gridModes = [
        {
          durationLessThanMin: 45,
          labelEveryMin: 5,
          stepMin: 1,
          height: 20,
        },
        {
          durationLessThanMin: 60,
          labelEveryMin: 10,
          stepMin: 5,
          height: 50,
        },
        {
          durationLessThanMin: 120,
          labelEveryMin: 30,
          stepMin: 15,
          height: 100,
        },
        {
          durationLessThanMin: 999,
          labelEveryMin: 60,
          stepMin: 30,
          height: 150,
        },
      ];
      // vue_el.usedGridMode = null;



      let durationInMin = Math.ceil((maxEndTime - minStartTime) / 1000 / 60);
      console.log(`durationInMin`, durationInMin);

      let grid = [];
      let hours = new Date(minStartTime).getHours();
      let minutes = new Date(minStartTime).getMinutes();

      // durationInMin = 60;
      let initialMinutesToNextStepMS = 0;
      for (let gridMode of gridModes) {
        if (durationInMin > gridMode.durationLessThanMin) continue;
        vue_el.usedGridMode = gridMode;
        while (minutes % gridMode.stepMin != 0) {
          if (minutes == 0) {
            hours--;
            minutes = 59;
            if (hours == 0) {
              hours = 23;
            }
          } else {
            minutes--;
          }
          initialMinutesToNextStepMS += 60 * 1000;
        }

        console.log(`initialMinutesToNextStepMS`, initialMinutesToNextStepMS);

        for (
          let i = 0;
          i < Math.floor(durationInMin / gridMode.stepMin) + 1;
          i++
        ) {
          let el = {};
          if (minutes % gridMode.labelEveryMin == 0) {
            el["type"] = "fat-line";
            el["label"] =
              ("00" + hours).slice(-2) + ":" + ("00" + minutes).slice(-2);
          }
          el["height"] = gridMode.height;
          grid.push(el);

          minutes += gridMode.stepMin;
          if (minutes == 60) {
            hours++;
            minutes = 0;
          }
          if (hours == 24) {
            hours = 0;
          }
        }
        break;
      }

      vue_el.gridSlots = grid.map((el) => {
        el["height"] = el["height"] + "px";
        return el;
      });
      console.log(`vue_el.gridSlots`, vue_el.gridSlots);
      // ### GRID END ###

      // add height poperties to trips to fit into grid

      let result = vue_el.trips.map((trip) => {
        console.log(`trip`, trip);

        if(typeof trip.isDateElement !== 'undefined') return trip

        //offsetTopPx
        let tripStart = new Date(
          trip["trias:Trip"]["trias:StartTime"]
        ).getTime();
        // initialMinutesToNextStepMS as offset
        let offsetTopMin =
          (tripStart - minStartTime + initialMinutesToNextStepMS) / 1000 / 60;
        console.log(`offsetTopMin`, offsetTopMin);
        let offsetTopPx =
          offsetTopMin * (vue_el.usedGridMode.height / vue_el.usedGridMode.stepMin);
        trip["offsetTopPx"] = offsetTopPx + "px";
        trip["legs"] = trip["legs"].map((leg) => {
          let startTime = new Date(leg["startTime"]).getTime();
          let endTime = new Date(leg["endTime"]).getTime();
          let offsetTopMin = (startTime - minStartTime) / 1000 / 60;
          leg["offsetTopPx"] =
            offsetTopMin * (vue_el.usedGridMode.height / vue_el.usedGridMode.stepMin) + "px";
          leg["heightPx"] =
            ((endTime - startTime) / 1000 / 60) *
              (vue_el.usedGridMode.height / vue_el.usedGridMode.stepMin) +
            "px";
          return leg;
        });

        return trip;
      });

      //NOW ELEMENT start
      let topMS = minStartTime - initialMinutesToNextStepMS;
      console.log(`topMS`, topMS);
      let bottomMS = minStartTime + durationInMin * (60 * 1000);
      let nowMS = new Date().getTime();
      if (minStartTime <= nowMS && nowMS <= bottomMS) {
        console.log(`nowMS - topMS`, nowMS - topMS);
        console.log(
          `((nowMS - topMS) / 1000 / 60)`,
          (nowMS - topMS) / 1000 / 60
        );
        vue_el.nowElement =
          ((nowMS - topMS) / 1000 / 60) *
            (vue_el.usedGridMode.height / vue_el.usedGridMode.stepMin) +
          "px";
      } else {
        vue_el.nowElement = null;
      }
      //NOW ELEMENT end

      console.log(`result`, result);

      console.log(`usedGridMode`, vue_el.usedGridMode);

      // ############ Calculating Alternatives start ############
      let alternativeResult = [];
      if (
        typeof vue_el.alternatives !== "undefined" &&
        Array.isArray(vue_el.alternatives)
      ) {
        alternativeResult = vue_el.alternatives.map((alt) => {
          //Fälle:
          // nur alternativen vorhanden
          // alternativen ab eingegebener uhrzeit anzeigen

console.log(`alt.startTimeMS`, alt.startTimeMS);
console.log(`minStartTime`, minStartTime);
console.log(`initialMinutesToNextStepMS`, initialMinutesToNextStepMS);
          let offsetTopMin =
            (alt.startTimeMS - minStartTime + initialMinutesToNextStepMS) /
            1000 /
            60;
          console.log(`offsetTopMin`, offsetTopMin);
          let offsetTopPx =
            offsetTopMin * (vue_el.usedGridMode.height / vue_el.usedGridMode.stepMin);
          alt["offsetTopPx"] = offsetTopPx + "px";

          let legHeight =
            (alt.durationS / 60) *
              (vue_el.usedGridMode.height / vue_el.usedGridMode.stepMin) 
              // +            "px";

            let transitionBottom = false;
            if((alt.startTimeMS + alt.durationS*1000) > maxEndTime){
              transitionBottom = true;
              legHeight -= (((alt.startTimeMS + alt.durationS*1000) - maxEndTime) / 60 / 1000) *
              (vue_el.usedGridMode.height / vue_el.usedGridMode.stepMin)
            }
            legHeight = legHeight + 'px';


          alt["legs"] = [
            {
              offsetTopPx: "0px",
              heightPx: legHeight,
              iconColor: "var(--swk-grey)",
              borderColor: "var(--swk-grey)",
              iconSrc: alt["iconSrc"],
              icon: "walk",
              transitionBottom
            },
          ];
          console.log(`alt`, alt);
          return alt;
        });
      }
      console.log(`alternativeResult`, alternativeResult);

      // because of overflow-y, overflow-x bug... cant use only one
      vue_el.verticalMinHeight =
      77 //padding for labels
      + ((vue_el.gridSlots.length || 1)+1) * 
      vue_el.usedGridMode.height 
      // +80 //buffer
      +'px'

        // ((maxEndTime - minStartTime + initialMinutesToNextStepMS) / 1000 / 60) *
        //   (Math.floor(vue_el.usedGridMode.height / vue_el.usedGridMode.stepMin) + 1) + 100 + "px";

        // console.log(`vue_el.verticalMinHeight`, vue_el.verticalMinHeight);

      // ############ Calculating Alternatives end ############
      vue_el.gridMinWidth = 80 + result.length * 100 + alternativeResult.length * 100 + 'px';

      return { trips: result, alternatives: alternativeResult };
    },
  },
};
</script>

<style>
</style>