var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-view"},[(_vm.trips && _vm.trips.length)?_c('div',[_c('div',{staticClass:"tr table-header"},[_c('div',{staticClass:"top"},[_vm._m(0),_c('div',{staticClass:"right no-before",attrs:{"tabindex":0},on:{"click":function($event){return _vm.$emit('resultsBefore')},"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13)return null;return _vm.$emit('resultsBefore')}}},[_c('chevron-up',{staticClass:"chevron"}),_vm._v(" Früher ")],1)])]),_vm._l((_vm.trips),function(trip){return _c('div',{key:trip.key,staticClass:"tr"},[(trip.isDateElement)?_c('div',{staticClass:"isDateElement"},[_vm._v(" "+_vm._s(trip.formattedDate)+" ")]):_c('div',[_c('div',{staticClass:"top",attrs:{"tabindex":0},on:{"click":function($event){_vm.expandedObjects[trip['key']] = !_vm.expandedObjects[trip['key']];
            _vm.iterateExpanded++;},"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13)return null;_vm.expandedObjects[trip['key']] = !_vm.expandedObjects[trip['key']];
            _vm.iterateExpanded++;}}},[_c('div',{staticClass:"left"},[_c('div',[_c('b',[_vm._v(_vm._s(trip.zeitenFrom))]),_vm._v(" - "+_vm._s(trip.zeitenTo)+" ")]),_c('div',[_vm._v(_vm._s(trip.dauer))]),_c('div',[_vm._v(" "+_vm._s(trip.umsteigen)+" ")]),_c('div',{staticClass:"verlauf-wrapper"},_vm._l((trip.verlauf),function(ver,index){return _c('div',{key:index,staticClass:"verlauf-item"},[(ver.iconSrc)?_c('div',{staticClass:"verlauf-logo-wrapper",style:({'backgroundColor': ver.iconColor})},[_c('img',{staticClass:"bus-icon-normal",attrs:{"src":ver.iconSrc,"alt":ver.altTag}}),_c('img',{staticClass:"bus-icon-contrast",attrs:{"src":ver.iconContrastSrc,"alt":ver.altTag}})]):_c('div',{staticClass:"alternative-logo",class:{ 'smaller-font': ver.icon && ver.icon.length > 2 }},[_vm._v(" "+_vm._s(ver.icon)+" ")]),(index < trip.verlauf.length - 1)?_c('div',{staticClass:"chevron-wrapper"},[_c('div',{staticClass:"chevron-grey-bg"})]):_vm._e()])}),0)]),(trip.hasSituations)?_c('div',{staticClass:"ex-mark"},[_c('exclamation-mark-red')],1):_vm._e(),_c('div',{staticClass:"right chevron-right",class:{
              rotate: _vm.iterateExpanded && _vm.expandedObjects[trip['key']],
            }})])]),_c('list-expansion-component',{directives:[{name:"show-slide",rawName:"v-show-slide",value:(_vm.expandedObjects[trip['key']]),expression:"expandedObjects[trip['key']]"}],attrs:{"trip":trip,"expandedObjects":_vm.expandedObjects}})],1)}),_c('div',{staticClass:"tr table-header no-border"},[_c('div',{staticClass:"top"},[_vm._m(1),_c('div',{staticClass:"right chevron-down no-before",attrs:{"tabindex":0},on:{"click":function($event){return _vm.$emit('resultsAfter')},"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13)return null;return _vm.$emit('resultsAfter')}}},[_c('chevron-down',{staticClass:"chevron down"}),_vm._v(" Später ")],1)])]),_c('div',{staticClass:"tr table-header center no-border"},[(_vm.laterLoading)?_c('LoadSpinnerComponent'):_vm._e()],1)],2):_vm._e(),(_vm.alternatives && _vm.alternatives.length)?_c('div',[_vm._m(2),_vm._l((_vm.alternatives),function(trip){return _c('div',{key:trip.key,staticClass:"tr"},[_c('div',{staticClass:"top",on:{"click":function($event){return _vm.openLink(trip.link)}}},[_c('div',{staticClass:"left"},[_c('div',[_c('b',[_vm._v(_vm._s(trip.title))])]),_c('div',[_vm._v(_vm._s(trip.duration))]),_c('div'),_c('div',{staticClass:"verlauf-wrapper"},[_c('div',{staticClass:"verlauf-item"},[(trip.iconSrc)?_c('div',{staticClass:"verlauf-logo-wrapper",staticStyle:{"background-color":"#acacac"}},[_c('img',{staticClass:"bus-icon-normal",attrs:{"src":trip.iconSrc}}),_c('img',{staticClass:"bus-icon-contrast",attrs:{"src":trip.iconContrastSrc}})]):_vm._e()])])]),_c('div',{staticClass:"right chevron-right"})])])})],2):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',[_vm._v("Zeiten")]),_c('div',[_vm._v("Dauer")]),_c('div',[_vm._v("Umsteigen")]),_c('div',[_vm._v("Verlauf")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div'),_c('div'),_c('div'),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr table-header"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"left"},[_c('div',[_vm._v("Alternativen")]),_c('div',[_vm._v("Dauer")]),_c('div',[_vm._v(" ")]),_c('div',[_vm._v("Verlauf")])]),_c('div',{staticClass:"right no-before"})])])
}]

export { render, staticRenderFns }