<template>
  <div class="list-view">
    <div v-if="trips && trips.length">
      <div class="tr table-header">
        <div class="top">
          <div class="left">
            <div>Zeiten</div>
            <div>Dauer</div>
            <div>Umsteigen</div>
            <div>Verlauf</div>
          </div>
          <div class="right no-before" v-on:click="$emit('resultsBefore')" :tabindex="0" @keyup.13="$emit('resultsBefore')">
            <chevron-up class="chevron"></chevron-up>
            Früher
          </div>
        </div>
      </div>
      <div class="tr" v-for="trip in trips" v-bind:key="trip.key">
        <div class="isDateElement" v-if="trip.isDateElement">
          {{ trip.formattedDate }}
        </div>
        <div v-else>
          <div
            class="top"
            v-on:click="
              expandedObjects[trip['key']] = !expandedObjects[trip['key']];
              iterateExpanded++;
            "
            :tabindex="0"
            @keyup.13="
              expandedObjects[trip['key']] = !expandedObjects[trip['key']];
              iterateExpanded++;"
          >
            <div class="left">
              <div>
                <b>{{ trip.zeitenFrom }}</b> - {{ trip.zeitenTo }}
              </div>
              <div>{{ trip.dauer }}</div>
              <div>
                {{ trip.umsteigen }}
              </div>
              <div class="verlauf-wrapper">
                <div
                  class="verlauf-item"
                  v-for="(ver, index) of trip.verlauf"
                  v-bind:key="index"
                  
                >
                  <div v-if="ver.iconSrc" 
                    class="verlauf-logo-wrapper"
                    v-bind:style="{'backgroundColor': ver.iconColor}"
                  >
                    <img class="bus-icon-normal" :src="ver.iconSrc" :alt="ver.altTag"/>
                    <img class="bus-icon-contrast" :src="ver.iconContrastSrc" :alt="ver.altTag"/>

                  </div>
                  <div
                    v-else
                    class="alternative-logo"
                    :class="{ 'smaller-font': ver.icon && ver.icon.length > 2 }"
                  >
                    {{ ver.icon }}
                  </div>
                  <div
                    class="chevron-wrapper"
                    v-if="index < trip.verlauf.length - 1"
                  >
                    <div class="chevron-grey-bg"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ex-mark" v-if="trip.hasSituations">
            <exclamation-mark-red></exclamation-mark-red>
          </div>
            <div
              class="right chevron-right"
              :class="{
                rotate: iterateExpanded && expandedObjects[trip['key']],
              }"
            ></div>
          </div>
        </div>

        <list-expansion-component
          v-show-slide="expandedObjects[trip['key']]"
          v-bind:trip="trip"
          v-bind:expandedObjects="expandedObjects"
        >
        </list-expansion-component>
      </div>
      <div class="tr table-header no-border">
        <div class="top">
          <div class="left">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="right chevron-down no-before" v-on:click="$emit('resultsAfter')" :tabindex="0" @keyup.13="$emit('resultsAfter')">
            <chevron-down class="chevron down"></chevron-down>
            Später
          </div>
        </div>
      </div>
      <div class="tr table-header center no-border">
        <LoadSpinnerComponent
          v-if="laterLoading"
        ></LoadSpinnerComponent>
      </div>
    </div>
    <!-- alternatives start -->
    <div v-if="alternatives && alternatives.length">
      <div class="tr table-header">
        <div class="top">
          <div class="left">
            <div>Alternativen</div>
            <div>Dauer</div>
            <div>&nbsp;</div>
            <div>Verlauf</div>
          </div>
          <div class="right no-before">

          </div>
        </div>
      </div>
      <div class="tr" v-for="trip in alternatives" v-bind:key="trip.key">
        <div class="top" v-on:click="openLink(trip.link)">
          <div class="left">
            <div>
              <b>{{ trip.title }}</b>
            </div>
            <div>{{ trip.duration }}</div>
            <div></div>
            <div class="verlauf-wrapper" >
              <div class="verlauf-item">
                <div class="verlauf-logo-wrapper" v-if="trip.iconSrc" style="background-color:#acacac">
                  <img class="bus-icon-normal" :src="trip.iconSrc" />
                  <img class="bus-icon-contrast" :src="trip.iconContrastSrc" />
                </div>
              </div>
            </div>
          </div>
          <div class="right chevron-right"></div>
        </div>
      </div>
    </div>
    <!-- alternatives end -->
  </div>
</template>

<script lang="ts">
import ListExpansionComponent from "./ListExpansionComponent.vue";
import LoadSpinnerComponent from "./LoadSpinnerComponent.vue";
import {
  ExclamationMarkRed,
  ChevronUp,
  ChevronDown,
} from "./SvgComponent.vue";
export default {
  components: {
    ListExpansionComponent,
    ExclamationMarkRed,
    ChevronUp,
    ChevronDown,
    LoadSpinnerComponent
  },
  name: "ListComponent",
  props: {
    trips: {
      default: [],
    },
    alternatives: {
      default: [],
    },
    earlierLoading: {
      default: false,
    },
    laterLoading: {
      default: false,
    },
  },
  data: function () {
    return {
      iterateExpanded: 1,
      expandedObjects: {},
      expandedIntermediates: {},
    };
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank").focus();
    },
  },
};
</script>

<style>
</style>