<template>
  <div>
    <SnackBarComponent ref="snackbar">
    </SnackBarComponent>
    <div class="timetable-information" ref="tti">
      <div class="header">
        <h3 role="contentinfo" 
          :tabindex="0" 
        >Verbindungsauskunft <span class="sr-only">
          Drücke Tabulator um zur Eingabe der Zielhaltestelle zu gelangen.
        </span></h3>
      </div>
      <div class="search-mask">
        <div class="from-to">
          <div class="elementor-field-group">
            <label v-if="selected_from_item" for="tti-from-select" class="elementor-field-label" role="contentinfo">Von</label>

            <vue-select
              :options="from_items"
              :filter="filterFromOptions"
              v-model="selected_from_item"
              v-on:option:selected="paramsChangedSinceRequestFnc()"
              v-on:option:option:deselected="paramsChangedSinceRequestFnc()"
              placeholder="Von"
              class="from-to-select"
              id="tti-from-select"
              :class="{'vs-search-padding-top': selected_from_item}"
              >
              <!-- @tab="handleTab"
              @focus="handleTab" -->
            <!-- <template #selected-option="{ label }">
              <div style="display: flex; align-items: baseline" :tabindex="0" aria-label="testlabel">
                <strong>{{ label }}</strong>
              </div>
            </template>
            <template
              #selected-option-container="{ option }"
            >
              <div class="vs__selected" aria-label="testtesttest">{{ option.label }}</div>
            </template> -->

              <span slot="no-options"
                >Leider finden wir keine übereinstimmende Optionen.</span
              >
            </vue-select>
            <div
              :tabindex="0"
              class="from-to-icon"
              id="from-gps"
              @click="locationFromGPS()"
              @keyup.13="locationFromGPS()"
              :class="{'icon-hidden': gpsLoading}"
              role="button"
              aria-label="Eigener Standort als Startpunkt festlegen"
            >
              <LoadSpinnerComponent
                v-if="gpsLoading"
              ></LoadSpinnerComponent>
            </div>

          </div>
          <div class="elementor-field-group">
            <label v-if="selected_to_item" for="tti-to-select" class="elementor-field-label">Nach</label>

            <vue-select
              :options="to_items"
              :filter="filterToOptions"
              v-model="selected_to_item"
              v-on:option:selected="paramsChangedSinceRequestFnc()"
              v-on:option:option:deselected="paramsChangedSinceRequestFnc()"
              placeholder="Nach"
              id="tti-to-select"
              :class="{'vs-search-padding-top': selected_to_item}"
            >
              <span slot="no-options"
                >Leider finden wir keine übereinstimmende Optionen.</span
              >
            </vue-select>
            <div
              :tabindex="0"
              class="from-to-icon"
              role="button"
              id="loc-vice-versa"
              @click="interchangeLocations"
              @keyup.13="interchangeLocations"
              aria-label="Start- und Zielhaltestelle tauschen"
            ></div>
          </div>
        </div>
        <div class="date">
          <div class="abfahrt-ankunft">
            <div class="toggle">
              <div
                :tabindex="0"
                class="input-wrapper"
                @click="
                  abfahrtAnkunft = 'Abfahrt';
                  paramsChangedSinceRequestFnc();
                "
                @keyup.13="
                  abfahrtAnkunft = 'Abfahrt';
                  paramsChangedSinceRequestFnc();
                "
                v-bind:class="{ 'toggle-active': abfahrtAnkunft == 'Abfahrt' }"
              >
                <label for="Abfahrt">Abfahrt</label>
              </div>
              <div
                :tabindex="0"
                class="input-wrapper"
                @click="
                  abfahrtAnkunft = 'Ankunft';
                  paramsChangedSinceRequestFnc();
                "
                @keyup.13="
                  abfahrtAnkunft = 'Ankunft';
                  paramsChangedSinceRequestFnc();
                "
                v-bind:class="{ 'toggle-active': abfahrtAnkunft == 'Ankunft' }"
              >
                <label for="Ankunft">Ankunft</label>
              </div>
            </div>
          </div>
          <div class="date-time">

            <div class="date-input-container">
              <label for="tti-date" class="elementor-field-label date-label">
                Datum</label
              >
              <flat-pickr v-if="date" id="tti-date" v-model="date"  v-bind:config="flatPickrConfig" placeholder="Wähle ein Datum" class="date date-input"></flat-pickr>
              <span class="date-after" 
                  ></span>
            </div>
            <!-- DATE -->
            <!-- <v-menu
              ref="date_menu"
              v-model="date_menu"
              :close-on-content-click="false"
              :return-value.sync="date_menu"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="date-input-container">
                  <label for="tti-date" class="elementor-field-label date-label">
                    Datum</label
                  >
                  <input
                    type="text"
                    class="date date-input"
                    v-model="dateFormatted"
                    id="tti-date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @keyup.13="toggleDatePicker()"
                    @click="toggleDatePicker()"
                    />
                  <span class="date-after" 
                  ></span>
                </div>
              </template>
              <v-date-picker
                v-model="date"
                id="tti-v-date-picker"
                no-title
                scrollable
                locale="de-de"
                color="var(--swk-primary)"
                v-on:change="paramsChangedSinceRequestFnc()"
                :nextMonthAriaLabel="'Nächster Monat'"
                :prevMonthAriaLabel="'Vorheriger Monat'"
                :nextYearAriaLabel="'Nächstes Jahr'"
                :prevYearAriaLabel="'Vorheriges Jahr'"
                :tabindex="0"   
              >
                <v-spacer></v-spacer>
                <v-btn text color="var(--swk-primary)" @click="date_menu = false"> OK </v-btn>
              </v-date-picker>
            </v-menu> -->
            <!-- DATE END -->

            <!-- TIME -->
            <!-- <v-menu
              ref="time_menu"
              v-model="time_menu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time_menu"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }"> -->
                <div class="time-input-container">
                  <span v-on:click="changeTime(false)" class="minus" 
                    role="button"
                    :tabindex="0"
                    aria-label="Zeit 15 Minuten früher"
                    @keyup.13="changeTime(false)"
                  ></span>
                    <!-- v-bind="attrs" -->
                    <!-- v-on="on" -->
                  <input            
                    type="time"
                    class="time"
                    id="tti-time"
                    v-model="time"
                    required
                    v-on:change="checkTime($event)"
                  />
                    <!-- v-on:click="focus" -->
                  <label for="tti-time" class="elementor-field-label time-label"
                    >Zeit</label
                  >
                  <span class="plus" v-on:click="changeTime(true)" 
                    :tabindex="0"
                    role="button"
                    aria-label="Zeit 15 Minuten später"
                    @keyup.13="changeTime(true)"
                  ></span>
                </div>
              <!-- </template>
              <v-time-picker
                v-if="time_menu"
                v-model="time"
                full-width
                color="var(--swk-primary)"
                format="24hr"
                v-on:change="paramsChangedSinceRequestFnc()"
              ></v-time-picker>
            </v-menu> -->
            <!-- TIME END -->
          </div>
        </div>
        <div class="date">
          <!-- <div class="show-options" v-on:click="showOptions = !showOptions">
            <span>{{
              showOptions ? "Optionen ausblenden" : "mehr Optionen"
            }}</span
            ><span class="plus">></span>
          </div> -->
       
          <div class="send elementor-button-wrapper three-buttons">
            <div @click="setTimeAndDateNow(1)" @keyup.13="setTimeAndDateNow(1)" v-bind:class="{'touched': threeButtonsTouchedIndex == 1}" :tabindex="0">Jetzt</div>
            <div @click="setTimeAndDateNow(2)" @keyup.13="setTimeAndDateNow(2)" v-bind:class="{'touched': threeButtonsTouchedIndex == 2}" :tabindex="0">In 15 min</div>
            <div @click="setTimeAndDateNow(3)" @keyup.13="setTimeAndDateNow(3)" v-bind:class="{'touched': threeButtonsTouchedIndex == 3}" :tabindex="0">In 30 min</div>
            <!-- <button
              type="button"
              class="elementor-button-link btn-primary btn-outline custom-outline"
              v-on:click="setTimeAndDateNow()"
            >
              Jetzt
            </button> -->
          </div>
          <div class="send elementor-button-wrapper">
            <button
              type="button"
              class="elementor-button-link elementor-button elementor-size-sm"
              v-on:click="getTripInformation()"
            >
              Verbindung finden
            </button>
          </div>
        </div>
        <!-- <div class="options" v-show-slide="showOptions">
          <div>
            <div class="opt-head">Umsteigezeit</div>
            <label for="slow" class="checkbox_label">
              <input
                @change="paramsChangedSinceRequestFnc()"
                v-model="transferOptions.WalkSpeed"
                value="50"
                type="radio"
                id="slow"
              />
              Langsames Umsteigen
            </label>
            <label for="normal" class="checkbox_label">
              <input
                @change="paramsChangedSinceRequestFnc()"
                v-model="transferOptions.WalkSpeed"
                value="100"
                type="radio"
                id="normal"
              />
              Normales Umsteigen
            </label>
            <label for="fast" class="checkbox_label">
              <input
                @change="paramsChangedSinceRequestFnc()"
                v-model="transferOptions.WalkSpeed"
                value="150"
                type="radio"
                id="fast"
              />
              Schnelles Umsteigen
            </label>
          </div>

          <div>
            <div class="opt-head">Mobilitätseinschränkungen</div>
            <label for="NoSingleStep" class="checkbox_label">
              <input
                @change="paramsChangedSinceRequestFnc()"
                v-model="transferOptions.NoSingleStep"
                type="checkbox"
                id="NoSingleStep"
              />
              keine Stufen
            </label>
            <label for="NoStairs" class="checkbox_label">
              <input
                @change="paramsChangedSinceRequestFnc()"
                v-model="transferOptions.NoStairs"
                type="checkbox"
                id="NoStairs"
              />
              keine Treppen
            </label>
            <label for="NoEscalator" class="checkbox_label">
              <input
                @change="paramsChangedSinceRequestFnc()"
                v-model="transferOptions.NoEscalator"
                type="checkbox"
                id="NoEscalator"
              />
              keine Rolltreppen
            </label>
            <label for="NoElevator" class="checkbox_label">
              <input
                @change="paramsChangedSinceRequestFnc()"
                v-model="transferOptions.NoElevator"
                type="checkbox"
                id="NoElevator"
              />

              kein Lift
            </label>
          </div>

          <div>
            <div class="opt-head">Mobilitätseinschränkungen</div>
            <label for="NoRamp" class="checkbox_label">
              <input
                @change="paramsChangedSinceRequestFnc()"
                v-model="transferOptions.NoRamp"
                type="checkbox"
                id="NoRamp"
              />
              keine Rampen
            </label>
            <label for="LevelEntrance" class="checkbox_label">
              <input
                @change="paramsChangedSinceRequestFnc()"
                v-model="transferOptions.LevelEntrance"
                type="checkbox"
                id="LevelEntrance"
              />
              benötigt ebenen Zugang
            </label>
            <label for="BikeTransport" class="checkbox_label">
              <input
                @change="paramsChangedSinceRequestFnc()"
                v-model="transferOptions.BikeTransport"
                type="checkbox"
                id="BikeTransport"
              />
              Fahrrad transport benötigt
            </label>
          </div>
        </div> -->
      </div>
      <div>
        <!-- title / Toggle -->
        <div v-if="trips.length" class="verbindungen-toggle" ref="result">
          <div class="verbindungen-heading">Verbindungen</div>
          <div class="toggle">
            <div
              :tabindex="0"
              class="input-wrapper"
              aria-label="Listenansicht"
              role="button"
              v-on:click="listType = 'list'"
              @keyup.13="listType = 'list'"
              v-bind:class="{ 'toggle-active': listType == 'list' }"
            >
              <label for="Abfahrt"
                :class="{
                  'fill-svg-white': listType == 'list',
                  'fill-svg-black': listType == 'vertical',
                }"
                >
                <abfahrt-button
                ></abfahrt-button
              ></label>
            </div>
            <div
              :tabindex="0"
              class="input-wrapper"
              role="button"
              aria-label="Verlaufsansicht"
              v-on:click="listType = 'vertical'"
              @keyup.13="listType = 'vertical'"
              v-bind:class="{ 'toggle-active': listType == 'vertical' }"
            >
              <label for="Ankunft"
                ><ankunft-button
                  v-bind:class="{
                    'fill-svg-white': listType == 'vertical',
                    'fill-svg-black': listType == 'list',
                  }"
                ></ankunft-button
              ></label>
            </div>
          </div>
        </div>
        <transition name="fade">
          <div v-if="errors.length">
            <div
              class="err"
              v-for="(error, index) of errors"
              v-bind:key="index"
            >
              <exclamation-mark-red></exclamation-mark-red>
              <span>{{ error }}</span>
            </div>
          </div>
        </transition>
        <div class="result-loader-wrapper" v-if="resultLoading">
          <LoadSpinnerComponent
              v-if="resultLoading"
            ></LoadSpinnerComponent>
        </div>
        <transition name="fade">
          <list-component
            @resultsBefore="resultsAfterBefore(false)"
            @resultsAfter="resultsAfterBefore(true)"
            v-if="(trips.length || alternatives.length) && listType == 'list'"
            v-bind:trips="trips"
            v-bind:alternatives="alternatives"
            v-bind:laterLoading="laterLoading"
          ></list-component>
        </transition>
        <transition name="fade">
          <vertical-component
            @resultsBefore="resultsAfterBefore(false)"
            @resultsAfter="resultsAfterBefore(true)"
            v-if="trips.length && listType == 'vertical'"
            v-bind:trips="trips"
            v-bind:alternatives="alternatives"
            v-bind:laterLoading="laterLoading"
          ></vertical-component>
        </transition>
        <!-- <transition name="fade">
          <traffic-news-component></traffic-news-component>
        </transition> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { RequestService } from "../services/RequestServiceFile.vue";
import ListComponent from "./ListComponent.vue";
import VerticalComponent from "./VerticalComponent.vue";
// import TrafficNewsComponent from "./TrafficNewsComponent.vue";
import { throttle } from "throttle-debounce";
import {
  AnkunftButton,
  AbfahrtButton,
  ExclamationMarkRed,
} from "./SvgComponent.vue";
import
  LoadSpinnerComponent
 from "./LoadSpinnerComponent.vue";
import
SnackBarComponent
 from "./SnackBarComponent.vue";

import * as smoothscroll from "smoothscroll-polyfill";
// import * as trias from 'trias-client';
// import { Notify } from 'notiflix/build/notiflix-notify-aio'
// import notiflix css
// import 'notiflix/build/notiflix-.css'

import { requestResultWithRemainInVehicle } from "./DebugVars.vue";

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {German} from 'flatpickr/dist/l10n/de.js';

// import RequestService from '../services/RequestService.vue';
export default {
  name: "TimeTableInformation",
  props: {
    msg: String,
  },
  components: {
    LoadSpinnerComponent,
    SnackBarComponent,
    ListComponent,
    VerticalComponent,
    // TrafficNewsComponent,
    // svgs
    AnkunftButton,
    AbfahrtButton,
    ExclamationMarkRed,
    flatPickr
    //debug
  },
  data() {
    return {
      flatPickrConfig: {
        locale: German,
        altInput: true,
        altFormat: "d.m.Y",
        ariaDateFormat: "d.m.Y",
        onReady: function() {
        },
        // dateFormat: "M j, Y",
        onChange: (selectedDates, dateStr, instance) => {
          // document.getElementById("tti-time").focus();
          instance.close();
        },
      },
      threeButtonsTouchedIndex: 1,
      gpsLoading: false,
      resultLoading: false,
      laterLoading: false,
      debug: false,
      date_menu: false,
      time_menu: false,
      modal2: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000),
      time:
        ("0" + new Date().getHours()).slice(-2) +
        ":" +
        ("0" + new Date().getMinutes()).slice(-2),
      rs: new RequestService(),

      //autocomplete stuff
      from_items: [],
      from_search: "Konstanz",
      selected_from_item: null,

      to_items: [],
      to_search: "Konstanz",
      selected_to_item: null,

      trias_client: null,
      snackbar_text: "",
      snackbar_count: 0,
      abfahrtAnkunft: "Abfahrt",
      trips: [],
      alternatives: [],
      listType: "list", // vertical

      //umsteigeoptionen
      showOptions: false,
      transferOptions: {
        // slow: false,
        // normal: false,
        WalkSpeed: 100,

        NoSingleStep: false, // kann keine stufe bewältigen
        NoStairs: false, //kann keine treppen bewältigen
        NoEscalator: false, // kann keine rolltreppe benutzen
        NoElevator: false, //kann keinen aufzug benutzen
        NoRamp: false, // kann keine rampe bewältigen
        LevelEntrance: false, // braucht ebenen zugang
        BikeTransport: false, //will fahrrad mitnehmen
      },

      paramsChangedSinceRequest: false,

      errors: [],

      //data attributes
      onlysearch: 0,
      farespage: null,
      timetablepage: null,
      routedFromStartPage: false,
    };
  },
  methods: {
    handleTab() {
      const input = this.$refs.select.$el.querySelector('.vs__search');
      input.setAttribute('aria-label', 'Selected option: ' + input.value);
    },
    checkTime(){
      this.threeButtonsTouchedIndex = 0;
    },
    fixTimeResetIOS(){
      if(!this.time || this.time == ""){
        this.time = ("0" + new Date().getHours()).slice(-2) +
          ":" +
          ("0" + new Date().getMinutes()).slice(-2);
      }
    },
    setTimeAndDateNow(threeButtonsTouchedIndex){
      let offset = 0
      this.threeButtonsTouchedIndex = threeButtonsTouchedIndex;
      if(threeButtonsTouchedIndex == 1){
        offset = 0
      } else if(threeButtonsTouchedIndex == 2){
        offset = 15 * 60 * 1000
      } else {
        offset = 30 * 60 * 1000
      }

      const ms = (Date.now() + offset)
      this.date = new Date(ms - (new Date()).getTimezoneOffset() * 60000)
      this.time =
        ("0" + new Date(ms).getHours()).slice(-2) +
        ":" +
        ("0" + new Date(ms).getMinutes()).slice(-2);
    },
    focus($event){
      $event.srcElement.click()
      console.log(`$event`, $event);
    },
    interchangeLocations() {
      let intermediateEl = this.selected_to_item;
      this.selected_to_item = this.selected_from_item;
      this.selected_from_item = intermediateEl;
      const tmpSwapAutocompleteElements = Object.assign([],this.from_items);
      this.from_items = this.to_items;
      this.to_items = tmpSwapAutocompleteElements;

    },
    paramsChangedSinceRequestFnc() {
      this.paramsChangedSinceRequest = true;
    },
    async resultsAfterBefore(after = false) {
      console.log(`after`, after);
      this.errors = [];
      if (after) {
        this.laterLoading = true;
      } else {
        this.resultLoading = true;
      }
      if (this.paramsChangedSinceRequest) {
        await this.getTripInformation();
        return;
      }

      this.paramsChangedSinceRequest = false;

      try {
        if (this.trips.length) {
          console.log(`this.trips`, this.trips);
          let index = 0;
          if(!after && this.trips[0]["isDateElement"]){
            index = 1;
          }
          // let startEnd = "startTime";
          if (after) {
            index =
              this.trips.filter(
                (el) => typeof el["isDateElement"] === "undefined"
              ).length - 1;
          }
          
          let arrivalTime = !after?this.trips[index]["endTime"]:null;
          let departureTime = after?this.trips[index]["startTime"]:null;

          let result = await this.rs.getTripRequest(
            this.selected_from_item,
            this.selected_to_item,
            this.transferOptions,
            departureTime,
            arrivalTime,
          );
          result = this.rs.parseTRIASBodyToArrayTrip(
            result,
            this.selected_from_item
          );
          if (after) {
            this.trips.push(...result);
          } else {
            this.trips.unshift(...result);
          }

          //remove duplicates
          console.log(`this.trips before remove`, this.trips);
          let seenElement = [];
          let indexToRemove = [];
          for (let i = 0; i < this.trips.length; i++) {
            if (typeof this.trips[i]["isDateElement"] !== "undefined") continue;
            let indexStr =
              "" +
              this.trips[i]["trias:Trip"]["trias:StartTime"] +
              this.trips[i]["trias:Trip"]["trias:Distance"] +
              this.trips[i]["trias:Trip"]["trias:Duration"];
            console.log(`indexStr`, indexStr);
            if (seenElement.includes(indexStr)) {
              indexToRemove.push(i);
            } else {
              seenElement.push(indexStr);
            }
          }
          indexToRemove = indexToRemove.reverse();
          console.log(`indexToRemove`, indexToRemove);
          for (let i = 0; i < indexToRemove.length; i++) {
            this.trips.splice(indexToRemove[i], 1);
          }

          if (!this.trips.length) {
            this.showNoResultsError()
          }

          this.addTripDatesIfNotRequestedDay();

          console.log(`this.trips after remove`, this.trips);
        }
      } catch (error) {
        let get_key_res = this.rs.getKey(
          JSON.parse(error.message),
          ["trias:Code"],
          false
        );
        if (get_key_res) {
          if (get_key_res == -4000) {
            this.showNoResultsError()
          }
        } else {
          this.showErrorSnackBar("Etwas ist schief gelaufen.");
        }
      }
      this.laterLoading = this.resultLoading = false;
    },
    showNoResultsError() {
      this.listType = "list";
      this.errors = [
        "Leider konnten wir keine Ergebnisse für die gewählten Parameter finden.",
      ];
    },
    async getTripInformation(scroll = true) {
      console.log(`getTripInformation this.onlysearch`, this.onlysearch);
      console.log(`getTripInformation this.routedFromStartPage`, this.routedFromStartPage);
      if(this.onlysearch){
        this.routedFromStartPage = true;
      } 
      let encoded_data_object = encodeURIComponent(
        JSON.stringify(this["_data"])
      );
      window.localStorage.setItem("tti-data-object", encoded_data_object);

      if (this.onlysearch) {
        window.open(this.timetablepage, "_self");
        return;
      }
      console.log(`transferOptions`, this.transferOptions);
      this.paramsChangedSinceRequest = false;
      this.errors = [];

      try {
        console.log(`this.from_search`, this.from_search);
        console.log(`this.to_search`, this.to_search);
        if (!this.selected_from_item || !this.selected_to_item) {
          this.showErrorSnackBar(
            "Sie müssen erst Start- und Endziel festlegen!"
          );
          return;
        }

        if (
          this.selected_from_item["label"] == this.selected_to_item["label"]
        ) {
          this.showErrorSnackBar(
            "Bitte wählen Sie den Zielort unterschiedlich vom Startort!"
          );
          return;
        }

        this.resultLoading = true;
        this.trips = [];
        this.alternatives = [];
        this.getTripAlternatives();

        // eslint-disable-next-line no-constant-condition
        if(scroll){
          this.scrollToResult();
        }

        let result = await this.rs.getTripRequest(
          this.selected_from_item,
          this.selected_to_item,
          this.transferOptions,
          this.abfahrtAnkunft == "Abfahrt" ? this.getActualTime() : null,
          this.abfahrtAnkunft == "Ankunft" ? this.getActualTime() : null
        );
        result = this.rs.parseTRIASBodyToArrayTrip(
          result,
          this.selected_from_item
        );

        this.trips = result;

        if (!this.trips.length) {
          this.showNoResultsError()
        }
        //set days objects -> show dates between trips when day changes
        console.log(`getTripInformation result`, result);
        this.addTripDatesIfNotRequestedDay();


        

      } catch (error) {
        // console.log(`get_key_res error`, error.message);
        console.error(`error`, error);
        let get_key_res = this.rs.getKey(
          JSON.parse(error.message),
          ["trias:Code"],
          false
        );
        if (get_key_res) {
          if (get_key_res == -4000) {
            this.showNoResultsError()
          }
        } else {
          this.showErrorSnackBar("Etwas ist schief gelaufen.");
        }
      }
      this.resultLoading = false;
    },
    scrollToResult(){
      setTimeout(() => {
        if (this.$refs.tti) {
          this.$refs.tti.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
      }, 1000);

    },

    /*
      Bicycle and Walk
    */
    async getTripAlternatives() {
      console.log("getTripAlternatives start");
      console.log(`this.selected_from_item`, this.selected_from_item);
      console.log(`this.selected_to_item`, this.selected_to_item);
      this.alternatives = [];
      try {
        let origin_lat = this.rs.getKey(this.selected_from_item, [
          "trias:Location",
          "trias:GeoPosition",
          "trias:Latitude",
        ]);
        let origin_lng = this.rs.getKey(this.selected_from_item, [
          "trias:Location",
          "trias:GeoPosition",
          "trias:Longitude",
        ]);
        let destination_lat = this.rs.getKey(this.selected_to_item, [
          "trias:Location",
          "trias:GeoPosition",
          "trias:Latitude",
        ]);
        let destination_lng = this.rs.getKey(this.selected_to_item, [
          "trias:Location",
          "trias:GeoPosition",
          "trias:Longitude",
        ]);
        const altBodies = await Promise.all([
          this.rs.getAlternativeRoutesFromGoogle(
            origin_lat + "," + origin_lng,
            destination_lat + "," + destination_lng,
            "walking"
          ),
          this.rs.getAlternativeRoutesFromGoogle(
            origin_lat + "," + origin_lng,
            destination_lat + "," + destination_lng,
            "bicycling"
          ),
        ]);
        console.log(`altBodies`, altBodies);
        let alternatives = [];
        for (let body of altBodies) {
          if (
            body &&
            typeof body["status"] !== "undefined" &&
            body["status"] == "OK"
          ) {
            let res = this.rs.parseDirectionsAPIBody(
              body,
              this.abfahrtAnkunft,
              this.getActualTime()
            );
            if (res) {
              alternatives.push(res);
            }
          }
        }

        this.alternatives = alternatives;
        console.log(`this.alternatives`, this.alternatives);
      } catch (error) {
        this.alternatives = [];
        console.error("getTripAlternatives", error);
      }
    },
    addTripDatesIfNotRequestedDay() {
      try {
        //TODO after NumberOfResultsBefore fixed -> check if backwards also works
        console.log(`this.trips`, this.trips);
        this.trips = this.trips.filter(
          (trip) => typeof trip.isDateElement === "undefined"
        );
        console.log(`this.trips after filter`, this.trips);
        let curr_day = null;
        for (let i = this.trips.length - 1; i >= 0; i--) {
          let el = this.rs.getKey(
            this.trips[i],
            ["trias:Trip", "trias:StartTime"],
            false
          );
          if (!el) continue;
          let date = new Date(el);
          if (curr_day && curr_day.getDate() != date.getDate()) {
            console.log(`curr_day`, curr_day);
            this.trips.splice(i + 1, 0, {
              isDateElement: true,
              formattedDate:
                curr_day.getDate() +
                "." +
                (curr_day.getMonth() + 1) +
                "." +
                curr_day.getFullYear(),
            });
            curr_day = date;
          }
          if (!curr_day) {
            curr_day = date;
          }
          if(i == 0 && date.getDate() != new Date().getDate()){
            this.trips.splice(i, 0, {
              isDateElement: true,
              formattedDate:
                date.getDate() +
                "." +
                (date.getMonth() + 1) +
                "." +
                date.getFullYear(),
            });
          }
        }
        console.log(`this.trips after`, this.trips);
      } catch (error) {
        console.error(`addTripDatesIfNotRequestedDay error`, error);
      }
    },
    getActualTime(dateParam = this.date) {

      //increaseDecrease = null
      this.fixTimeResetIOS();


      let date = new Date(dateParam);
      console.log(`date`, date);
      let [hours, minutes] = this.time.split(":");
      // let [hours, minutes] = [0,0]
      // if(increaseDecrease){
      //   date = new Date(date.getTime() + (increaseDecrease == 'inc'?60000:-60000));
      //   console.log(`date after Settime`, date);
      //   [hours, minutes] = [date.getHours(), date.getMinutes()];
      // } else {
      // }
      date.setHours(Number.parseInt(hours));
      date.setMinutes(Number.parseInt(minutes));
      return date.toISOString();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    changeTime(inc = true) {
      this.fixTimeResetIOS();
      this.threeButtonsTouchedIndex = 0;
      this.paramsChangedSinceRequestFnc();
      let [hours, minutes] = this.time.split(":").map((z) => parseInt(z));
      if (inc) {
        minutes = minutes + 15;
      } else {
        minutes = minutes - 15;
      }

      minutes = minutes - (minutes % 15);

      if (minutes > 59) {
        hours += 1;
      }
      if (minutes < 0) {
        hours -= 1;
        minutes = 60 + minutes;
      }
      if (hours < 0) {
        hours = 24 + hours;
      }
      hours = hours % 24;
      minutes = minutes % 60;
      this.time = ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2);
    },
    throttledToSearch: throttle(1500, function () {
      this.searchFunction("to");
    }),
    throttledFromSearch: throttle(1500, function () {
      this.searchFunction("from");
    }),

    async searchFunction(mode = "from") {
      // other mode "to"
      console.log(`mode`, mode);
      this.from_search = this.from_search?this.from_search: "Konstanz";
      this.to_search = this.to_search?this.to_search: "Konstanz";

      // Lazily load input items
        try {
          let fromSearchRes = await this.rs.getLocationsForInput(
            mode == "from" ? this.from_search : this.to_search
          );
          console.log(`fromSearchRes`, fromSearchRes);

          if (mode == "from" || mode == "both") {
            this.from_items = fromSearchRes
          }

          if (mode == "to" || mode == "both") {
            this.to_items = fromSearchRes;
          }
        } catch (error) {
          console.log(`searchFunction error`, error);
          //   this.showErrorSnackBar(JSON.stringify(error));
          try {
            let errParsed = JSON.parse(error['message'])
            console.log(`errParsed`, errParsed);

            if(errParsed['trias:Code'] == -8020){
              this.errors = []
              return
            }
          } catch (err) {
            console.error('error at errorparsing',err)
          }
          this.from_items = [];
          this.to_items = [];
          this.errors = [
            "Leider ist der Server für die Fahrplanauskunft derzeit nicht erreichbar. Bitte versuchen Sie es später erneut",
          ];
          console.error("searchFunction Error: ", error,this.errors);
        }
        console.log(`this.from_items`, this.from_items);
    },
    showErrorSnackBar(message) {
      this.$refs.snackbar.showSnackbar(message);
    },
    filterFromOptions(options, search) {
      console.log(`options`, options);
      console.log(`search`, search);
      if(this.from_search != search){
        this.from_search = search;
        this.throttledFromSearch();
      }
      return this.from_items;
    },
    filterToOptions(options, search) {
      console.log(`options`, options);
      console.log(`search`, search);
      if(this.to_search != search){
        this.to_search = search;
        this.throttledToSearch();
      }
      return this.to_items;
    },
    async locationFromGPS() {
      if ("geolocation" in navigator) {
        if(this.gpsLoading) return
        try {
          this.gpsLoading = true;
          let position = await new Promise((res, rej) => {
            navigator.geolocation.getCurrentPosition(
              (success) => res(success),
              (error) => rej(error)
            );
          });
  
          let result = await this.rs.getLocationsForInput(null, position);
          console.log(`locationFromGPS result`, result);
          console.log(`selected_from_item`, this.selected_from_item);
          this.from_items = result;
          this.from_search = result[0]["label"];
          this.selected_from_item = result[0];
          this.gpsLoading = false;
        } catch (error) {
          this.gpsLoading = false;
          console.error(`error`, error);
        }
      }
    },
    saveStuffToLocalStorage(){
      return true
      // let encoded_data_object = encodeURIComponent(
      //   JSON.stringify(this["_data"])
      //   );
      //   console.log(`transferOptions encoded_data_object`, encoded_data_object);    
      //   window.localStorage.setItem("tti-data-object", encoded_data_object)
    }
  },
  watch: {
    time(){
      this.saveStuffToLocalStorage();
    },
    selected_to_item(){
      this.saveStuffToLocalStorage();
    },
    selected_from_item(){
      this.saveStuffToLocalStorage();
    },
    transferOptions(){
      this.saveStuffToLocalStorage();
    },
    abfahrtAnkunft(){
      this.saveStuffToLocalStorage();
    },



    date() {
      this.saveStuffToLocalStorage();
    },
  },
  async mounted() {
    smoothscroll.polyfill();

    // vs__dropdown-toggle
    try {
      const vonElement = this.$el.querySelector("#tti-from-select .vs__dropdown-toggle");
      vonElement.ariaLabel = "Von";
      const nachElement = this.$el.querySelector("#tti-to-select .vs__dropdown-toggle");
      nachElement.ariaLabel = "Nach";
    } catch (error) {
      console.error(`error`, error);}

    let data_el = document.getElementById("vue-app-data");
    try {
      this.onlysearch = !!Number.parseInt(data_el.dataset.onlysearch);
    } catch (error) {
      this.onlysearch = false;
    }
    this.farespage = data_el.dataset.farespage;
    this.timetablepage = data_el.dataset.timetablepage;
    console.log(`this.onlysearch`, this.onlysearch);
    console.log(`this.farespage`, this.farespage);
    console.log(`this.timetablepage`, this.timetablepage);

    // this.rs = new RequestService();
    this.searchFunction("both");

    // console.log(`searchParams`, searchParams.get('data-object'));

    // console.log('this', this['_data'])

    // let searchParams = new URLSearchParams(window.location.search);
    // if(searchParams.get('data-object') && !this.onlysearch){
      // && !this.onlysearch
    if (window.localStorage.getItem("tti-data-object") && !this.onlysearch) {
      let decoded_data_object = JSON.parse(
        decodeURIComponent(window.localStorage.getItem("tti-data-object"))
      );
      // let decoded_data_object = JSON.parse(decodeURIComponent(searchParams.get('data-object')));
      console.log(`decoded_data_object`, JSON.stringify(decoded_data_object));
      if (decoded_data_object) {
        for (let key of Object.keys(decoded_data_object)) {
          if (
            !(
              typeof decoded_data_object[key] === "undefined" ||
              ["onlysearch", "date_menu", "time_menu", "listType", "flatPickrConfig"].includes(
                key
              ) ||
              !decoded_data_object[key] ||
              (Array.isArray(decoded_data_object[key]) &&
                !decoded_data_object.length) ||
              (typeof decoded_data_object[key] === "object" &&
                !Object.keys(decoded_data_object[key]).length)
            )
          ) {
            this[key] = decoded_data_object[key];
          }
        }
        console.log('data', this['_data'])
        window.localStorage.setItem("tti-data-object", null);
        console.log(`this.routedFromStartPage`, this.routedFromStartPage);
        if(this.routedFromStartPage){
          this.routedFromStartPage = false;
          this.getTripInformation();
          // let encoded_data_object = encodeURIComponent(
          //   JSON.stringify(this["_data"])
          // // );
          // window.localStorage.setItem("tti-data-object", encoded_data_object);
        } else {
          this.getTripInformation(false);
        }

      }
    }

    if (this.debug) {
      // this.date = '2022-02-04'
      this.time = '08:59'
      // this.dateFormatted =
      // this.changeTime(false);
      this.paramsChangedSinceRequestFnc();
      this.selected_from_item = {
        "trias:Location": {
          "trias:StopPoint": {
            "trias:StopPointRef": "de:08335:11913",
            "trias:StopPointName": {
              "trias:Text": "Döbele",
              "trias:Language": "de",
            },
            "trias:LocalityRef": "8335043:5",
          },
          "trias:LocationName": {
            "trias:Text": "Konstanz",
            "trias:Language": "de",
          },
          "trias:GeoPosition": {
            "trias:Longitude": 9.1689,
            "trias:Latitude": 47.65864,
          },
        },
        "trias:Complete": true,
        "trias:Probability": 0.953000009,
        label: "Döbele, Konstanz",
      };
      this.selected_to_item = {
        "trias:Location": {
          "trias:StopPoint": {
            "trias:StopPointRef": "de:08335:11897",
            "trias:StopPointName": {
              "trias:Text": "Rosenau",
              "trias:Language": "de",
            },
            "trias:LocalityRef": "8335043:5",
          },
          "trias:LocationName": {
            "trias:Text": "Konstanz",
            "trias:Language": "de",
          },
          "trias:GeoPosition": {
            "trias:Longitude": 9.19883,
            "trias:Latitude": 47.66702,
          },
        },
        "trias:Complete": true,
        "trias:Probability": 0.953000009,
        label: "Rosenau, Konstanz",
      };
      this.getTripAlternatives();
      // debug
      let searchRes = requestResultWithRemainInVehicle;
      this.trips = this.rs.parseTRIASBodyToArrayTrip(
        searchRes,
        this.selected_from_item
      );

      console.log(`this.trips.legs`, this.trips);
      // this.getTripInformation();
    }

    // this.showErrorSnackBar('test')
    // console.log(`trias`, trias);
    // this.trias_client = trias.getClient({
    //   url: "http://efa-bw.de/trias",
    //   requestorRef: "STaDTWerKE-KonstANz",
    // });
    // console.log(`this.trias_client`, this.trias_client);
    // let result = await this.trias_client.getStops({
    //   // name: "zähringer",
    //   latitude: 47.67405,
    //   longitude:9.17352,
    //   radius: 1000
    // });
    // console.log(`mounted result`, result);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.v-application {
  background: transparent !important;
}
.v-select {
  background-color: white;
}

.v-application--wrap {
  min-height: 0px !important;
}

</style>
<style scoped>
.time-label{
  width:100%!important;
}
</style>
