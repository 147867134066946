<template>
    <div class="loadspinner">
        <div class="spinner" :style="spinnerStyle"></div>
    </div>
</template>
  
<script>
export default {
    name: "LoadSpinnerComponent",
    props: {
        size: {
            type: Number,
            default: 40
        },
        color: {
            type: String,
            default: "var(--swk-primary)"
        }
    },
    computed: {
        spinnerStyle() {
            return {
                width: `${this.size}px`,
                height: `${this.size}px`,
                border: `${this.size / 8}px solid ${this.color}`,
                borderTopColor: "transparent",
                borderRightColor: "transparent",
                borderRadius: "50%",
                animation: "spin 1s infinite linear"
            };
        }
    }
};
</script>
  
<style>
.loadspinner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    box-sizing: border-box;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
</style>