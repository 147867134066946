<template>
    <div v-if="snackbarMessages.length" class="snackbar">
        <div v-for="(message, index) in snackbarMessages" :key="index" class="snackbar-message">
            <!-- svg umrandetes ausrufezeichen -->
            <div class="left">
                <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 7v6M12 17.01l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
                        stroke="white" stroke-width="2.1" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

            </div>
            <div class="right">
                {{ message.text }}
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            snackbarMessages: [],
        };
    },
    methods: {
        showSnackbar(text) {
            this.snackbarMessages.push({
                text,
                timeout: setTimeout(() => {
                    this.snackbarMessages.shift();
                }, 3000),
            });
        },
    },
};
</script>
  
<style scoped lang="scss">
.snackbar {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 9999;
}

.snackbar-message {
    border-radius: 6px;
    min-width: 600px;
    background-color: var(--swk-primary);
    color: white;
    padding: 15px;
    margin-bottom: 10px;
    display: flex;
    gap: 7px;
    font-weight: bold;

    .left,
    .right {
        display: flex;
        align-items: center;
    }
}
</style>