<template>
  <div class="list-expansion-wrap" v-show-slide="expandedObjects[trip['key']]">
    <div class="list-expansion">
      <div class="left">
        <div
          class="list-el"
          v-for="(leg, index) of trip.legs"
          v-bind:key="index"
        >
          <div>
            <div class="part" v-if="!leg.timedLegBefore">
              <div class="left">
                <div>
                  {{ leg.startTimeFormatted }} 
                  <span class="tti-delay font-size-12" 
                    v-bind:class="{
                      'tti-green': leg.startEstimatedDifferenceMinutes < 2,
                      'tti-orange': leg.startEstimatedDifferenceMinutes >= 2 && leg.startEstimatedDifferenceMinutes < 5,
                      'tti-red': leg.startEstimatedDifferenceMinutes >= 5,
                    }"
                  >+{{leg.startEstimatedDifferenceMinutes}} <br>
                  <span class="font-size-16" v-if="leg.startEstimatedDifferenceMinutes">{{leg.startTimeEstimatedFormatted}}</span>
                </span>
                </div>
                
              </div>
                <!-- v-bind:style="{
                  borderColor: leg.borderColor,
                }" -->
              <div
                class="right no-border"
              >
                <div class="border-wrapper">
                  <div class="border"
                    v-bind:style="{
                      backgroundColor: leg.beforeColor,
                    }"
                  ></div>
                  <div class="border"
                    v-bind:style="{
                      backgroundColor: leg.borderColor,
                    }"
                  ></div>
                </div>

                <div
                  class="point"
                  v-bind:style="{
                    backgroundColor: leg.iconColor,
                  }"
                ></div>
                {{ leg.startPoint }}
              </div>
            </div>
            <div class="part middle-part">
              <div class="left"></div>
              <div
                class="right"
                v-bind:style="{
                  borderColor: leg.borderColor,
                }"
              >
                <div class="icon-left" v-if="leg.icon">
                  <div class="verlauf-logo-wrapper" v-if="leg.iconSrc" v-bind:style="{'backgroundColor': leg.iconColor}">
                    <img class="bus-icon-normal" :src="leg.iconSrc" :alt="leg.altTag"/>
                    <img  class="bus-icon-contrast" :src="leg.iconContrastSrc" :alt="leg.altTag"/>
                  </div>
                  <div
                    v-else
                    class="alternative-logo"
                    :class="{
                      'smaller-font': leg.icon && leg.icon.length > 2,
                    }"
                  >
                    {{ leg.icon }}
                  </div>
                </div>
                <div class="middle-part-description">
                  <b v-if="leg.vehicleMode">{{ leg.vehicleMode }}</b>
                  <span v-if="leg.vehicleEndPoint">
                    Richtung {{ leg.vehicleEndPoint }}</span
                  >
                  <div
                    class="time-inter"
                    v-bind:class="{
                      'center-time': leg.mode != 'trias:TimedLeg',
                    }"
                  >
                    <span v-if="leg.durationStdMin">{{
                      leg.durationStdMin
                    }}</span>

                    <span class="zwischenhalte" v-if="leg.intermediates && leg.intermediates.length"
                      >{{ leg.intermediates.length }} Zwischenhalte</span
                    >
                    <!-- <span
                      v-else-if="leg.mode == 'trias:TimedLeg'"
                      class="zwischenhalte"
                      >0 Zwischenhalte</span
                    > -->
                    <div
                      v-if="leg.intermediates && leg.intermediates.length"
                      class="right chevron-right"
                      :class="{
                        rotate:
                          forceRefreshExpanded &&
                          expandedIntermediates[trip['key'] + leg.id],
                      }"
                      v-on:click="
                        expandedIntermediates[trip['key'] + leg.id] =
                          !expandedIntermediates[trip['key'] + leg.id];
                        forceRefreshExpanded++;
                      "
                      :tabindex="0"
                      :aria-label="expandedIntermediates[trip['key'] + leg.id] ? 'Zwischenhalte ausblenden' : 'Zwischenhalte anzeigen'"
                      @keyup.13="
                        expandedIntermediates[trip['key'] + leg.id] =
                          !expandedIntermediates[trip['key'] + leg.id];
                        forceRefreshExpanded++;"
                    ></div>
                  </div>
                  <div
                    v-show-slide="expandedIntermediates[trip['key'] + leg.id]"
                    class="intermediates"
                    v-if="leg.intermediates && leg.intermediates.length"
                    :class="{'intPaddingBottom': expandedIntermediates[trip['key'] + leg.id]}"
                  >
                    <div
                      class="el"
                      v-for="int of leg.intermediates"
                      v-bind:key="int"
                    >
                      {{ int }}
                    </div>
                  </div>
                </div>
                <div v-if="leg.situationsForLeg && leg.situationsForLeg.length">
                  <div class="situations-box" v-for="(sit, index) in leg.situationsForLeg " v-bind:key="index">
                    <div class="left-right" >
                      <div class="leftt">
                        <exclamation-mark-red></exclamation-mark-red>
                      </div>
                      <div class="rightt">
                        <span v-html="sit.title"></span>
                        <span
                          v-if="sit.description && sit.description != ''"
                          class="right chevron-right"
                          :class="{
                            rotate:
                              forceRefreshExpanded &&
                              expandedIntermediates[trip['key'] + leg.id + 'sit' + index ],
                          }"
                          v-on:click="
                            expandedIntermediates[trip['key'] + leg.id + 'sit' + index ] =
                              !expandedIntermediates[trip['key'] + leg.id + 'sit' + index ];
                            forceRefreshExpanded++;
                          "
                        ></span>
                        <!-- <span v-if="sit.description && sit.description != ''"></span> -->
                      </div>
                    </div>
                    <div v-show-slide="expandedIntermediates[trip['key'] + leg.id + 'sit' + index ]" class="expansion" v-if="sit.description && sit.description != ''" v-html="sit.description">
                    </div>
                  </div>
                </div>
              </div> 
            </div>
            <div class="part" v-if="!leg.timedLegAfter">
              <div class="left">
                <div>
                  {{ leg.endTimeFormatted }}
                  <span class="tti-delay font-size-12" 
                    v-bind:class="{
                      'tti-green': leg.endEstimatedDifferenceMinutes < 2,
                      'tti-orange': leg.endEstimatedDifferenceMinutes >= 2 && leg.endEstimatedDifferenceMinutes < 5,
                      'tti-red': leg.endEstimatedDifferenceMinutes >= 5,
                    }"
                  >+{{leg.endEstimatedDifferenceMinutes}} <br>
                  <span class="font-size-16" v-if="leg.endEstimatedDifferenceMinutes">{{leg.endTimeEstimatedFormatted}}</span>
                </span>
                </div>
              
              </div>
                <!-- v-bind:style="{
                  borderColor: leg.borderColor,
                }" -->
              <div
                class="right no-border"
              >
                <div class="border-wrapper">
                  <div class="border"
                    v-bind:style="{
                      backgroundColor: leg.borderColor,
                    }"
                  ></div>
                  <div class="border"
                    v-bind:style="{
                      backgroundColor: leg.afterColor,
                    }"
                  ></div>
                </div>
                <div
                  class="point"
                  v-bind:style="{
                    backgroundColor: leg.iconColor,
                  }"
                ></div>
                {{ leg.endPoint }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="navigate-and-fares">
          <a :href="trip.navigateToStartLink" target="_blank">Zur Starthaltestelle navigieren</a>
          <a :href="$parent.$parent.farespage" target="_blank">Tarife anzeigen</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ExclamationMarkRed,
} from "./SvgComponent.vue";
export default {
  components: {
    ExclamationMarkRed
  },
  name: "ListExpansionComponent",
  props: {
    trip: {
        default: {},
    },
    expandedObjects:{
        default:{}
    },
  },
  data: function () {
    return {
      expandedIntermediates: {},
      forceRefreshExpanded: 1,
    };
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank").focus();
    },
  },
};
</script>

<style>
</style>