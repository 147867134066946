import Vue from 'vue'
import App from './App.vue'
// import vuetify from './plugins/vuetify'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VShowSlide from 'v-show-slide'
// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";
// import { CaptureConsole } from '@sentry/integrations';

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// import  { CalendarPlugin } from 'bootstrap-vue'
// Vue.use(CalendarPlugin)

// Import Bootstrap and BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)





Vue.use(VShowSlide);

Vue.config.productionTip = false
Vue.component('vue-select', vSelect)

console.log(`process.env`, process.env);

if(process.env.NODE_ENV != "development"){
  console.log = () => {};
}


if(process.env.NODE_ENV == "development"){
  console.log = () => {};
  // Sentry.init({
  //   Vue,
  //   dsn: "https://b479dc3a277d4c469bddd17f92f82805@o939555.ingest.sentry.io/6212921",
  //   integrations: [
  //     new BrowserTracing(
  //       {
  //       tracingOrigins: ["localhost", /^\//, /.*stadtwerke-konstanz.de.*/],
  //       }
  //     ),
  //     new CaptureConsole({
  //       levels: ['error']
  //     })
  //   ],
  
  //   environment: 'prod',
  
  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  // });
}

if(document.getElementById('app')){
  new Vue({
    // vuetify,
    render: h => h(App)
  }).$mount('#app')
}
