<script lang="ts">
export const AbfahrtButton = {
  name: "abfahrt-button",
  template: `<svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 6.75h13.5M8 12h13.5M8 17.25h13.5M4.25 7.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM4.25 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM4.25 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
`
};
export const AnkunftButton = {
  name: "ankunft-button",
  template: `<svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 1.75v13.875a.4.4 0 0 0 .098.265c.062.07.147.11.235.11H10.5V1.75a.8.8 0 0 0-.195-.53.631.631 0 0 0-.472-.22H5.167a.631.631 0 0 0-.472.22.8.8 0 0 0-.195.53v0ZM18.9 3h-4.8a.547.547 0 0 0-.424.22.854.854 0 0 0-.176.53V22.5h6V3.75a.854.854 0 0 0-.176-.53A.547.547 0 0 0 18.9 3v0Z" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
`
};

export const ExclamationMarkRed = {
  name: "exclamation-mark-red",
  template: `<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 12c0-4.969-4.031-9-9-9s-9 4.031-9 9 4.031 9 9 9 9-4.031 9-9Z" stroke="var(--swk-primary)" stroke-width="1.5" stroke-miterlimit="10"/><path d="M11.73 7.784 12 13.5l.269-5.716a.27.27 0 0 0-.272-.282v0a.269.269 0 0 0-.266.282v0Z" stroke="var(--swk-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 17.246a.937.937 0 1 1 0-1.875.937.937 0 0 1 0 1.875Z" fill="var(--swk-primary)"/></svg>`
}
export const ExclamationMarkWhite = {
  name: "exclamation-mark-white",
  template: `<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 12c0-4.969-4.031-9-9-9s-9 4.031-9 9 4.031 9 9 9 9-4.031 9-9Z" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10"/><path d="M11.73 7.784 12 13.5l.269-5.716a.27.27 0 0 0-.272-.282v0a.269.269 0 0 0-.266.282v0Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 17.246a.937.937 0 1 1 0-1.875.937.937 0 0 1 0 1.875Z" fill="#fff"/></svg>`
}
export const ChevronDown = {
  name: "chevron-down",
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.25 8.625L12 15.375L18.75 8.625" stroke="#E2001A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`
}
export const ChevronUp = {
  name: "chevron-up",
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.25 15.375L12 8.625L18.75 15.375" stroke="#E2001A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

`
}


export const BusIconColor = {
  '1': '#D7303E',
  '2': '#F8D757',
  '3': '#D590BB',
  '4-13': '#EDA89D',
  '5': '#2B5A8C',
  '6': '#DB4B7A',
  '9': '#729CD1',
  '9A': '#729CD1',
  '9AB': '#729CD1',
  '9B': '#729CD1',
  '9C': '#6E73B1',
  '11': '#2A6A61',
  '12-3': '#E3743F',
  '12': '#E3743F',
  '13-4': '#EDA89D',
  '14': '#B8D6E9',
  '15': '#B6A095',
  '908': '#BB6235',
  'AST': '#F0DD8A',
  'N': '#C6C7C7',
  'N13': '#C6C7C7',
  'N14': '#C6C7C7',
  'B': '#D7303E',
  'P-R': '#98C271',
  'CS': '#98C271',
  'cycle': '#ACACAC',
  'walk': '#ACACAC',
  'chevron-right': '#ACACAC',

}
//bus logos
export const BusIcons = {
  '1': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#D7303E" rx="4"/><path fill="#fff" d="M16.894 22.808h-2.88V26h8.808v-3.192h-2.616V9.08h-3.048l-3.984 1.512v3.576l3.72-1.464v10.104Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><path fill="#fff" d="M16.894 22.808h-2.88V26h8.808v-3.192h-2.616V9.08h-3.048l-3.984 1.512v3.576l3.72-1.464v10.104Z"/></svg>'
  },

  '2': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#F8D757" rx="4"/><path fill="#fff" d="M15.774 22.808c.6-2.472 8.064-3.552 8.064-8.856 0-4.92-4.896-5.064-5.352-5.064-.36 0-5.496 0-5.496 5.424h3.312c0-.48 0-2.208 2.184-2.208 1.896 0 2.04 1.152 2.04 1.824 0 2.856-8.136 3.96-8.136 9.192V26h11.328v-3.192h-7.944Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><path fill="#fff" d="M15.774 22.808c.6-2.472 8.064-3.552 8.064-8.856 0-4.92-4.896-5.064-5.352-5.064-.36 0-5.496 0-5.496 5.424h3.312c0-.48 0-2.208 2.184-2.208 1.896 0 2.04 1.152 2.04 1.824 0 2.856-8.136 3.96-8.136 9.192V26h11.328v-3.192h-7.944Z"/></svg>'
  },

  '3': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#D590BB" rx="4"/><path fill="#fff" d="M23.206 12.68v-3.6H13.078v3.192h5.88l-3.744 3.672v2.88h2.592c1.944 0 2.472 1.488 2.472 2.184 0 .72-.264 1.992-2.184 1.992-2.232 0-2.328-1.752-2.328-2.232h-3.36c0 5.424 5.328 5.424 5.688 5.424.456 0 5.496-.264 5.496-5.16 0-3.024-1.824-4.584-3.672-5.064l3.288-3.288Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><path fill="#fff" d="M23.206 12.68v-3.6H13.078v3.192h5.88l-3.744 3.672v2.88h2.592c1.944 0 2.472 1.488 2.472 2.184 0 .72-.264 1.992-2.184 1.992-2.232 0-2.328-1.752-2.328-2.232h-3.36c0 5.424 5.328 5.424 5.688 5.424.456 0 5.496-.264 5.496-5.16 0-3.024-1.824-4.584-3.672-5.064l3.288-3.288Z"/></svg>'
  },

  '4-13': {
    normal: '<svg id="Symbole" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 269.29 269.29">\n' +
        '  <defs>\n' +
        '    <style>\n' +
        '      .cls-1 {\n' +
        '        fill: #fff;\n' +
        '      }\n' +
        '\n' +
        '      .cls-1, .cls-2 {\n' +
        '        stroke-width: 0px;\n' +
        '      }\n' +
        '\n' +
        '      .cls-2 {\n' +
        '        fill: #f6b1a6;\n' +
        '      }\n' +
        '    </style>\n' +
        '  </defs>\n' +
        '  <rect class="cls-2" width="269.29" height="269.29" rx="18.81" ry="18.81"/>\n' +
        '  <g>\n' +
        '    <path class="cls-1" d="m162.11,243.93l-19.36.04-.16-74.62-23.09,7.21-.03-15.74,40.36-14.55h2.08s.2,97.65.2,97.65Z"/>\n' +
        '    <path class="cls-1" d="m202.02,186.48l10.32-.02c4.91-.01,8.55-1.25,10.91-3.71,2.36-2.46,3.54-5.72,3.53-9.79,0-3.93-1.19-6.99-3.54-9.17-2.35-2.18-5.58-3.27-9.69-3.26-3.71,0-6.81,1.03-9.3,3.07-2.5,2.04-3.74,4.69-3.74,7.94l-19.36.04c-.01-5.09,1.35-9.66,4.09-13.71,2.74-4.05,6.57-7.21,11.5-9.5,4.93-2.29,10.36-3.44,16.3-3.45,10.32-.02,18.4,2.43,24.26,7.35,5.86,4.92,8.8,11.71,8.82,20.38,0,4.47-1.35,8.58-4.06,12.33-2.72,3.76-6.28,6.64-10.7,8.66,5.5,1.95,9.6,4.89,12.31,8.82,2.71,3.92,4.07,8.56,4.08,13.92.02,8.66-3.13,15.61-9.43,20.85-6.31,5.24-14.66,7.87-25.07,7.89-9.73.02-17.7-2.53-23.89-7.65-6.2-5.12-9.3-11.9-9.32-20.34l19.36-.04c0,3.66,1.39,6.65,4.14,8.97,2.75,2.32,6.14,3.47,10.15,3.46,4.6,0,8.2-1.23,10.81-3.67,2.61-2.44,3.91-5.67,3.9-9.69-.02-9.73-5.39-14.59-16.11-14.57l-10.25.02-.03-15.14Z"/>\n' +
        '  </g>\n' +
        '  <path class="cls-1" d="m37.95,217.65l-8.31-8.27L186.67,51.69l8.31,8.27L37.95,217.65Z"/>\n' +
        '  <path class="cls-1" d="m83.07,82.84l11.05-.02.03,15.61-11.05.02.04,21.1-19.36.04-.04-21.1-39.99.08-.9-12.19L63.38,22.07l19.56-.04.13,60.82Zm-40.92.09l21.57-.05-.07-34.43-1.27,2.21-20.23,32.26Z"/>\n' +
        '</svg>',
    contrast: '<svg id="Symbole" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 269.29 269.29">\n' +
        '  <defs>\n' +
        '    <style>\n' +
        '      .cls-1 {\n' +
        '        fill: #fff;\n' +
        '      }\n' +
        '\n' +
        '      .cls-1, .cls-2 {\n' +
        '        stroke-width: 0px;\n' +
        '      }\n' +
        '\n' +
        '      .cls-2 {\n' +
        '        fill: #f6b1a6;\n' +
        '      }\n' +
        '    </style>\n' +
        '  </defs>\n' +
        '  <rect class="cls-2" width="269.29" height="269.29" rx="18.81" ry="18.81"/>\n' +
        '  <g>\n' +
        '    <path class="cls-1" d="m162.11,243.93l-19.36.04-.16-74.62-23.09,7.21-.03-15.74,40.36-14.55h2.08s.2,97.65.2,97.65Z"/>\n' +
        '    <path class="cls-1" d="m202.02,186.48l10.32-.02c4.91-.01,8.55-1.25,10.91-3.71,2.36-2.46,3.54-5.72,3.53-9.79,0-3.93-1.19-6.99-3.54-9.17-2.35-2.18-5.58-3.27-9.69-3.26-3.71,0-6.81,1.03-9.3,3.07-2.5,2.04-3.74,4.69-3.74,7.94l-19.36.04c-.01-5.09,1.35-9.66,4.09-13.71,2.74-4.05,6.57-7.21,11.5-9.5,4.93-2.29,10.36-3.44,16.3-3.45,10.32-.02,18.4,2.43,24.26,7.35,5.86,4.92,8.8,11.71,8.82,20.38,0,4.47-1.35,8.58-4.06,12.33-2.72,3.76-6.28,6.64-10.7,8.66,5.5,1.95,9.6,4.89,12.31,8.82,2.71,3.92,4.07,8.56,4.08,13.92.02,8.66-3.13,15.61-9.43,20.85-6.31,5.24-14.66,7.87-25.07,7.89-9.73.02-17.7-2.53-23.89-7.65-6.2-5.12-9.3-11.9-9.32-20.34l19.36-.04c0,3.66,1.39,6.65,4.14,8.97,2.75,2.32,6.14,3.47,10.15,3.46,4.6,0,8.2-1.23,10.81-3.67,2.61-2.44,3.91-5.67,3.9-9.69-.02-9.73-5.39-14.59-16.11-14.57l-10.25.02-.03-15.14Z"/>\n' +
        '  </g>\n' +
        '  <path class="cls-1" d="m37.95,217.65l-8.31-8.27L186.67,51.69l8.31,8.27L37.95,217.65Z"/>\n' +
        '  <path class="cls-1" d="m83.07,82.84l11.05-.02.03,15.61-11.05.02.04,21.1-19.36.04-.04-21.1-39.99.08-.9-12.19L63.38,22.07l19.56-.04.13,60.82Zm-40.92.09l21.57-.05-.07-34.43-1.27,2.21-20.23,32.26Z"/>\n' +
        '</svg>'
  },

  '5': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#2B5A8C" rx="4"/><path fill="#fff" d="M15.96 19.256c.336-.504 1.056-1.08 2.472-1.08 1.92 0 2.184 1.416 2.184 2.424 0 1.104-.264 2.4-2.184 2.4-2.208 0-2.328-1.8-2.328-2.232h-3.312c0 5.424 4.464 5.424 5.64 5.424 1.176 0 5.496-.264 5.496-5.616 0-5.352-4.392-5.616-4.848-5.616-1.008 0-2.136.048-2.928.552v-3.24h6.096V9.08H12.84v10.176h3.12Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><path fill="#fff" d="M15.96 19.256c.336-.504 1.056-1.08 2.472-1.08 1.92 0 2.184 1.416 2.184 2.424 0 1.104-.264 2.4-2.184 2.4-2.208 0-2.328-1.8-2.328-2.232h-3.312c0 5.424 4.464 5.424 5.64 5.424 1.176 0 5.496-.264 5.496-5.616 0-5.352-4.392-5.616-4.848-5.616-1.008 0-2.136.048-2.928.552v-3.24h6.096V9.08H12.84v10.176h3.12Z"/></svg>'
  },

  '6': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#DB4B7A" rx="4"/><path fill="#fff" d="M15.6 20.48c0-.408.073-2.568 2.4-2.568 2.329 0 2.4 2.16 2.4 2.568 0 .408-.071 2.52-2.4 2.52-2.327 0-2.4-2.112-2.4-2.52Zm5.185-11.4h-3.912l-3.552 5.856c-.912 1.464-1.032 2.928-1.032 5.544 0 5.712 4.488 5.712 5.712 5.712 1.224 0 5.712 0 5.712-5.712 0-5.688-4.488-5.688-5.712-5.688-.216 0-.432.024-.624.024l3.408-5.736Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><path fill="#fff" d="M15.6 20.48c0-.408.073-2.568 2.4-2.568 2.329 0 2.4 2.16 2.4 2.568 0 .408-.071 2.52-2.4 2.52-2.327 0-2.4-2.112-2.4-2.52Zm5.185-11.4h-3.912l-3.552 5.856c-.912 1.464-1.032 2.928-1.032 5.544 0 5.712 4.488 5.712 5.712 5.712 1.224 0 5.712 0 5.712-5.712 0-5.688-4.488-5.688-5.712-5.688-.216 0-.432.024-.624.024l3.408-5.736Z"/></svg>'
  },

  '9': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#729CD1" rx="4"/><path fill="#fff" d="M10.941 15.45c0 .306-.054 1.926-1.8 1.926s-1.8-1.62-1.8-1.926c0-.306.054-1.89 1.8-1.89s1.8 1.584 1.8 1.89ZM7.053 24h2.934l2.664-4.392c.684-1.098.774-2.196.774-4.158 0-4.284-3.366-4.284-4.284-4.284-.918 0-4.284 0-4.284 4.284 0 4.266 3.366 4.266 4.284 4.266.162 0 .324-.018.468-.018L7.053 24ZM17.942 14.13 14.484 24h1.022l.84-2.394h4.802l.84 2.394h1.022l-3.458-9.87h-1.61Zm.686.938h.238l1.96 5.6h-4.158l1.96-5.6ZM24.464 24h3.906c.91 0 2.8-.294 2.8-2.562v-.294c0-.854-.392-1.988-1.456-2.17v-.182c.756-.518.812-1.302.812-1.862v-.308c0-2.226-2.072-2.492-2.8-2.492h-3.262V24Zm.966-4.536h2.898c1.358 0 1.876.574 1.876 1.68v.196c0 1.106-.518 1.722-1.876 1.722H25.43v-3.598Zm0-.938v-3.458h2.226c1.358 0 1.904.546 1.904 1.638v.224c0 1.106-.546 1.596-1.904 1.596H25.43Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><mask id="a" width="36" height="36" x="0" y="0" maskUnits="userSpaceOnUse" style="mask-type:alpha"><rect width="36" height="36" fill="#464646" rx="4"/></mask><g stroke="#000" stroke-width="4" mask="url(#a)"><path d="M31.843-22.295-15.172 24.72M37.661-16.476-9.353 30.538M43.48-10.658-3.535 36.356M49.298-4.84 2.284 42.176M55.117.979 8.102 47.993M60.935 6.797 13.921 53.812"/></g><path fill="#fff" d="M20.4 14.6c0 .408-.071 2.568-2.4 2.568-2.327 0-2.4-2.16-2.4-2.568 0-.408.073-2.52 2.4-2.52 2.329 0 2.4 2.112 2.4 2.52ZM15.218 26h3.912l3.552-5.856c.912-1.464 1.032-2.928 1.032-5.544 0-5.712-4.488-5.712-5.712-5.712-1.224 0-5.712 0-5.712 5.712 0 5.688 4.488 5.688 5.712 5.688.216 0 .432-.024.624-.024L15.217 26Z"/></svg>'
  },

  '9A': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#729CD1" rx="4"/><path fill="#fff" d="M10.941 15.45c0 .306-.054 1.926-1.8 1.926s-1.8-1.62-1.8-1.926c0-.306.054-1.89 1.8-1.89s1.8 1.584 1.8 1.89ZM7.053 24h2.934l2.664-4.392c.684-1.098.774-2.196.774-4.158 0-4.284-3.366-4.284-4.284-4.284-.918 0-4.284 0-4.284 4.284 0 4.266 3.366 4.266 4.284 4.266.162 0 .324-.018.468-.018L7.053 24ZM17.942 14.13 14.484 24h1.022l.84-2.394h4.802l.84 2.394h1.022l-3.458-9.87h-1.61Zm.686.938h.238l1.96 5.6h-4.158l1.96-5.6ZM24.464 24h3.906c.91 0 2.8-.294 2.8-2.562v-.294c0-.854-.392-1.988-1.456-2.17v-.182c.756-.518.812-1.302.812-1.862v-.308c0-2.226-2.072-2.492-2.8-2.492h-3.262V24Zm.966-4.536h2.898c1.358 0 1.876.574 1.876 1.68v.196c0 1.106-.518 1.722-1.876 1.722H25.43v-3.598Zm0-.938v-3.458h2.226c1.358 0 1.904.546 1.904 1.638v.224c0 1.106-.546 1.596-1.904 1.596H25.43Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><mask id="a" width="36" height="36" x="0" y="0" maskUnits="userSpaceOnUse" style="mask-type:alpha"><rect width="36" height="36" fill="#464646" rx="4"/></mask><g stroke="#000" stroke-width="4" mask="url(#a)"><path d="M31.843-22.295-15.172 24.72M37.661-16.476-9.353 30.538M43.48-10.658-3.535 36.356M49.298-4.84 2.284 42.176M55.117.979 8.102 47.993M60.935 6.797 13.921 53.812"/></g><path fill="#fff" d="M20.4 14.6c0 .408-.071 2.568-2.4 2.568-2.327 0-2.4-2.16-2.4-2.568 0-.408.073-2.52 2.4-2.52 2.329 0 2.4 2.112 2.4 2.52ZM15.218 26h3.912l3.552-5.856c.912-1.464 1.032-2.928 1.032-5.544 0-5.712-4.488-5.712-5.712-5.712-1.224 0-5.712 0-5.712 5.712 0 5.688 4.488 5.688 5.712 5.688.216 0 .432-.024.624-.024L15.217 26Z"/></svg>'
  },

  '9AB': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#729CD1" rx="4"/><path fill="#fff" d="M10.941 15.45c0 .306-.054 1.926-1.8 1.926s-1.8-1.62-1.8-1.926c0-.306.054-1.89 1.8-1.89s1.8 1.584 1.8 1.89ZM7.053 24h2.934l2.664-4.392c.684-1.098.774-2.196.774-4.158 0-4.284-3.366-4.284-4.284-4.284-.918 0-4.284 0-4.284 4.284 0 4.266 3.366 4.266 4.284 4.266.162 0 .324-.018.468-.018L7.053 24ZM17.942 14.13 14.484 24h1.022l.84-2.394h4.802l.84 2.394h1.022l-3.458-9.87h-1.61Zm.686.938h.238l1.96 5.6h-4.158l1.96-5.6ZM24.464 24h3.906c.91 0 2.8-.294 2.8-2.562v-.294c0-.854-.392-1.988-1.456-2.17v-.182c.756-.518.812-1.302.812-1.862v-.308c0-2.226-2.072-2.492-2.8-2.492h-3.262V24Zm.966-4.536h2.898c1.358 0 1.876.574 1.876 1.68v.196c0 1.106-.518 1.722-1.876 1.722H25.43v-3.598Zm0-.938v-3.458h2.226c1.358 0 1.904.546 1.904 1.638v.224c0 1.106-.546 1.596-1.904 1.596H25.43Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><path fill="#fff" d="M10.941 15.45c0 .306-.054 1.926-1.8 1.926s-1.8-1.62-1.8-1.926c0-.306.054-1.89 1.8-1.89s1.8 1.584 1.8 1.89ZM7.053 24h2.934l2.664-4.392c.684-1.098.774-2.196.774-4.158 0-4.284-3.366-4.284-4.284-4.284-.918 0-4.284 0-4.284 4.284 0 4.266 3.366 4.266 4.284 4.266.162 0 .324-.018.468-.018L7.053 24Zm10.89-9.87L14.483 24h1.022l.84-2.394h4.802l.84 2.394h1.022l-3.458-9.87h-1.61Zm.685.938h.238l1.96 5.6h-4.158l1.96-5.6ZM24.464 24h3.906c.91 0 2.8-.294 2.8-2.562v-.294c0-.854-.392-1.988-1.456-2.17v-.182c.756-.518.812-1.302.812-1.862v-.308c0-2.226-2.072-2.492-2.8-2.492h-3.262V24Zm.966-4.536h2.898c1.358 0 1.876.574 1.876 1.68v.196c0 1.106-.518 1.722-1.876 1.722H25.43v-3.598Zm0-.938v-3.458h2.226c1.358 0 1.904.546 1.904 1.638v.224c0 1.106-.546 1.596-1.904 1.596H25.43Z"/></svg>'
  },

  '9B': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#729CD1" rx="4"/><path fill="#fff" d="M10.941 15.45c0 .306-.054 1.926-1.8 1.926s-1.8-1.62-1.8-1.926c0-.306.054-1.89 1.8-1.89s1.8 1.584 1.8 1.89ZM7.053 24h2.934l2.664-4.392c.684-1.098.774-2.196.774-4.158 0-4.284-3.366-4.284-4.284-4.284-.918 0-4.284 0-4.284 4.284 0 4.266 3.366 4.266 4.284 4.266.162 0 .324-.018.468-.018L7.053 24ZM17.942 14.13 14.484 24h1.022l.84-2.394h4.802l.84 2.394h1.022l-3.458-9.87h-1.61Zm.686.938h.238l1.96 5.6h-4.158l1.96-5.6ZM24.464 24h3.906c.91 0 2.8-.294 2.8-2.562v-.294c0-.854-.392-1.988-1.456-2.17v-.182c.756-.518.812-1.302.812-1.862v-.308c0-2.226-2.072-2.492-2.8-2.492h-3.262V24Zm.966-4.536h2.898c1.358 0 1.876.574 1.876 1.68v.196c0 1.106-.518 1.722-1.876 1.722H25.43v-3.598Zm0-.938v-3.458h2.226c1.358 0 1.904.546 1.904 1.638v.224c0 1.106-.546 1.596-1.904 1.596H25.43Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><mask id="a" width="36" height="36" x="0" y="0" maskUnits="userSpaceOnUse" style="mask-type:alpha"><rect width="36" height="36" fill="#464646" rx="4"/></mask><g stroke="#000" stroke-width="4" mask="url(#a)"><path d="M31.843-22.295-15.172 24.72M37.661-16.476-9.353 30.538M43.48-10.658-3.535 36.356M49.298-4.84 2.284 42.176M55.117.979 8.102 47.993M60.935 6.797 13.921 53.812"/></g><path fill="#fff" d="M20.4 14.6c0 .408-.071 2.568-2.4 2.568-2.327 0-2.4-2.16-2.4-2.568 0-.408.073-2.52 2.4-2.52 2.329 0 2.4 2.112 2.4 2.52ZM15.218 26h3.912l3.552-5.856c.912-1.464 1.032-2.928 1.032-5.544 0-5.712-4.488-5.712-5.712-5.712-1.224 0-5.712 0-5.712 5.712 0 5.688 4.488 5.688 5.712 5.688.216 0 .432-.024.624-.024L15.217 26Z"/></svg>'
  },

  '9C': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#6E73B1" rx="4"/><path fill="#fff" d="M15.37 15.45c0 .306-.053 1.926-1.8 1.926-1.745 0-1.8-1.62-1.8-1.926 0-.306.055-1.89 1.8-1.89 1.747 0 1.8 1.584 1.8 1.89ZM11.484 24h2.934l2.664-4.392c.684-1.098.774-2.196.774-4.158 0-4.284-3.366-4.284-4.284-4.284-.918 0-4.284 0-4.284 4.284 0 4.266 3.366 4.266 4.284 4.266.162 0 .324-.018.468-.018L11.483 24ZM19.264 19.058c0 .91.028 5.054 3.892 5.054 2.744 0 3.332-2.156 3.374-3.01h-.966c-.182 2.086-2.338 2.072-2.408 2.072-2.394 0-2.926-2.142-2.926-4.116 0-1.974.532-4.102 2.926-4.102.07 0 2.226-.014 2.408 2.072h.966c-.042-.854-.63-3.01-3.374-3.01-3.864 0-3.892 4.13-3.892 5.04Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><path fill="#fff" d="M15.37 15.45c0 .306-.053 1.926-1.8 1.926-1.745 0-1.8-1.62-1.8-1.926 0-.306.055-1.89 1.8-1.89 1.747 0 1.8 1.584 1.8 1.89ZM11.484 24h2.934l2.664-4.392c.684-1.098.774-2.196.774-4.158 0-4.284-3.366-4.284-4.284-4.284-.918 0-4.284 0-4.284 4.284 0 4.266 3.366 4.266 4.284 4.266.162 0 .324-.018.468-.018L11.483 24Zm7.78-4.942c0 .91.029 5.054 3.893 5.054 2.744 0 3.332-2.156 3.374-3.01h-.966c-.182 2.086-2.338 2.072-2.408 2.072-2.394 0-2.926-2.142-2.926-4.116 0-1.974.532-4.102 2.926-4.102.07 0 2.226-.014 2.408 2.072h.966c-.042-.854-.63-3.01-3.374-3.01-3.864 0-3.892 4.13-3.892 5.04Z"/></svg>'
  },

  '11': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#2A6A61" rx="4"/><path fill="#fff" d="M11.491 22.808h-2.88V26h8.808v-3.192h-2.616V9.08h-3.048l-3.984 1.512v3.576l3.72-1.464v10.104ZM22.296 22.808h-2.88V26h8.808v-3.192h-2.616V9.08H22.56l-3.984 1.512v3.576l3.72-1.464v10.104Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><path fill="#fff" d="M11.491 22.808h-2.88V26h8.808v-3.192h-2.616V9.08h-3.048l-3.984 1.512v3.576l3.72-1.464v10.104Zm10.805 0h-2.88V26h8.808v-3.192h-2.616V9.08H22.56l-3.984 1.512v3.576l3.72-1.464v10.104Z"/></svg>'
  },

  '12': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#E3743F" rx="4"/><path fill="#fff" d="M10.155 22.808h-2.88V26h8.808v-3.192h-2.616V9.08H10.42l-3.984 1.512v3.576l3.72-1.464v10.104ZM21.176 22.808c.6-2.472 8.064-3.552 8.064-8.856 0-4.92-4.896-5.064-5.352-5.064-.36 0-5.496 0-5.496 5.424h3.312c0-.48 0-2.208 2.184-2.208 1.896 0 2.04 1.152 2.04 1.824 0 2.856-8.136 3.96-8.136 9.192V26H29.12v-3.192h-7.944Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><path fill="#fff" d="M10.155 22.808h-2.88V26h8.808v-3.192h-2.616V9.08H10.42l-3.984 1.512v3.576l3.72-1.464v10.104ZM21.176 22.808c.6-2.472 8.064-3.552 8.064-8.856 0-4.92-4.896-5.064-5.352-5.064-.36 0-5.496 0-5.496 5.424h3.312c0-.48 0-2.208 2.184-2.208 1.896 0 2.04 1.152 2.04 1.824 0 2.856-8.136 3.96-8.136 9.192V26H29.12v-3.192h-7.944Z"/></svg>'
  },

  '12-3': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="url(#a)" rx="4"/><path fill="#fff" d="M5.86 21.446H4.126V23h4.942v-1.554H7.499V13.13H5.86l-2.254.854v1.75l2.254-.84v6.552ZM11.87 21.432c0-1.876 4.913-2.282 4.913-5.544 0-2.786-2.786-2.87-3.052-2.87-.196 0-3.164 0-3.164 2.898h1.638c0-.112 0-1.344 1.526-1.344 1.33 0 1.414.826 1.414 1.302 0 1.974-4.914 2.422-4.914 5.572V23h6.482v-1.554l-4.844-.014ZM24.461 10.484h-1.708l-4.508 14.532h1.708l4.508-14.532ZM32.028 15.272V13.13h-5.754v1.554h3.822v.252l-2.534 2.324v1.358l1.386.028c1.33 0 1.666 1.05 1.666 1.526 0 .504-.168 1.386-1.498 1.386-1.526 0-1.61-1.232-1.61-1.344h-1.638c0 2.898 3.052 2.898 3.248 2.898.266 0 3.136-.14 3.136-2.926 0-1.904-1.316-2.744-2.254-2.94l2.03-1.974Z"/><defs><linearGradient id="a" x1="0" x2="36" y1="36" y2="0" gradientUnits="userSpaceOnUse"><stop stop-color="#E3743F"/><stop offset="1" stop-color="#D590BB"/></linearGradient></defs></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><path fill="#fff" d="M5.86 21.446H4.126V23h4.942v-1.554H7.499V13.13H5.86l-2.254.854v1.75l2.254-.84v6.552Zm6.01-.014c0-1.876 4.913-2.282 4.913-5.544 0-2.786-2.786-2.87-3.052-2.87-.196 0-3.164 0-3.164 2.898h1.638c0-.112 0-1.344 1.526-1.344 1.33 0 1.414.826 1.414 1.302 0 1.974-4.914 2.422-4.914 5.572V23h6.482v-1.554l-4.844-.014ZM24.46 10.484h-1.708l-4.508 14.532h1.708l4.508-14.532Zm7.567 4.788V13.13h-5.754v1.554h3.822v.252l-2.534 2.324v1.358l1.386.028c1.33 0 1.666 1.05 1.666 1.526 0 .504-.168 1.386-1.498 1.386-1.526 0-1.61-1.232-1.61-1.344h-1.638c0 2.898 3.052 2.898 3.248 2.898.266 0 3.136-.14 3.136-2.926 0-1.904-1.316-2.744-2.254-2.94l2.03-1.974Z"/></svg>'
  },

  '13-4': {
    normal: '<svg id="Symbole" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 269.29 269.29">\n' +
        '  <defs>\n' +
        '    <style>\n' +
        '      .cls-1 {\n' +
        '        fill: #8bcbb7;\n' +
        '      }\n' +
        '\n' +
        '      .cls-1, .cls-2 {\n' +
        '        stroke-width: 0px;\n' +
        '      }\n' +
        '\n' +
        '      .cls-2 {\n' +
        '        fill: #fff;\n' +
        '      }\n' +
        '    </style>\n' +
        '  </defs>\n' +
        '  <rect class="cls-1" width="269.29" height="269.29" rx="18.81" ry="18.81"/>\n' +
        '  <g>\n' +
        '    <path class="cls-2" d="m64.72,122.81l-19.36.04-.16-74.62-23.09,7.21-.03-15.74,40.36-14.55h2.08s.2,97.65.2,97.65Z"/>\n' +
        '    <path class="cls-2" d="m104.63,65.37l10.32-.02c4.91-.01,8.55-1.25,10.91-3.71,2.36-2.46,3.54-5.72,3.53-9.79,0-3.93-1.19-6.99-3.54-9.17-2.35-2.18-5.58-3.27-9.69-3.26-3.71,0-6.81,1.03-9.3,3.07-2.5,2.04-3.74,4.69-3.74,7.94l-19.36.04c-.01-5.09,1.35-9.66,4.09-13.71,2.74-4.05,6.57-7.21,11.5-9.5,4.93-2.29,10.36-3.44,16.3-3.45,10.32-.02,18.4,2.43,24.26,7.35,5.86,4.92,8.8,11.71,8.82,20.38,0,4.47-1.35,8.58-4.06,12.33-2.72,3.76-6.28,6.64-10.7,8.66,5.5,1.95,9.6,4.89,12.31,8.82,2.71,3.92,4.07,8.56,4.08,13.92.02,8.66-3.13,15.61-9.43,20.85-6.31,5.24-14.66,7.87-25.07,7.89-9.73.02-17.7-2.53-23.89-7.65-6.2-5.12-9.3-11.9-9.32-20.34l19.36-.04c0,3.66,1.39,6.65,4.14,8.97,2.75,2.32,6.14,3.47,10.15,3.46,4.6,0,8.2-1.23,10.81-3.67,2.61-2.44,3.91-5.67,3.9-9.69-.02-9.73-5.39-14.59-16.11-14.57l-10.25.02-.03-15.14Z"/>\n' +
        '  </g>\n' +
        '  <path class="cls-2" d="m76.01,236.02l-8.31-8.27L224.73,70.06l8.31,8.27L76.01,236.02Z"/>\n' +
        '  <path class="cls-2" d="m235.91,210.17l11.05-.02.03,15.61-11.05.02.04,21.1-19.36.04-.04-21.1-39.99.08-.9-12.19,40.52-64.32,19.56-.04.13,60.82Zm-40.92.09l21.57-.05-.07-34.43-1.27,2.21-20.23,32.26Z"/>\n' +
        '</svg>',
    contrast: '<svg id="Symbole" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 269.29 269.29">\n' +
        '  <defs>\n' +
        '    <style>\n' +
        '      .cls-1 {\n' +
        '        fill: #8bcbb7;\n' +
        '      }\n' +
        '\n' +
        '      .cls-1, .cls-2 {\n' +
        '        stroke-width: 0px;\n' +
        '      }\n' +
        '\n' +
        '      .cls-2 {\n' +
        '        fill: #fff;\n' +
        '      }\n' +
        '    </style>\n' +
        '  </defs>\n' +
        '  <rect class="cls-1" width="269.29" height="269.29" rx="18.81" ry="18.81"/>\n' +
        '  <g>\n' +
        '    <path class="cls-2" d="m64.72,122.81l-19.36.04-.16-74.62-23.09,7.21-.03-15.74,40.36-14.55h2.08s.2,97.65.2,97.65Z"/>\n' +
        '    <path class="cls-2" d="m104.63,65.37l10.32-.02c4.91-.01,8.55-1.25,10.91-3.71,2.36-2.46,3.54-5.72,3.53-9.79,0-3.93-1.19-6.99-3.54-9.17-2.35-2.18-5.58-3.27-9.69-3.26-3.71,0-6.81,1.03-9.3,3.07-2.5,2.04-3.74,4.69-3.74,7.94l-19.36.04c-.01-5.09,1.35-9.66,4.09-13.71,2.74-4.05,6.57-7.21,11.5-9.5,4.93-2.29,10.36-3.44,16.3-3.45,10.32-.02,18.4,2.43,24.26,7.35,5.86,4.92,8.8,11.71,8.82,20.38,0,4.47-1.35,8.58-4.06,12.33-2.72,3.76-6.28,6.64-10.7,8.66,5.5,1.95,9.6,4.89,12.31,8.82,2.71,3.92,4.07,8.56,4.08,13.92.02,8.66-3.13,15.61-9.43,20.85-6.31,5.24-14.66,7.87-25.07,7.89-9.73.02-17.7-2.53-23.89-7.65-6.2-5.12-9.3-11.9-9.32-20.34l19.36-.04c0,3.66,1.39,6.65,4.14,8.97,2.75,2.32,6.14,3.47,10.15,3.46,4.6,0,8.2-1.23,10.81-3.67,2.61-2.44,3.91-5.67,3.9-9.69-.02-9.73-5.39-14.59-16.11-14.57l-10.25.02-.03-15.14Z"/>\n' +
        '  </g>\n' +
        '  <path class="cls-2" d="m76.01,236.02l-8.31-8.27L224.73,70.06l8.31,8.27L76.01,236.02Z"/>\n' +
        '  <path class="cls-2" d="m235.91,210.17l11.05-.02.03,15.61-11.05.02.04,21.1-19.36.04-.04-21.1-39.99.08-.9-12.19,40.52-64.32,19.56-.04.13,60.82Zm-40.92.09l21.57-.05-.07-34.43-1.27,2.21-20.23,32.26Z"/>\n' +
        '</svg>'
  },


  '14': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#B8D6E9" rx="4"/><path fill="#fff" d="M10.566 22.808h-2.88V26h8.808v-3.192h-2.617V9.08H10.83l-3.983 1.512v3.576l3.72-1.464v10.104ZM24.403 26h3.312v-3.984h1.68v-3.192h-1.68V9.08h-4.8l-5.856 9.96v2.976h7.344V26Zm0-12.984v5.808h-3.168v-.36l3.168-5.448Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><path fill="#fff" d="M10.566 22.808h-2.88V26h8.808v-3.192h-2.617V9.08H10.83l-3.983 1.512v3.576l3.72-1.464v10.104ZM24.403 26h3.312v-3.984h1.68v-3.192h-1.68V9.08h-4.8l-5.856 9.96v2.976h7.344V26Zm0-12.984v5.808h-3.168v-.36l3.168-5.448Z"/></svg>'
  },

  '15': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#B6A095" rx="4"/><path fill="#fff" d="M10.39 22.808H7.51V26h8.808v-3.192h-2.616V9.08h-3.048L6.67 10.592v3.576l3.72-1.464v10.104ZM21.362 19.256c.336-.504 1.056-1.08 2.472-1.08 1.92 0 2.184 1.416 2.184 2.424 0 1.104-.264 2.4-2.184 2.4-2.208 0-2.328-1.8-2.328-2.232h-3.312c0 5.424 4.464 5.424 5.64 5.424 1.176 0 5.496-.264 5.496-5.616 0-5.352-4.392-5.616-4.848-5.616-1.008 0-2.136.048-2.928.552v-3.24h6.096V9.08h-9.408v10.176h3.12Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><path fill="#fff" d="M10.39 22.808H7.51V26h8.808v-3.192h-2.616V9.08h-3.048L6.67 10.592v3.576l3.72-1.464v10.104Zm10.972-3.552c.336-.504 1.056-1.08 2.472-1.08 1.92 0 2.184 1.416 2.184 2.424 0 1.104-.264 2.4-2.184 2.4-2.208 0-2.328-1.8-2.328-2.232h-3.312c0 5.424 4.464 5.424 5.64 5.424 1.176 0 5.496-.264 5.496-5.616 0-5.352-4.392-5.616-4.848-5.616-1.008 0-2.136.048-2.928.552v-3.24h6.096V9.08h-9.408v10.176h3.12Z"/></svg>'
  },

  '908': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#BB6235" rx="4"/><path fill="#fff" d="M8.788 15.45c0 .306-.054 1.926-1.8 1.926s-1.8-1.62-1.8-1.926c0-.306.054-1.89 1.8-1.89s1.8 1.584 1.8 1.89ZM4.9 24h2.934l2.664-4.392c.684-1.098.774-2.196.774-4.158 0-4.284-3.366-4.284-4.284-4.284-.918 0-4.284 0-4.284 4.284 0 4.266 3.366 4.266 4.284 4.266.162 0 .324-.018.468-.018L4.9 24ZM17.589 11.166c-4.986 0-5.004 5.328-5.004 6.498s.018 6.48 5.004 6.48 5.004-5.31 5.004-6.48c0-1.17-.018-6.498-5.004-6.498Zm0 10.584c-2.142 0-2.52-2.196-2.52-4.086 0-1.908.378-4.104 2.52-4.104s2.52 2.196 2.52 4.104c0 1.89-.378 4.086-2.52 4.086ZM28.601 11.166c-.918 0-4.05.09-4.05 3.852 0 .99.378 1.872 1.08 2.412v.252a3.092 3.092 0 0 0-1.368 2.61c0 3.762 3.384 3.852 4.302 3.852.99 0 4.374-.09 4.374-3.852 0-1.17-.576-2.07-1.368-2.61v-.252c.702-.54 1.08-1.422 1.08-2.412 0-3.762-3.132-3.852-4.05-3.852Zm0 10.584c-1.782 0-1.836-1.152-1.836-1.458 0-.288.054-1.44 1.8-1.44 1.818 0 1.872 1.152 1.872 1.44 0 .306-.054 1.458-1.836 1.458Zm0-5.292c-1.476-.018-1.53-1.152-1.53-1.44 0-.306.054-1.458 1.53-1.458s1.53 1.152 1.53 1.458c0 .288-.054 1.422-1.53 1.44Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><path fill="#fff" d="M8.788 15.45c0 .306-.054 1.926-1.8 1.926s-1.8-1.62-1.8-1.926c0-.306.054-1.89 1.8-1.89s1.8 1.584 1.8 1.89ZM4.9 24h2.934l2.664-4.392c.684-1.098.774-2.196.774-4.158 0-4.284-3.366-4.284-4.284-4.284-.918 0-4.284 0-4.284 4.284 0 4.266 3.366 4.266 4.284 4.266.162 0 .324-.018.468-.018L4.9 24Zm12.689-12.834c-4.986 0-5.004 5.328-5.004 6.498s.018 6.48 5.004 6.48 5.004-5.31 5.004-6.48c0-1.17-.018-6.498-5.004-6.498Zm0 10.584c-2.142 0-2.52-2.196-2.52-4.086 0-1.908.378-4.104 2.52-4.104s2.52 2.196 2.52 4.104c0 1.89-.378 4.086-2.52 4.086ZM28.6 11.166c-.918 0-4.05.09-4.05 3.852 0 .99.378 1.872 1.08 2.412v.252a3.092 3.092 0 0 0-1.368 2.61c0 3.762 3.384 3.852 4.302 3.852.99 0 4.374-.09 4.374-3.852 0-1.17-.576-2.07-1.368-2.61v-.252c.702-.54 1.08-1.422 1.08-2.412 0-3.762-3.132-3.852-4.05-3.852Zm0 10.584c-1.782 0-1.836-1.152-1.836-1.458 0-.288.054-1.44 1.8-1.44 1.818 0 1.872 1.152 1.872 1.44 0 .306-.054 1.458-1.836 1.458Zm0-5.292c-1.476-.018-1.53-1.152-1.53-1.44 0-.306.054-1.458 1.53-1.458s1.53 1.152 1.53 1.458c0 .288-.054 1.422-1.53 1.44Z"/></svg>'
  },

  'AST': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#F0DD8A" rx="4"/><mask id="a" width="36" height="36" x="0" y="0" maskUnits="userSpaceOnUse" style="mask-type:alpha"><rect width="36" height="36" fill="#C6C7C7" rx="4"/></mask><g mask="url(#a)"><path fill="#E2BE35" fill-rule="evenodd" d="M59.52 5.383 12.507 52.398l2.829 2.828L62.349 8.212l-2.828-2.829Zm-64.469 29.56 47.014-47.015 2.829 2.828-47.015 47.015-2.828-2.829ZM30.428-23.71l-47.014 47.014 2.828 2.829 47.015-47.015-2.828-2.828Zm5.819 5.818-47.014 47.015 2.828 2.828 47.014-47.014-2.828-2.829ZM47.884-6.254.869 40.761l2.829 2.828L50.712-3.425l-2.828-2.829ZM6.688 46.58 53.702-.435l2.829 2.828L9.516 49.408l-2.828-2.829Z" clip-rule="evenodd"/></g><path fill="#fff" d="M6.832 11.31 2.854 24h2.628l.864-2.772h4.464L11.674 24h2.628l-3.978-12.69H6.832Zm1.62 3.114h.252l1.35 4.392h-2.97l1.368-4.392Zm9.205.414c0-.504.198-1.278 1.638-1.278 1.656 0 1.764 1.314 1.764 1.692 0 0 .342-.018 2.484-.018 0-4.068-3.996-4.068-4.248-4.068-.36 0-4.122 0-4.122 3.69 0 4.158 6.174 3.672 6.174 5.67 0 .018-.036 1.224-1.926 1.224-1.854 0-2.124-1.908-2.124-1.908h-2.484c.18 2.988 2.286 4.302 4.608 4.302 2.826 0 4.41-1.44 4.41-3.6 0-4.23-6.174-3.924-6.174-5.706Zm15.391-1.134V11.31h-8.712v2.394h3.114V24h2.484V13.704h3.114Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><mask id="a" width="36" height="36" x="0" y="0" maskUnits="userSpaceOnUse" style="mask-type:alpha"><rect width="36" height="36" fill="#C6C7C7" rx="4"/></mask><g mask="url(#a)"><path fill="#464646" fill-rule="evenodd" d="M59.52 5.383 12.507 52.398l2.829 2.828L62.349 8.212l-2.828-2.829Zm-64.469 29.56 47.014-47.015 2.829 2.828-47.015 47.015-2.828-2.829ZM30.428-23.71l-47.014 47.014 2.828 2.829 47.015-47.015-2.828-2.828Zm5.819 5.818-47.014 47.015 2.828 2.828 47.014-47.014-2.828-2.829ZM47.884-6.254.869 40.761l2.829 2.828L50.712-3.425l-2.828-2.829ZM6.688 46.58 53.702-.435l2.829 2.828L9.516 49.408l-2.828-2.829Z" clip-rule="evenodd"/></g><path fill="#fff" d="M6.832 11.31 2.854 24h2.628l.864-2.772h4.464L11.674 24h2.628l-3.978-12.69H6.832Zm1.62 3.114h.252l1.35 4.392h-2.97l1.368-4.392Zm9.205.414c0-.504.198-1.278 1.638-1.278 1.656 0 1.764 1.314 1.764 1.692 0 0 .342-.018 2.484-.018 0-4.068-3.996-4.068-4.248-4.068-.36 0-4.122 0-4.122 3.69 0 4.158 6.174 3.672 6.174 5.67 0 .018-.036 1.224-1.926 1.224-1.854 0-2.124-1.908-2.124-1.908h-2.484c.18 2.988 2.286 4.302 4.608 4.302 2.826 0 4.41-1.44 4.41-3.6 0-4.23-6.174-3.924-6.174-5.706Zm15.391-1.134V11.31h-8.712v2.394h3.114V24h2.484V13.704h3.114Z"/></svg>'
  },

  'B': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#D7303E" rx="4"/><path fill="#fff" d="M12.312 26H19.2c1.368 0 5.208-.576 5.208-4.848v-.456c0-1.656-.864-2.88-2.064-3.528v-.384c.72-.648 1.104-1.416 1.104-2.496v-.456c0-4.296-3.84-4.752-5.232-4.752h-5.904V26Zm3.312-6.96h3.528c1.416.096 1.944.624 1.944 1.656v.48c0 1.032-.528 1.536-1.944 1.632h-3.528V19.04Zm0-3.192v-3.576h2.568c1.416.096 1.944.504 1.944 1.536v.48c0 1.056-.528 1.464-1.944 1.56h-2.568Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><path fill="#fff" d="M12.312 26H19.2c1.368 0 5.208-.576 5.208-4.848v-.456c0-1.656-.864-2.88-2.064-3.528v-.384c.72-.648 1.104-1.416 1.104-2.496v-.456c0-4.296-3.84-4.752-5.232-4.752h-5.904V26Zm3.312-6.96h3.528c1.416.096 1.944.624 1.944 1.656v.48c0 1.032-.528 1.536-1.944 1.632h-3.528V19.04Zm0-3.192v-3.576h2.568c1.416.096 1.944.504 1.944 1.536v.48c0 1.056-.528 1.464-1.944 1.56h-2.568Z"/></svg>'
  },

  'N': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#C6C7C7" rx="4"/><rect width="36" height="36" fill="#C4C4C4" rx="4"/><mask id="a" width="36" height="36" x="0" y="0" maskUnits="userSpaceOnUse" style="mask-type:alpha"><rect width="36" height="36" fill="#C6C7C7" rx="4"/></mask><g stroke="#ABACAC" stroke-width="4" mask="url(#a)"><path d="M31.843-22.295-15.172 24.72M37.661-16.476-9.353 30.538M43.48-10.658-3.535 36.356M49.298-4.84 2.284 42.176M55.117.979 8.102 47.993M60.935 6.797 13.921 53.812"/></g><path fill="#fff" d="M14.874 15.704h.36L20.586 26h3.864V9.08h-3.288v10.152h-.36L15.546 9.08h-3.984V26h3.312V15.704Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><rect width="36" height="36" fill="#000" rx="4"/><mask id="a" width="36" height="36" x="0" y="0" maskUnits="userSpaceOnUse" style="mask-type:alpha"><rect width="36" height="36" fill="#C6C7C7" rx="4"/></mask><g stroke="#464646" stroke-width="4" mask="url(#a)"><path d="M31.843-22.295-15.172 24.72M37.66-16.476-9.353 30.538M43.48-10.658-3.535 36.356M49.298-4.84 2.283 42.176M55.117.979 8.102 47.993M60.935 6.797 13.92 53.812"/></g><path fill="#fff" d="M14.874 15.704h.36L20.586 26h3.864V9.08h-3.288v10.152h-.36L15.546 9.08h-3.984V26h3.312V15.704Z"/></svg>'
  },

  'N13': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#C6C7C7" rx="4"/><rect width="36" height="36" fill="#C4C4C4" rx="4"/><mask id="a" width="36" height="36" x="0" y="0" maskUnits="userSpaceOnUse" style="mask-type:alpha"><rect width="36" height="36" fill="#C6C7C7" rx="4"/></mask><g stroke="#ABACAC" stroke-width="4" mask="url(#a)"><path d="M31.843-22.295-15.172 24.72M37.661-16.476-9.353 30.538M43.48-10.658-3.535 36.356M49.298-4.84 2.284 42.176M55.117.979 8.102 47.993M60.935 6.797 13.921 53.812"/></g><path fill="#fff" d="M14.874 15.704h.36L20.586 26h3.864V9.08h-3.288v10.152h-.36L15.546 9.08h-3.984V26h3.312V15.704Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><rect width="36" height="36" fill="#000" rx="4"/><mask id="a" width="36" height="36" x="0" y="0" maskUnits="userSpaceOnUse" style="mask-type:alpha"><rect width="36" height="36" fill="#C6C7C7" rx="4"/></mask><g stroke="#464646" stroke-width="4" mask="url(#a)"><path d="M31.843-22.295-15.172 24.72M37.66-16.476-9.353 30.538M43.48-10.658-3.535 36.356M49.298-4.84 2.283 42.176M55.117.979 8.102 47.993M60.935 6.797 13.92 53.812"/></g><path fill="#fff" d="M14.874 15.704h.36L20.586 26h3.864V9.08h-3.288v10.152h-.36L15.546 9.08h-3.984V26h3.312V15.704Z"/></svg>'
  },

  'N14': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#C6C7C7" rx="4"/><rect width="36" height="36" fill="#C4C4C4" rx="4"/><mask id="a" width="36" height="36" x="0" y="0" maskUnits="userSpaceOnUse" style="mask-type:alpha"><rect width="36" height="36" fill="#C6C7C7" rx="4"/></mask><g stroke="#ABACAC" stroke-width="4" mask="url(#a)"><path d="M31.843-22.295-15.172 24.72M37.661-16.476-9.353 30.538M43.48-10.658-3.535 36.356M49.298-4.84 2.284 42.176M55.117.979 8.102 47.993M60.935 6.797 13.921 53.812"/></g><path fill="#fff" d="M14.874 15.704h.36L20.586 26h3.864V9.08h-3.288v10.152h-.36L15.546 9.08h-3.984V26h3.312V15.704Z"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><rect width="36" height="36" fill="#000" rx="4"/><mask id="a" width="36" height="36" x="0" y="0" maskUnits="userSpaceOnUse" style="mask-type:alpha"><rect width="36" height="36" fill="#C6C7C7" rx="4"/></mask><g stroke="#464646" stroke-width="4" mask="url(#a)"><path d="M31.843-22.295-15.172 24.72M37.66-16.476-9.353 30.538M43.48-10.658-3.535 36.356M49.298-4.84 2.283 42.176M55.117.979 8.102 47.993M60.935 6.797 13.92 53.812"/></g><path fill="#fff" d="M14.874 15.704h.36L20.586 26h3.864V9.08h-3.288v10.152h-.36L15.546 9.08h-3.984V26h3.312V15.704Z"/></svg>'
  },

  'P-R': {
    //CS icon....
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#98C271" rx="4"/><path fill="#fff" d="M13.373 22.75c-2.16 0-2.646-2.196-2.646-4.086 0-1.908.486-4.104 2.646-4.104 0 0 2.052-.018 2.106 2.214h2.484s-.072-4.608-4.59-4.608c-5.112 0-5.13 5.328-5.13 6.498s.018 6.48 5.13 6.48c4.518 0 4.59-4.608 4.59-4.608h-2.484c-.054 2.232-2.106 2.214-2.106 2.214Zm8.397-6.912c0-.504.198-1.278 1.638-1.278 1.656 0 1.764 1.314 1.764 1.692 0 0 .342-.018 2.484-.018 0-4.068-3.996-4.068-4.248-4.068-.36 0-4.122 0-4.122 3.69 0 4.158 6.174 3.672 6.174 5.67 0 .018-.036 1.224-1.926 1.224-1.854 0-2.124-1.908-2.124-1.908h-2.484c.18 2.988 2.286 4.302 4.608 4.302 2.826 0 4.41-1.44 4.41-3.6 0-4.23-6.174-3.924-6.174-5.706Z"/></svg>',
    //ALSO CS
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><path fill="#fff" d="M13.373 22.75c-2.16 0-2.646-2.196-2.646-4.086 0-1.908.486-4.104 2.646-4.104 0 0 2.052-.018 2.106 2.214h2.484s-.072-4.608-4.59-4.608c-5.112 0-5.13 5.328-5.13 6.498s.018 6.48 5.13 6.48c4.518 0 4.59-4.608 4.59-4.608h-2.484c-.054 2.232-2.106 2.214-2.106 2.214Zm8.397-6.912c0-.504.198-1.278 1.638-1.278 1.656 0 1.764 1.314 1.764 1.692 0 0 .342-.018 2.484-.018 0-4.068-3.996-4.068-4.248-4.068-.36 0-4.122 0-4.122 3.69 0 4.158 6.174 3.672 6.174 5.67 0 .018-.036 1.224-1.926 1.224-1.854 0-2.124-1.908-2.124-1.908h-2.484c.18 2.988 2.286 4.302 4.608 4.302 2.826 0 4.41-1.44 4.41-3.6 0-4.23-6.174-3.924-6.174-5.706Z"/></svg>'
  },

  'CS': {
    normal: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#98C271" rx="4"/><path fill="#fff" d="M13.373 22.75c-2.16 0-2.646-2.196-2.646-4.086 0-1.908.486-4.104 2.646-4.104 0 0 2.052-.018 2.106 2.214h2.484s-.072-4.608-4.59-4.608c-5.112 0-5.13 5.328-5.13 6.498s.018 6.48 5.13 6.48c4.518 0 4.59-4.608 4.59-4.608h-2.484c-.054 2.232-2.106 2.214-2.106 2.214Zm8.397-6.912c0-.504.198-1.278 1.638-1.278 1.656 0 1.764 1.314 1.764 1.692 0 0 .342-.018 2.484-.018 0-4.068-3.996-4.068-4.248-4.068-.36 0-4.122 0-4.122 3.69 0 4.158 6.174 3.672 6.174 5.67 0 .018-.036 1.224-1.926 1.224-1.854 0-2.124-1.908-2.124-1.908h-2.484c.18 2.988 2.286 4.302 4.608 4.302 2.826 0 4.41-1.44 4.41-3.6 0-4.23-6.174-3.924-6.174-5.706Z"/></svg>',

    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="36" height="36" fill="#000" rx="4"/><path fill="#fff" d="M13.373 22.75c-2.16 0-2.646-2.196-2.646-4.086 0-1.908.486-4.104 2.646-4.104 0 0 2.052-.018 2.106 2.214h2.484s-.072-4.608-4.59-4.608c-5.112 0-5.13 5.328-5.13 6.498s.018 6.48 5.13 6.48c4.518 0 4.59-4.608 4.59-4.608h-2.484c-.054 2.232-2.106 2.214-2.106 2.214Zm8.397-6.912c0-.504.198-1.278 1.638-1.278 1.656 0 1.764 1.314 1.764 1.692 0 0 .342-.018 2.484-.018 0-4.068-3.996-4.068-4.248-4.068-.36 0-4.122 0-4.122 3.69 0 4.158 6.174 3.672 6.174 5.67 0 .018-.036 1.224-1.926 1.224-1.854 0-2.124-1.908-2.124-1.908h-2.484c.18 2.988 2.286 4.302 4.608 4.302 2.826 0 4.41-1.44 4.41-3.6 0-4.23-6.174-3.924-6.174-5.706Z"/></svg>'
  },

  'cycle': {
    normal: '<svg width="37" height="36" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x=".5" width="36" height="36" rx="6" fill="#ACACAC"/><path d="M22.708 20.1a3.562 3.562 0 0 1 1.98-.6 3.574 3.574 0 0 1 3.562 3.563 3.562 3.562 0 1 1-5.542-2.963ZM10.333 20.1a3.562 3.562 0 0 1 1.98-.6 3.574 3.574 0 0 1 3.562 3.563 3.562 3.562 0 1 1-5.542-2.963Z" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10"/><path d="M18.5 22.875v-4.031l-3-1.969 3.75-4.125 1.875 3.375h2.625" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M21.5 12.375A1.495 1.495 0 0 0 23 10.87a1.48 1.48 0 0 0-1.49-1.495 1.5 1.5 0 1 0-.01 3Z" fill="#fff"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" fill="none"><rect width="35" height="35" x="1" y=".5" fill="#fff" rx="5.5"/><path stroke="#000" stroke-miterlimit="10" stroke-width="1.5" d="M22.708 20.1a3.562 3.562 0 0 1 1.98-.6 3.574 3.574 0 0 1 3.562 3.563 3.562 3.562 0 1 1-5.542-2.963ZM10.333 20.1a3.562 3.562 0 0 1 1.98-.6 3.574 3.574 0 0 1 3.562 3.563 3.562 3.562 0 1 1-5.542-2.963Z"/><path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18.5 22.875v-4.031l-3-1.969 3.75-4.125 1.875 3.375h2.625"/><path fill="#000" d="M21.5 12.375A1.495 1.495 0 0 0 23 10.87a1.48 1.48 0 0 0-1.49-1.495 1.5 1.5 0 0 0-.01 3Z"/><rect width="35" height="35" x="1" y=".5" stroke="#000" rx="5.5"/></svg>'
  },

  'walk': {
    normal: '<svg width="37" height="36" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x=".5" width="36" height="36" rx="6" fill="#ACACAC"/><path d="m21.229 28.609-2.662-5.379-2.104-2.69a3.413 3.413 0 0 1-.475-1.737V12.75h.735a1.885 1.885 0 0 1 1.886 1.885v8.595M12.495 19.737v-3.493s1.747-3.494 3.493-3.494M23.848 18.863l-3.098-2.035" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.494 28.423 17 24.75l-2.506 3.673Z" fill="#fff"/><path d="M14.494 28.423 17 24.75M18.609 11.003a1.747 1.747 0 1 0 0-3.493 1.747 1.747 0 0 0 0 3.493Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    contrast: '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><rect width="35" height="35" x=".5" y=".5" fill="#fff" rx="5.5"/><path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m20.729 28.609-2.662-5.379-2.104-2.69a3.414 3.414 0 0 1-.475-1.737V12.75h.735a1.885 1.885 0 0 1 1.886 1.885v8.595"/><path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.995 19.737v-3.493s1.747-3.494 3.494-3.494M23.348 18.863l-3.098-2.035"/><path fill="#000" d="M13.994 28.423 16.5 24.75Z"/><path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13.994 28.423 16.5 24.75M18.109 11.003a1.747 1.747 0 1 0 0-3.493 1.747 1.747 0 0 0 0 3.493Z"/><rect width="35" height="35" x=".5" y=".5" stroke="#000" rx="5.5"/></svg>'
  },
  'chevron-right': '<svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m6.25 3.5 4.5 4.5-4.5 4.5" stroke="#ACACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
}

export const altTags = {
  'walk': 'Zu Fuß',
  'cycle': 'Mit dem Fahrrad',
  'CS': 'Mit dem Carsharing',
  'P-R': 'Park and Ride',
}

export default {};

</script>
