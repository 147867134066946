<script lang="ts">

export const requestResultWithRemainInVehicle ={
    "?xml": "",
    "trias:Trias": {
        "trias:ServiceDelivery": {
            "siri:ResponseTimestamp": "2022-05-03T10:45:28Z",
            "siri:ProducerRef": "de:nvbw",
            "siri:Status": true,
            "trias:Language": "de",
            "trias:CalcTime": 456,
            "trias:DeliveryPayload": {
                "trias:TripResponse": {
                    "trias:TripResponseContext": {
                        "trias:Situations": {
                            "trias:PtSituation": {
                                "siri:CreationTime": "2022-04-29T10:49:00Z",
                                "siri:ParticipantRef": "AllgemeineMeldungenNVBW",
                                "siri:SituationNumber": "26887_ICSNVBW",
                                "siri:Version": 2,
                                "siri:Source": {
                                    "siri:SourceType": "other"
                                },
                                "siri:ValidityPeriod": {
                                    "siri:StartTime": "2022-04-25T22:00:00Z",
                                    "siri:EndTime": "2022-05-25T22:00:00Z"
                                },
                                "siri:UnknownReason": "unknown",
                                "siri:Priority": 3,
                                "siri:ScopeType": "stopPoint",
                                "siri:Summary": "Haltestellen Stadtwerke, Gottmannplatz, Markgrafenstraße und Klingenbergstraße werden vom 26.4.2022 bis 25.5.2022 nicht bedient",
                                "siri:Description": "Haltestellen Stadtwerke, Gottmannplatz, Markgrafenstraße und Klingenbergstraße werden vom 26.4.2022 bis 25.5.2022 nicht bedient",
                                "siri:Detail": "Bitte beachten Sie, dass die Haltestellen&nbsp;Stadtwerke, Gottmannplatz, Markgrafenstra&szlig;e und Klingenbergstra&szlig;e vom 26.4.-25.5.22 nicht bedient werden kann. Die Umleitungsstrecke ist nicht in der Auskunft versorgt, Informationen finden Sie unter&nbsp;<a title=\"Haltestellen werden nicht bedient\" href=\"https://www.stadtwerke-konstanz.de/mobilitaet/infothek-seemobilitaet/\" target=\"_blank\">https://www.stadtwerke-konstanz.de/mobilitaet/infothek-seemobilitaet/</a>",
                                "siri:Affects": {
                                    "siri:StopPoints": {
                                        "siri:AffectedStopPoint": [
                                            {
                                                "siri:StopPointRef": "de:08335:11921"
                                            },
                                            {
                                                "siri:StopPointRef": "de:08335:11850"
                                            },
                                            {
                                                "siri:StopPointRef": "de:08335:11881"
                                            },
                                            {
                                                "siri:StopPointRef": "de:08335:11851"
                                            }
                                        ]
                                    }
                                }
                            }
                        }
                    },
                    "trias:TripResult": [
                        {
                            "trias:ResultId": "ID-E8A8CE71-A4E4-4EE2-8521-89D8C6280482",
                            "trias:Trip": {
                                "trias:TripId": "ID-E8A8CE71-A4E4-4EE2-8521-89D8C6280482",
                                "trias:Duration": "PT10M",
                                "trias:StartTime": "2022-05-03T10:32:00Z",
                                "trias:EndTime": "2022-05-03T10:42:00Z",
                                "trias:Interchanges": 0,
                                "trias:Distance": 2654,
                                "trias:TripLeg": [
                                    {
                                        "trias:LegId": 1,
                                        "trias:TimedLeg": {
                                            "trias:LegBoard": {
                                                "trias:StopPointRef": "de:08335:11921:0:1",
                                                "trias:StopPointName": {
                                                    "trias:Text": "Konstanz Stadtwerke",
                                                    "trias:Language": "de"
                                                },
                                                "trias:ServiceDeparture": {
                                                    "trias:TimetabledTime": "2022-05-03T10:32:00Z"
                                                },
                                                "trias:StopSeqNumber": 1
                                            },
                                            "trias:LegIntermediates": [
                                                {
                                                    "trias:StopPointRef": "de:08335:11850:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Gottmannplatz",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T10:34:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T10:34:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 2
                                                },
                                                {
                                                    "trias:StopPointRef": "de:08335:11881:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Markgrafenstraße",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T10:35:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T10:35:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 3
                                                },
                                                {
                                                    "trias:StopPointRef": "de:08335:11851:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Klingenbergstraße",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T10:36:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T10:36:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 4
                                                },
                                                {
                                                    "trias:StopPointRef": "de:08335:11854:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Hallenbad",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T10:37:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T10:37:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 5
                                                },
                                                {
                                                    "trias:StopPointRef": "de:08335:11920:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Sternenpl./Spanierstr.",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T10:38:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T10:38:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 6
                                                },
                                                {
                                                    "trias:StopPointRef": "de:08335:11868:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Konzilstr./Theater",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T10:40:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T10:40:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 7
                                                }
                                            ],
                                            "trias:LegAlight": {
                                                "trias:StopPointRef": "de:08335:11882:0:1",
                                                "trias:StopPointName": {
                                                    "trias:Text": "Konstanz Marktstätte/Bahnhof",
                                                    "trias:Language": "de"
                                                },
                                                "trias:ServiceArrival": {
                                                    "trias:TimetabledTime": "2022-05-03T10:41:00Z"
                                                },
                                                "trias:StopSeqNumber": 8
                                            },
                                            "trias:Service": {
                                                "trias:OperatingDayRef": "2022-05-03",
                                                "trias:JourneyRef": "vhb:00006::H:j22:195",
                                                "trias:ServiceSection": {
                                                    "trias:LineRef": "vhb:00006::H",
                                                    "trias:DirectionRef": "outward",
                                                    "trias:Mode": {
                                                        "trias:PtMode": "bus",
                                                        "trias:BusSubmode": "localBus",
                                                        "trias:Name": {
                                                            "trias:Text": "Stadtbus",
                                                            "trias:Language": "de"
                                                        }
                                                    },
                                                    "trias:PublishedLineName": {
                                                        "trias:Text": 6,
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:OperatorRef": "vhb:SWK"
                                                },
                                                "trias:OriginText": {
                                                    "trias:Text": "",
                                                    "trias:Language": "de"
                                                },
                                                "trias:DestinationStopPointRef": "de:08335:11882",
                                                "trias:DestinationText": {
                                                    "trias:Text": "Konstanz Marktstätte/Bahnhof",
                                                    "trias:Language": "de"
                                                },
                                                "trias:SituationFullRef": {
                                                    "siri:ParticipantRef": "AllgemeineMeldungenNVBW",
                                                    "siri:SituationNumber": "26887_ICSNVBW"
                                                }
                                            },
                                            "trias:LegTrack": {
                                                "trias:TrackSection": {
                                                    "trias:TrackStart": {
                                                        "trias:StopPointRef": "de:08335:11921:0:1",
                                                        "trias:LocationName": {
                                                            "trias:Text": "Konstanz Stadtwerke",
                                                            "trias:Language": "de"
                                                        }
                                                    },
                                                    "trias:TrackEnd": {
                                                        "trias:StopPointRef": "de:08335:11882:0:1",
                                                        "trias:LocationName": {
                                                            "trias:Text": "Konstanz Marktstätte/Bahnhof",
                                                            "trias:Language": "de"
                                                        }
                                                    },
                                                    "trias:Duration": "PT9M",
                                                    "trias:Length": 2607
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "trias:LegId": 2,
                                        "trias:InterchangeLeg": {
                                            "trias:InterchangeMode": "walk",
                                            "trias:LegStart": {
                                                "trias:StopPointRef": "de:08335:11882:0:1",
                                                "trias:LocationName": {
                                                    "trias:Text": "Konstanz Marktstätte/Bahnhof",
                                                    "trias:Language": "de"
                                                }
                                            },
                                            "trias:LegEnd": {
                                                "trias:StopPointRef": "de:08335:6554",
                                                "trias:LocationName": {
                                                    "trias:Text": "Konstanz Bahnhof",
                                                    "trias:Language": "de"
                                                }
                                            },
                                            "trias:TimeWindowStart": "2022-05-03T10:41:00Z",
                                            "trias:TimeWindowEnd": "2022-05-03T10:42:00Z",
                                            "trias:Duration": "PT1M",
                                            "trias:WalkDuration": "PT1M",
                                            "trias:BufferTime": "PT0M",
                                            "trias:Length": 47,
                                            "trias:NavigationPath": {
                                                "trias:NavigationSection": {
                                                    "trias:TrackSection": {
                                                        "trias:TrackStart": {
                                                            "trias:StopPointRef": "de:08335:11882",
                                                            "trias:LocationName": {
                                                                "trias:Text": "",
                                                                "trias:Language": "de"
                                                            }
                                                        },
                                                        "trias:TrackEnd": {
                                                            "trias:StopPointRef": "de:08335:6554",
                                                            "trias:LocationName": {
                                                                "trias:Text": "",
                                                                "trias:Language": "de"
                                                            }
                                                        }
                                                    },
                                                    "trias:AccessPath": {
                                                        "trias:Transition": "level",
                                                        "trias:AccessFeatureType": "footpath"
                                                    }
                                                }
                                            }
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "trias:ResultId": "ID-1E008641-5B0F-4D0D-8376-0681E8871AB2",
                            "trias:Trip": {
                                "trias:TripId": "ID-1E008641-5B0F-4D0D-8376-0681E8871AB2",
                                "trias:Duration": "PT10M",
                                "trias:StartTime": "2022-05-03T10:47:00Z",
                                "trias:EndTime": "2022-05-03T10:57:00Z",
                                "trias:Interchanges": 0,
                                "trias:Distance": 2654,
                                "trias:TripLeg": [
                                    {
                                        "trias:LegId": 1,
                                        "trias:TimedLeg": {
                                            "trias:LegBoard": {
                                                "trias:StopPointRef": "de:08335:11921:0:1",
                                                "trias:StopPointName": {
                                                    "trias:Text": "Konstanz Stadtwerke",
                                                    "trias:Language": "de"
                                                },
                                                "trias:ServiceDeparture": {
                                                    "trias:TimetabledTime": "2022-05-03T10:47:00Z"
                                                },
                                                "trias:StopSeqNumber": 1
                                            },
                                            "trias:LegIntermediates": [
                                                {
                                                    "trias:StopPointRef": "de:08335:11850:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Gottmannplatz",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T10:49:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T10:49:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 2
                                                },
                                                {
                                                    "trias:StopPointRef": "de:08335:11881:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Markgrafenstraße",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T10:50:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T10:50:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 3
                                                },
                                                {
                                                    "trias:StopPointRef": "de:08335:11851:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Klingenbergstraße",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T10:51:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T10:51:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 4
                                                },
                                                {
                                                    "trias:StopPointRef": "de:08335:11854:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Hallenbad",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T10:52:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T10:52:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 5
                                                },
                                                {
                                                    "trias:StopPointRef": "de:08335:11920:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Sternenpl./Spanierstr.",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T10:53:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T10:53:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 6
                                                },
                                                {
                                                    "trias:StopPointRef": "de:08335:11868:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Konzilstr./Theater",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T10:55:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T10:55:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 7
                                                }
                                            ],
                                            "trias:LegAlight": {
                                                "trias:StopPointRef": "de:08335:11882:0:1",
                                                "trias:StopPointName": {
                                                    "trias:Text": "Konstanz Marktstätte/Bahnhof",
                                                    "trias:Language": "de"
                                                },
                                                "trias:ServiceArrival": {
                                                    "trias:TimetabledTime": "2022-05-03T10:56:00Z"
                                                },
                                                "trias:StopSeqNumber": 8
                                            },
                                            "trias:Service": {
                                                "trias:OperatingDayRef": "2022-05-03",
                                                "trias:JourneyRef": "vhb:00006::H:j22:145",
                                                "trias:ServiceSection": {
                                                    "trias:LineRef": "vhb:00006::H",
                                                    "trias:DirectionRef": "outward",
                                                    "trias:Mode": {
                                                        "trias:PtMode": "bus",
                                                        "trias:BusSubmode": "localBus",
                                                        "trias:Name": {
                                                            "trias:Text": "Stadtbus",
                                                            "trias:Language": "de"
                                                        }
                                                    },
                                                    "trias:PublishedLineName": {
                                                        "trias:Text": 6,
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:OperatorRef": "vhb:SWK"
                                                },
                                                "trias:OriginText": {
                                                    "trias:Text": "",
                                                    "trias:Language": "de"
                                                },
                                                "trias:DestinationStopPointRef": "de:08335:11882",
                                                "trias:DestinationText": {
                                                    "trias:Text": "Konstanz Marktstätte/Bahnhof",
                                                    "trias:Language": "de"
                                                },
                                                "trias:SituationFullRef": {
                                                    "siri:ParticipantRef": "AllgemeineMeldungenNVBW",
                                                    "siri:SituationNumber": "26887_ICSNVBW"
                                                }
                                            },
                                            "trias:LegTrack": {
                                                "trias:TrackSection": {
                                                    "trias:TrackStart": {
                                                        "trias:StopPointRef": "de:08335:11921:0:1",
                                                        "trias:LocationName": {
                                                            "trias:Text": "Konstanz Stadtwerke",
                                                            "trias:Language": "de"
                                                        }
                                                    },
                                                    "trias:TrackEnd": {
                                                        "trias:StopPointRef": "de:08335:11882:0:1",
                                                        "trias:LocationName": {
                                                            "trias:Text": "Konstanz Marktstätte/Bahnhof",
                                                            "trias:Language": "de"
                                                        }
                                                    },
                                                    "trias:Duration": "PT9M",
                                                    "trias:Length": 2607
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "trias:LegId": 2,
                                        "trias:InterchangeLeg": {
                                            "trias:InterchangeMode": "walk",
                                            "trias:LegStart": {
                                                "trias:StopPointRef": "de:08335:11882:0:1",
                                                "trias:LocationName": {
                                                    "trias:Text": "Konstanz Marktstätte/Bahnhof",
                                                    "trias:Language": "de"
                                                }
                                            },
                                            "trias:LegEnd": {
                                                "trias:StopPointRef": "de:08335:6554",
                                                "trias:LocationName": {
                                                    "trias:Text": "Konstanz Bahnhof",
                                                    "trias:Language": "de"
                                                }
                                            },
                                            "trias:TimeWindowStart": "2022-05-03T10:56:00Z",
                                            "trias:TimeWindowEnd": "2022-05-03T10:57:00Z",
                                            "trias:Duration": "PT1M",
                                            "trias:WalkDuration": "PT1M",
                                            "trias:BufferTime": "PT0M",
                                            "trias:Length": 47,
                                            "trias:NavigationPath": {
                                                "trias:NavigationSection": {
                                                    "trias:TrackSection": {
                                                        "trias:TrackStart": {
                                                            "trias:StopPointRef": "de:08335:11882",
                                                            "trias:LocationName": {
                                                                "trias:Text": "",
                                                                "trias:Language": "de"
                                                            }
                                                        },
                                                        "trias:TrackEnd": {
                                                            "trias:StopPointRef": "de:08335:6554",
                                                            "trias:LocationName": {
                                                                "trias:Text": "",
                                                                "trias:Language": "de"
                                                            }
                                                        }
                                                    },
                                                    "trias:AccessPath": {
                                                        "trias:Transition": "level",
                                                        "trias:AccessFeatureType": "footpath"
                                                    }
                                                }
                                            }
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "trias:ResultId": "ID-47D22E51-9BDE-4F78-BA07-0E8B674930CE",
                            "trias:Trip": {
                                "trias:TripId": "ID-47D22E51-9BDE-4F78-BA07-0E8B674930CE",
                                "trias:Duration": "PT10M",
                                "trias:StartTime": "2022-05-03T11:02:00Z",
                                "trias:EndTime": "2022-05-03T11:12:00Z",
                                "trias:Interchanges": 0,
                                "trias:Distance": 2654,
                                "trias:TripLeg": [
                                    {
                                        "trias:LegId": 1,
                                        "trias:TimedLeg": {
                                            "trias:LegBoard": {
                                                "trias:StopPointRef": "de:08335:11921:0:1",
                                                "trias:StopPointName": {
                                                    "trias:Text": "Konstanz Stadtwerke",
                                                    "trias:Language": "de"
                                                },
                                                "trias:ServiceDeparture": {
                                                    "trias:TimetabledTime": "2022-05-03T11:02:00Z",
                                                    "trias:EstimatedTime": "2022-05-03T11:02:00Z"
                                                },
                                                "trias:StopSeqNumber": 1
                                            },
                                            "trias:LegIntermediates": [
                                                {
                                                    "trias:StopPointRef": "de:08335:11850:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Gottmannplatz",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T11:04:00Z",
                                                        "trias:EstimatedTime": "2022-05-03T11:04:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T11:04:00Z",
                                                        "trias:EstimatedTime": "2022-05-03T11:04:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 2
                                                },
                                                {
                                                    "trias:StopPointRef": "de:08335:11881:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Markgrafenstraße",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T11:05:00Z",
                                                        "trias:EstimatedTime": "2022-05-03T11:05:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T11:05:00Z",
                                                        "trias:EstimatedTime": "2022-05-03T11:05:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 3
                                                },
                                                {
                                                    "trias:StopPointRef": "de:08335:11851:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Klingenbergstraße",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T11:06:00Z",
                                                        "trias:EstimatedTime": "2022-05-03T11:06:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T11:06:00Z",
                                                        "trias:EstimatedTime": "2022-05-03T11:06:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 4
                                                },
                                                {
                                                    "trias:StopPointRef": "de:08335:11854:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Hallenbad",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T11:07:00Z",
                                                        "trias:EstimatedTime": "2022-05-03T11:07:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T11:07:00Z",
                                                        "trias:EstimatedTime": "2022-05-03T11:07:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 5
                                                },
                                                {
                                                    "trias:StopPointRef": "de:08335:11920:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Sternenpl./Spanierstr.",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T11:08:00Z",
                                                        "trias:EstimatedTime": "2022-05-03T11:08:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T11:08:00Z",
                                                        "trias:EstimatedTime": "2022-05-03T11:08:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 6
                                                },
                                                {
                                                    "trias:StopPointRef": "de:08335:11868:0:1",
                                                    "trias:StopPointName": {
                                                        "trias:Text": "Konstanz Konzilstr./Theater",
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:ServiceArrival": {
                                                        "trias:TimetabledTime": "2022-05-03T11:10:00Z",
                                                        "trias:EstimatedTime": "2022-05-03T11:10:00Z"
                                                    },
                                                    "trias:ServiceDeparture": {
                                                        "trias:TimetabledTime": "2022-05-03T11:10:00Z",
                                                        "trias:EstimatedTime": "2022-05-03T11:10:00Z"
                                                    },
                                                    "trias:StopSeqNumber": 7
                                                }
                                            ],
                                            "trias:LegAlight": {
                                                "trias:StopPointRef": "de:08335:11882:0:1",
                                                "trias:StopPointName": {
                                                    "trias:Text": "Konstanz Marktstätte/Bahnhof",
                                                    "trias:Language": "de"
                                                },
                                                "trias:ServiceArrival": {
                                                    "trias:TimetabledTime": "2022-05-03T11:11:00Z",
                                                    "trias:EstimatedTime": "2022-05-03T11:11:00Z"
                                                },
                                                "trias:StopSeqNumber": 8
                                            },
                                            "trias:Service": {
                                                "trias:OperatingDayRef": "2022-05-03",
                                                "trias:JourneyRef": "vhb:00006::H:j22:146",
                                                "trias:ServiceSection": {
                                                    "trias:LineRef": "vhb:00006::H",
                                                    "trias:DirectionRef": "outward",
                                                    "trias:Mode": {
                                                        "trias:PtMode": "bus",
                                                        "trias:BusSubmode": "localBus",
                                                        "trias:Name": {
                                                            "trias:Text": "Stadtbus",
                                                            "trias:Language": "de"
                                                        }
                                                    },
                                                    "trias:PublishedLineName": {
                                                        "trias:Text": 6,
                                                        "trias:Language": "de"
                                                    },
                                                    "trias:OperatorRef": "vhb:SWK"
                                                },
                                                "trias:OriginText": {
                                                    "trias:Text": "",
                                                    "trias:Language": "de"
                                                },
                                                "trias:DestinationStopPointRef": "de:08335:11882",
                                                "trias:DestinationText": {
                                                    "trias:Text": "Konstanz Marktstätte/Bahnhof",
                                                    "trias:Language": "de"
                                                },
                                                "trias:SituationFullRef": {
                                                    "siri:ParticipantRef": "AllgemeineMeldungenNVBW",
                                                    "siri:SituationNumber": "26887_ICSNVBW"
                                                }
                                            },
                                            "trias:LegTrack": {
                                                "trias:TrackSection": {
                                                    "trias:TrackStart": {
                                                        "trias:StopPointRef": "de:08335:11921:0:1",
                                                        "trias:LocationName": {
                                                            "trias:Text": "Konstanz Stadtwerke",
                                                            "trias:Language": "de"
                                                        }
                                                    },
                                                    "trias:TrackEnd": {
                                                        "trias:StopPointRef": "de:08335:11882:0:1",
                                                        "trias:LocationName": {
                                                            "trias:Text": "Konstanz Marktstätte/Bahnhof",
                                                            "trias:Language": "de"
                                                        }
                                                    },
                                                    "trias:Duration": "PT9M",
                                                    "trias:Length": 2607
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "trias:LegId": 2,
                                        "trias:InterchangeLeg": {
                                            "trias:InterchangeMode": "walk",
                                            "trias:LegStart": {
                                                "trias:StopPointRef": "de:08335:11882:0:1",
                                                "trias:LocationName": {
                                                    "trias:Text": "Konstanz Marktstätte/Bahnhof",
                                                    "trias:Language": "de"
                                                }
                                            },
                                            "trias:LegEnd": {
                                                "trias:StopPointRef": "de:08335:6554",
                                                "trias:LocationName": {
                                                    "trias:Text": "Konstanz Bahnhof",
                                                    "trias:Language": "de"
                                                }
                                            },
                                            "trias:TimeWindowStart": "2022-05-03T11:11:00Z",
                                            "trias:TimeWindowEnd": "2022-05-03T11:12:00Z",
                                            "trias:Duration": "PT1M",
                                            "trias:WalkDuration": "PT1M",
                                            "trias:BufferTime": "PT0M",
                                            "trias:Length": 47,
                                            "trias:NavigationPath": {
                                                "trias:NavigationSection": {
                                                    "trias:TrackSection": {
                                                        "trias:TrackStart": {
                                                            "trias:StopPointRef": "de:08335:11882",
                                                            "trias:LocationName": {
                                                                "trias:Text": "",
                                                                "trias:Language": "de"
                                                            }
                                                        },
                                                        "trias:TrackEnd": {
                                                            "trias:StopPointRef": "de:08335:6554",
                                                            "trias:LocationName": {
                                                                "trias:Text": "",
                                                                "trias:Language": "de"
                                                            }
                                                        }
                                                    },
                                                    "trias:AccessPath": {
                                                        "trias:Transition": "level",
                                                        "trias:AccessFeatureType": "footpath"
                                                    }
                                                }
                                            }
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
        }
    }
}
// export const requestResultWithRemainInVehicle ={
//     "?xml": "",
//     "trias:Trias": {
//         "trias:ServiceDelivery": {
//             "siri:ResponseTimestamp": "2022-03-31T11:11:53Z",
//             "siri:ProducerRef": "de:nvbw",
//             "siri:Status": true,
//             "trias:Language": "de",
//             "trias:CalcTime": 470,
//             "trias:DeliveryPayload": {
//                 "trias:TripResponse": {
//                     "trias:TripResponseContext": {
//                         "trias:Situations": ""
//                     },
//                     "trias:TripResult": [
//                         {
//                             "trias:ResultId": "ID-5DA6B94B-25B4-4852-ABC8-CA8629ADBE6A",
//                             "trias:Trip": {
//                                 "trias:TripId": "ID-5DA6B94B-25B4-4852-ABC8-CA8629ADBE6A",
//                                 "trias:Duration": "PT14M",
//                                 "trias:StartTime": "2022-02-04T07:42:00Z",
//                                 "trias:EndTime": "2022-02-04T07:56:00Z",
//                                 "trias:Interchanges": 1,
//                                 "trias:Distance": 5194,
//                                 "trias:TripLeg": [
//                                     {
//                                         "trias:LegId": 1,
//                                         "trias:TimedLeg": {
//                                             "trias:LegBoard": {
//                                                 "trias:StopPointRef": "de:08335:11913:0:1",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Döbele",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceDeparture": {
//                                                     "trias:TimetabledTime": "2022-02-04T07:42:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 1
//                                             },
//                                             "trias:LegIntermediates": [
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11902:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Schnetztor",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:43:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:43:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 2
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11812:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Bodanplatz",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:44:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:44:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 3
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:6554:10:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Bahnhof",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:46:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:46:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 4
//                                                 }
//                                             ],
//                                             "trias:LegAlight": {
//                                                 "trias:StopPointRef": "de:08335:11868:0:2",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Konzilstr./Theater",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceArrival": {
//                                                     "trias:TimetabledTime": "2022-02-04T07:47:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 5
//                                             },
//                                             "trias:Service": {
//                                                 "trias:OperatingDayRef": "2022-02-04",
//                                                 "trias:JourneyRef": "vhb:00001::H:j22:22",
//                                                 "trias:ServiceSection": {
//                                                     "trias:LineRef": "vhb:00001::H",
//                                                     "trias:DirectionRef": "outward",
//                                                     "trias:Mode": {
//                                                         "trias:PtMode": "bus",
//                                                         "trias:BusSubmode": "localBus",
//                                                         "trias:Name": {
//                                                             "trias:Text": "Stadtbus",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:PublishedLineName": {
//                                                         "trias:Text": 1,
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:OperatorRef": "vhb:SWK"
//                                                 },
//                                                 "trias:OriginText": {
//                                                     "trias:Text": "",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:DestinationStopPointRef": "de:08335:11351",
//                                                 "trias:DestinationText": {
//                                                     "trias:Text": "Konstanz Staad/Autofähre",
//                                                     "trias:Language": "de"
//                                                 }
//                                             },
//                                             "trias:LegTrack": {
//                                                 "trias:TrackSection": {
//                                                     "trias:TrackStart": {
//                                                         "trias:StopPointRef": "de:08335:11913:0:1",
//                                                         "trias:LocationName": {
//                                                             "trias:Text": "Konstanz Döbele",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:TrackEnd": {
//                                                         "trias:StopPointRef": "de:08335:11868:0:2",
//                                                         "trias:LocationName": {
//                                                             "trias:Text": "Konstanz Konzilstr./Theater",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:Duration": "PT5M",
//                                                     "trias:Length": 1093
//                                                 }
//                                             }
//                                         }
//                                     },
//                                     {
//                                         "trias:LegId": 2,
//                                         "trias:TimedLeg": {
//                                             "trias:LegBoard": {
//                                                 "trias:StopPointRef": "de:08335:11868:0:2",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Konzilstr./Theater",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceDeparture": {
//                                                     "trias:TimetabledTime": "2022-02-04T07:48:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 1
//                                             },
//                                             "trias:LegIntermediates": [
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11920:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Sternenpl./Spanierstr.",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:50:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:50:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 2
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:71100:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Am Rheinufer",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:52:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:52:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 3
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:61500:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Bodenseeforum / IHK",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:53:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:53:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 4
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:60200:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Neue Rheinbrücke",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:54:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:54:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 5
//                                                 }
//                                             ],
//                                             "trias:LegAlight": {
//                                                 "trias:StopPointRef": "de:08335:11952:0:1",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Neuwerk",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceArrival": {
//                                                     "trias:TimetabledTime": "2022-02-04T07:56:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 6
//                                             },
//                                             "trias:Service": {
//                                                 "trias:OperatingDayRef": "2022-02-04",
//                                                 "trias:JourneyRef": "vhb:00134:a:H:j22:22",
//                                                 "trias:ServiceSection": {
//                                                     "trias:LineRef": "vhb:00134:a:H",
//                                                     "trias:DirectionRef": "outward",
//                                                     "trias:Mode": {
//                                                         "trias:PtMode": "bus",
//                                                         "trias:BusSubmode": "localBus",
//                                                         "trias:Name": {
//                                                             "trias:Text": "Stadtbus",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:PublishedLineName": {
//                                                         "trias:Text": "13/4",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:OperatorRef": "vhb:SWK"
//                                                 },
//                                                 "trias:OriginText": {
//                                                     "trias:Text": "",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:DestinationStopPointRef": "de:08335:11882",
//                                                 "trias:DestinationText": {
//                                                     "trias:Text": "Konstanz Marktstätte/Bahnhof",
//                                                     "trias:Language": "de"
//                                                 }
//                                             },
//                                             "trias:LegTrack": {
//                                                 "trias:TrackSection": {
//                                                     "trias:TrackStart": {
//                                                         "trias:StopPointRef": "de:08335:11868:0:2",
//                                                         "trias:LocationName": {
//                                                             "trias:Text": "Konstanz Konzilstr./Theater",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:TrackEnd": {
//                                                         "trias:StopPointRef": "de:08335:11952:0:1",
//                                                         "trias:LocationName": {
//                                                             "trias:Text": "Konstanz Neuwerk",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:Duration": "PT8M",
//                                                     "trias:Length": 4101
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 ]
//                             }
//                         },
//                         {
//                             "trias:ResultId": "ID-A1C9529A-FBD6-4ADD-946C-68C84586758C",
//                             "trias:Trip": {
//                                 "trias:TripId": "ID-A1C9529A-FBD6-4ADD-946C-68C84586758C",
//                                 "trias:Duration": "PT17M",
//                                 "trias:StartTime": "2022-02-04T07:57:00Z",
//                                 "trias:EndTime": "2022-02-04T08:14:00Z",
//                                 "trias:Interchanges": 1,
//                                 "trias:Distance": 3927,
//                                 "trias:TripLeg": [
//                                     {
//                                         "trias:LegId": 1,
//                                         "trias:TimedLeg": {
//                                             "trias:LegBoard": {
//                                                 "trias:StopPointRef": "de:08335:11913:0:1",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Döbele",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceDeparture": {
//                                                     "trias:TimetabledTime": "2022-02-04T07:57:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 1
//                                             },
//                                             "trias:LegIntermediates": [
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11902:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Schnetztor",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:58:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:58:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 2
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11812:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Bodanplatz",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:59:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T07:59:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 3
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:6554:10:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Bahnhof",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:01:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:01:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 4
//                                                 }
//                                             ],
//                                             "trias:LegAlight": {
//                                                 "trias:StopPointRef": "de:08335:11868:0:2",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Konzilstr./Theater",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceArrival": {
//                                                     "trias:TimetabledTime": "2022-02-04T08:02:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 5
//                                             },
//                                             "trias:Service": {
//                                                 "trias:OperatingDayRef": "2022-02-04",
//                                                 "trias:JourneyRef": "vhb:00001::H:j22:24",
//                                                 "trias:ServiceSection": {
//                                                     "trias:LineRef": "vhb:00001::H",
//                                                     "trias:DirectionRef": "outward",
//                                                     "trias:Mode": {
//                                                         "trias:PtMode": "bus",
//                                                         "trias:BusSubmode": "localBus",
//                                                         "trias:Name": {
//                                                             "trias:Text": "Stadtbus",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:PublishedLineName": {
//                                                         "trias:Text": 1,
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:OperatorRef": "vhb:SWK"
//                                                 },
//                                                 "trias:OriginText": {
//                                                     "trias:Text": "",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:DestinationStopPointRef": "de:08335:11351",
//                                                 "trias:DestinationText": {
//                                                     "trias:Text": "Konstanz Staad/Autofähre",
//                                                     "trias:Language": "de"
//                                                 }
//                                             },
//                                             "trias:LegTrack": {
//                                                 "trias:TrackSection": {
//                                                     "trias:TrackStart": {
//                                                         "trias:StopPointRef": "de:08335:11913:0:1",
//                                                         "trias:LocationName": {
//                                                             "trias:Text": "Konstanz Döbele",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:TrackEnd": {
//                                                         "trias:StopPointRef": "de:08335:11868:0:2",
//                                                         "trias:LocationName": {
//                                                             "trias:Text": "Konstanz Konzilstr./Theater",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:Duration": "PT5M",
//                                                     "trias:Length": 1093
//                                                 }
//                                             }
//                                         }
//                                     },
//                                     {
//                                         "trias:LegId": 2,
//                                         "trias:TimedLeg": {
//                                             "trias:LegBoard": {
//                                                 "trias:StopPointRef": "de:08335:11868:0:2",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Konzilstr./Theater",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceDeparture": {
//                                                     "trias:TimetabledTime": "2022-02-04T08:06:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 1
//                                             },
//                                             "trias:LegIntermediates": [
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11920:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Sternenpl./Spanierstr.",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:08:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:08:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 2
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:64350:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Ebertplatz",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:09:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:09:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 3
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11851:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Klingenbergstraße",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:10:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:10:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 4
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11881:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Markgrafenstraße",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:11:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:11:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 5
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11850:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Gottmannplatz",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:12:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:12:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 6
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11921:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Stadtwerke",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:13:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:13:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 7
//                                                 }
//                                             ],
//                                             "trias:LegAlight": {
//                                                 "trias:StopPointRef": "de:08335:11952:0:2",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Neuwerk",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceArrival": {
//                                                     "trias:TimetabledTime": "2022-02-04T08:14:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 8
//                                             },
//                                             "trias:Service": {
//                                                 "trias:OperatingDayRef": "2022-02-04",
//                                                 "trias:JourneyRef": "vhb:00006::R:j22:5",
//                                                 "trias:ServiceSection": {
//                                                     "trias:LineRef": "vhb:00006::R",
//                                                     "trias:DirectionRef": "return",
//                                                     "trias:Mode": {
//                                                         "trias:PtMode": "bus",
//                                                         "trias:BusSubmode": "localBus",
//                                                         "trias:Name": {
//                                                             "trias:Text": "Stadtbus",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:PublishedLineName": {
//                                                         "trias:Text": 6,
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:OperatorRef": "vhb:SWK"
//                                                 },
//                                                 "trias:OriginText": {
//                                                     "trias:Text": "",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:DestinationStopPointRef": "de:08335:11933",
//                                                 "trias:DestinationText": {
//                                                     "trias:Text": "Wollmatingen Rathaus",
//                                                     "trias:Language": "de"
//                                                 }
//                                             },
//                                             "trias:LegTrack": {
//                                                 "trias:TrackSection": {
//                                                     "trias:TrackStart": {
//                                                         "trias:StopPointRef": "de:08335:11868:0:2",
//                                                         "trias:LocationName": {
//                                                             "trias:Text": "Konstanz Konzilstr./Theater",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:TrackEnd": {
//                                                         "trias:StopPointRef": "de:08335:11952:0:2",
//                                                         "trias:LocationName": {
//                                                             "trias:Text": "Konstanz Neuwerk",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:Duration": "PT8M",
//                                                     "trias:Length": 2834
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 ]
//                             }
//                         },
//                         {
//                             "trias:ResultId": "ID-46001A6F-AB17-4E37-82E7-6F705F89446C",
//                             "trias:Trip": {
//                                 "trias:TripId": "ID-46001A6F-AB17-4E37-82E7-6F705F89446C",
//                                 "trias:Duration": "PT14M",
//                                 "trias:StartTime": "2022-02-04T08:12:00Z",
//                                 "trias:EndTime": "2022-02-04T08:26:00Z",
//                                 "trias:Interchanges": 1,
//                                 "trias:Distance": 5194,
//                                 "trias:TripLeg": [
//                                     {
//                                         "trias:LegId": 1,
//                                         "trias:TimedLeg": {
//                                             "trias:LegBoard": {
//                                                 "trias:StopPointRef": "de:08335:11913:0:1",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Döbele",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceDeparture": {
//                                                     "trias:TimetabledTime": "2022-02-04T08:12:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 1
//                                             },
//                                             "trias:LegIntermediates": [
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11902:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Schnetztor",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:13:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:13:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 2
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11812:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Bodanplatz",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:14:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:14:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 3
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:6554:10:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Bahnhof",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:16:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:16:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 4
//                                                 }
//                                             ],
//                                             "trias:LegAlight": {
//                                                 "trias:StopPointRef": "de:08335:11868:0:2",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Konzilstr./Theater",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceArrival": {
//                                                     "trias:TimetabledTime": "2022-02-04T08:17:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 5
//                                             },
//                                             "trias:Service": {
//                                                 "trias:OperatingDayRef": "2022-02-04",
//                                                 "trias:JourneyRef": "vhb:00001::H:j22:25",
//                                                 "trias:ServiceSection": {
//                                                     "trias:LineRef": "vhb:00001::H",
//                                                     "trias:DirectionRef": "outward",
//                                                     "trias:Mode": {
//                                                         "trias:PtMode": "bus",
//                                                         "trias:BusSubmode": "localBus",
//                                                         "trias:Name": {
//                                                             "trias:Text": "Stadtbus",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:PublishedLineName": {
//                                                         "trias:Text": 1,
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:OperatorRef": "vhb:SWK"
//                                                 },
//                                                 "trias:OriginText": {
//                                                     "trias:Text": "",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:DestinationStopPointRef": "de:08335:11351",
//                                                 "trias:DestinationText": {
//                                                     "trias:Text": "Konstanz Staad/Autofähre",
//                                                     "trias:Language": "de"
//                                                 }
//                                             },
//                                             "trias:LegTrack": {
//                                                 "trias:TrackSection": {
//                                                     "trias:TrackStart": {
//                                                         "trias:StopPointRef": "de:08335:11913:0:1",
//                                                         "trias:LocationName": {
//                                                             "trias:Text": "Konstanz Döbele",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:TrackEnd": {
//                                                         "trias:StopPointRef": "de:08335:11868:0:2",
//                                                         "trias:LocationName": {
//                                                             "trias:Text": "Konstanz Konzilstr./Theater",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:Duration": "PT5M",
//                                                     "trias:Length": 1093
//                                                 }
//                                             }
//                                         }
//                                     },
//                                     {
//                                         "trias:LegId": 2,
//                                         "trias:TimedLeg": {
//                                             "trias:LegBoard": {
//                                                 "trias:StopPointRef": "de:08335:11868:0:2",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Konzilstr./Theater",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceDeparture": {
//                                                     "trias:TimetabledTime": "2022-02-04T08:18:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 1
//                                             },
//                                             "trias:LegIntermediates": [
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11920:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Sternenpl./Spanierstr.",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:20:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:20:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 2
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:71100:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Am Rheinufer",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:22:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:22:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 3
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:61500:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Bodenseeforum / IHK",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:23:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:23:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 4
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:60200:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Neue Rheinbrücke",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:24:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-02-04T08:24:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 5
//                                                 }
//                                             ],
//                                             "trias:LegAlight": {
//                                                 "trias:StopPointRef": "de:08335:11952:0:1",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Neuwerk",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceArrival": {
//                                                     "trias:TimetabledTime": "2022-02-04T08:26:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 6
//                                             },
//                                             "trias:Service": {
//                                                 "trias:OperatingDayRef": "2022-02-04",
//                                                 "trias:JourneyRef": "vhb:00134:a:H:j22:23",
//                                                 "trias:ServiceSection": {
//                                                     "trias:LineRef": "vhb:00134:a:H",
//                                                     "trias:DirectionRef": "outward",
//                                                     "trias:Mode": {
//                                                         "trias:PtMode": "bus",
//                                                         "trias:BusSubmode": "localBus",
//                                                         "trias:Name": {
//                                                             "trias:Text": "Stadtbus",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:PublishedLineName": {
//                                                         "trias:Text": "13/4",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:OperatorRef": "vhb:SWK"
//                                                 },
//                                                 "trias:OriginText": {
//                                                     "trias:Text": "",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:DestinationStopPointRef": "de:08335:11882",
//                                                 "trias:DestinationText": {
//                                                     "trias:Text": "Konstanz Marktstätte/Bahnhof",
//                                                     "trias:Language": "de"
//                                                 }
//                                             },
//                                             "trias:LegTrack": {
//                                                 "trias:TrackSection": {
//                                                     "trias:TrackStart": {
//                                                         "trias:StopPointRef": "de:08335:11868:0:2",
//                                                         "trias:LocationName": {
//                                                             "trias:Text": "Konstanz Konzilstr./Theater",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:TrackEnd": {
//                                                         "trias:StopPointRef": "de:08335:11952:0:1",
//                                                         "trias:LocationName": {
//                                                             "trias:Text": "Konstanz Neuwerk",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:Duration": "PT8M",
//                                                     "trias:Length": 4101
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 ]
//                             }
//                         }
//                     ]
//                 }
//             }
//         }
//     }
// }
// export const requestResultWithRemainInVehicle = {
//     "?xml": "",
//     "trias:Trias": {
//         "trias:ServiceDelivery": {
//             "siri:ResponseTimestamp": "2022-01-25T16:49:25Z",
//             "siri:ProducerRef": "de:nvbw",
//             "siri:Status": true,
//             "trias:Language": "de",
//             "trias:CalcTime": 312,
//             "trias:DeliveryPayload": {
//                 "trias:TripResponse": {
//                     "trias:TripResponseContext": {
//                         "trias:Situations": {
//                             "trias:PtSituation": {
//                                 "siri:CreationTime": "2021-12-06T15:37:00Z",
//                                 "siri:ParticipantRef": "AllgemeineMeldungenNVBW",
//                                 "siri:SituationNumber": "25796_ICSNVBW",
//                                 "siri:Version": 4,
//                                 "siri:Source": {
//                                     "siri:SourceType": "other"
//                                 },
//                                 "siri:ValidityPeriod": {
//                                     "siri:StartTime": "2021-08-31T22:00:00Z",
//                                     "siri:EndTime": "2022-01-31T23:00:00Z"
//                                 },
//                                 "siri:UnknownReason": "unknown",
//                                 "siri:Priority": 3,
//                                 "siri:ScopeType": "line",
//                                 "siri:Summary": "Vollsperrung des Buhlenwegs vom 01. September bis 31. Januar",
//                                 "siri:Description": "Vollsperrung des Buhlenwegs vom 01. September bis 31. Januar",
//                                 "siri:Detail": "Aufgrund der Sperrung des Buhlenwegs werden die Linien 11, 12 und 12/3 umgeleitet. <br /> Dauer der Ma&szlig;nahme 01.09.2021 bis 31.01.2022. <br /> Ausf&uuml;hrlichere Informationen finden Sie auf folgender Seite <br /><a title=\"https://www.stadtwerke-konstanz.de/mobilitaet/infothek-seemobilitaet\" href=\"https://www.stadtwerke-konstanz.de/mobilitaet/infothek-seemobilitaet\" target=\"_blank\">https://www.stadtwerke-konstanz.de/mobilitaet/infothek-seemobilitaet</a>"
//                             }
//                         }
//                     },
//                     "trias:TripResult": [
//                         {
//                             "trias:ResultId": "ID-878CEF8A-1248-4264-919E-A7FD1AA0FE30",
//                             "trias:Trip": {
//                                 "trias:TripId": "ID-878CEF8A-1248-4264-919E-A7FD1AA0FE30",
//                                 "trias:Duration": "PT25M",
//                                 "trias:StartTime": "2022-01-25T16:17:00Z",
//                                 "trias:EndTime": "2022-01-25T16:42:00Z",
//                                 "trias:Interchanges": 1,
//                                 "trias:Distance": 6365,
//                                 "trias:TripLeg": [
//                                     {
//                                         "trias:LegId": 1,
//                                         "trias:TimedLeg": {
//                                             "trias:LegBoard": {
//                                                 "trias:StopPointRef": "de:08335:11811:0:1",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Bodan",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceDeparture": {
//                                                     "trias:TimetabledTime": "2022-01-25T16:17:00Z",
//                                                     "trias:EstimatedTime": "2022-01-25T16:17:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 1
//                                             },
//                                             "trias:LegIntermediates": [
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11855:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Hardtstr./Fürstenberg",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:18:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:18:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:18:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:18:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 2
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11859:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Herosestraße",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:19:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:19:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:19:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:19:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 3
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11847:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Fürstenberg",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:20:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:20:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:20:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:20:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 4
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11922:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Taborweg",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:21:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:21:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:21:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:21:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 5
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11844:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Friedhof",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:22:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:22:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:22:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:22:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 6
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11809:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Bismarcksteig",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:23:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:23:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:23:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:23:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 7
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11935:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Zähringerplatz",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:25:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:25:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:25:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:25:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 8
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11918:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Sternenplatz",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:27:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:27:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:27:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:27:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 9
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11903:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Schottenplatz",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:29:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:29:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:29:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:29:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 10
//                                                 }
//                                             ],
//                                             "trias:LegAlight": {
//                                                 "trias:StopPointRef": "de:08335:11904:0:1",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Bürgerbüro",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceArrival": {
//                                                     "trias:TimetabledTime": "2022-01-25T16:30:00Z",
//                                                     "trias:EstimatedTime": "2022-01-25T16:30:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 11
//                                             },
//                                             "trias:Service": {
//                                                 "trias:OperatingDayRef": "2022-01-25",
//                                                 "trias:JourneyRef": "vhb:00002::H:j22:53",
//                                                 "trias:ServiceSection": {
//                                                     "trias:LineRef": "vhb:00002::H",
//                                                     "trias:DirectionRef": "outward",
//                                                     "trias:Mode": {
//                                                         "trias:PtMode": "bus",
//                                                         "trias:BusSubmode": "localBus",
//                                                         "trias:Name": {
//                                                             "trias:Text": "Stadtbus",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:PublishedLineName": {
//                                                         "trias:Text": 2,
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:OperatorRef": "vhb:SWK"
//                                                 },
//                                                 "trias:OriginText": {
//                                                     "trias:Text": "",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:DestinationStopPointRef": "de:08335:11934",
//                                                 "trias:DestinationText": {
//                                                     "trias:Text": "Wollmatingen Radolfzeller Str.",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:SituationFullRef": {
//                                                     "siri:ParticipantRef": "AllgemeineMeldungenNVBW",
//                                                     "siri:SituationNumber": "25796_ICSNVBW"
//                                                 }
//                                             }
//                                         }
//                                     },
//                                     {
//                                         "trias:LegId": 2,
//                                         "trias:TimedLeg": {
//                                             "trias:LegBoard": {
//                                                 "trias:StopPointRef": "de:08335:11904:0:1",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Bürgerbüro",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceDeparture": {
//                                                     "trias:TimetabledTime": "2022-01-25T16:37:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 1
//                                             },
//                                             "trias:LegIntermediates": [
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11877:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Lutherplatz",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:38:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:38:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 2
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11905:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Schulthaißstraße",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:40:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:40:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 3
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11835:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Döbelestraße",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:41:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:41:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 4
//                                                 }
//                                             ],
//                                             "trias:LegAlight": {
//                                                 "trias:StopPointRef": "de:08335:11913:0:1",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Döbele",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceArrival": {
//                                                     "trias:TimetabledTime": "2022-01-25T16:42:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 5
//                                             },
//                                             "trias:Service": {
//                                                 "trias:OperatingDayRef": "2022-01-25",
//                                                 "trias:JourneyRef": "vhb:00001::H:j22:59",
//                                                 "trias:ServiceSection": {
//                                                     "trias:LineRef": "vhb:00001::H",
//                                                     "trias:DirectionRef": "outward",
//                                                     "trias:Mode": {
//                                                         "trias:PtMode": "bus",
//                                                         "trias:BusSubmode": "localBus",
//                                                         "trias:Name": {
//                                                             "trias:Text": "Stadtbus",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:PublishedLineName": {
//                                                         "trias:Text": 1,
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:OperatorRef": "vhb:SWK"
//                                                 },
//                                                 "trias:OriginText": {
//                                                     "trias:Text": "",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:DestinationStopPointRef": "de:08335:11351",
//                                                 "trias:DestinationText": {
//                                                     "trias:Text": "Konstanz Staad/Autofähre",
//                                                     "trias:Language": "de"
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 ]
//                             }
//                         },
//                         {
//                             "trias:ResultId": "ID-97404D59-4E6D-4D94-B832-2977FC46BFBC",
//                             "trias:Trip": {
//                                 "trias:TripId": "ID-97404D59-4E6D-4D94-B832-2977FC46BFBC",
//                                 "trias:Duration": "PT22M",
//                                 "trias:StartTime": "2022-01-25T16:32:00Z",
//                                 "trias:EndTime": "2022-01-25T16:54:00Z",
//                                 "trias:Interchanges": 1,
//                                 "trias:TripLeg": [
//                                     {
//                                         "trias:LegId": 1,
//                                         "trias:TimedLeg": {
//                                             "trias:LegBoard": {
//                                                 "trias:StopPointRef": "de:08335:11811:0:1",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Bodan",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceDeparture": {
//                                                     "trias:TimetabledTime": "2022-01-25T16:32:00Z",
//                                                     "trias:EstimatedTime": "2022-01-25T16:32:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 1
//                                             },
//                                             "trias:LegIntermediates": [
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11855:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Hardtstr./Fürstenberg",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:33:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:33:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:33:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:33:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 2
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11859:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Herosestraße",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:34:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:34:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:34:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:34:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 3
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11847:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Fürstenberg",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:35:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:35:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:35:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:35:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 4
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11922:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Taborweg",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:36:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:36:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:36:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:36:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 5
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11844:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Friedhof",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:37:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:37:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:37:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:37:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 6
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11809:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Bismarcksteig",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:38:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:38:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:38:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:38:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 7
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11935:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Zähringerplatz",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:40:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:40:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:40:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:40:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 8
//                                                 }
//                                             ],
//                                             "trias:LegAlight": {
//                                                 "trias:StopPointRef": "de:08335:11918:0:2",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Sternenplatz",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceArrival": {
//                                                     "trias:TimetabledTime": "2022-01-25T16:42:00Z",
//                                                     "trias:EstimatedTime": "2022-01-25T16:42:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 9
//                                             },
//                                             "trias:Service": {
//                                                 "trias:OperatingDayRef": "2022-01-25",
//                                                 "trias:JourneyRef": "vhb:00002::H:j22:54",
//                                                 "trias:ServiceSection": {
//                                                     "trias:LineRef": "vhb:00002::H",
//                                                     "trias:DirectionRef": "outward",
//                                                     "trias:Mode": {
//                                                         "trias:PtMode": "bus",
//                                                         "trias:BusSubmode": "localBus",
//                                                         "trias:Name": {
//                                                             "trias:Text": "Stadtbus",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:PublishedLineName": {
//                                                         "trias:Text": 2,
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:OperatorRef": "vhb:SWK"
//                                                 },
//                                                 "trias:OriginText": {
//                                                     "trias:Text": "",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:DestinationStopPointRef": "de:08335:11934",
//                                                 "trias:DestinationText": {
//                                                     "trias:Text": "Wollmatingen Radolfzeller Str.",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:SituationFullRef": {
//                                                     "siri:ParticipantRef": "AllgemeineMeldungenNVBW",
//                                                     "siri:SituationNumber": "25796_ICSNVBW"
//                                                 }
//                                             }
//                                         }
//                                     },
//                                     {
//                                         "trias:LegId": 2,
//                                         "trias:TimedLeg": {
//                                             "trias:LegBoard": {
//                                                 "trias:StopPointRef": "de:08335:11918:0:2",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Sternenplatz",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceDeparture": {
//                                                     "trias:TimetabledTime": "2022-01-25T16:44:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 1
//                                             },
//                                             "trias:LegIntermediates": {
//                                                 "trias:StopPointRef": "de:08335:11868:0:1",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Konzilstr./Theater",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceArrival": {
//                                                     "trias:TimetabledTime": "2022-01-25T16:46:00Z"
//                                                 },
//                                                 "trias:ServiceDeparture": {
//                                                     "trias:TimetabledTime": "2022-01-25T16:46:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 2
//                                             },
//                                             "trias:LegAlight": {
//                                                 "trias:StopPointRef": "de:08335:11882:0:1",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Marktstätte/Bahnhof",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceArrival": {
//                                                     "trias:TimetabledTime": "2022-01-25T16:48:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 3
//                                             },
//                                             "trias:Service": {
//                                                 "trias:OperatingDayRef": "2022-01-25",
//                                                 "trias:JourneyRef": "vhb:00005::H:j22:80",
//                                                 "trias:ServiceSection": {
//                                                     "trias:LineRef": "vhb:00005::H",
//                                                     "trias:DirectionRef": "outward",
//                                                     "trias:Mode": {
//                                                         "trias:PtMode": "bus",
//                                                         "trias:BusSubmode": "localBus",
//                                                         "trias:Name": {
//                                                             "trias:Text": "Stadtbus",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:PublishedLineName": {
//                                                         "trias:Text": 5,
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:OperatorRef": "vhb:SWK"
//                                                 },
//                                                 "trias:OriginText": {
//                                                     "trias:Text": "",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:DestinationStopPointRef": "de:08335:11882",
//                                                 "trias:DestinationText": {
//                                                     "trias:Text": "Konstanz Marktstätte/Bahnhof",
//                                                     "trias:Language": "de"
//                                                 }
//                                             }
//                                         }
//                                     },
//                                     {
//                                         "trias:LegId": 3,
//                                         "trias:InterchangeLeg": {
//                                             "trias:InterchangeMode": "remainInVehicle",
//                                             "trias:LegStart": {
//                                                 "trias:StopPointRef": "de:08335:11882:0:1",
//                                                 "trias:LocationName": {
//                                                     "trias:Text": "Konstanz Marktstätte/Bahnhof",
//                                                     "trias:Language": "de"
//                                                 }
//                                             },
//                                             "trias:LegEnd": {
//                                                 "trias:StopPointRef": "de:08335:11882:0:1",
//                                                 "trias:LocationName": {
//                                                     "trias:Text": "Konstanz Marktstätte/Bahnhof",
//                                                     "trias:Language": "de"
//                                                 }
//                                             },
//                                             "trias:TimeWindowStart": "2022-01-25T16:48:00Z",
//                                             "trias:TimeWindowEnd": "2022-01-25T16:50:00Z",
//                                             "trias:Duration": "PT2M"
//                                         }
//                                     },
//                                     {
//                                         "trias:LegId": 4,
//                                         "trias:TimedLeg": {
//                                             "trias:LegBoard": {
//                                                 "trias:StopPointRef": "de:08335:11882:0:1",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Marktstätte/Bahnhof",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceDeparture": {
//                                                     "trias:TimetabledTime": "2022-01-25T16:50:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 1
//                                             },
//                                             "trias:LegIntermediates": {
//                                                 "trias:StopPointRef": "de:08335:11812:0:2",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Bodanplatz",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceArrival": {
//                                                     "trias:TimetabledTime": "2022-01-25T16:52:00Z"
//                                                 },
//                                                 "trias:ServiceDeparture": {
//                                                     "trias:TimetabledTime": "2022-01-25T16:52:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 2
//                                             },
//                                             "trias:LegAlight": {
//                                                 "trias:StopPointRef": "de:08335:11913:0:2",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Döbele",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceArrival": {
//                                                     "trias:TimetabledTime": "2022-01-25T16:54:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 3
//                                             },
//                                             "trias:Service": {
//                                                 "trias:OperatingDayRef": "2022-01-25",
//                                                 "trias:JourneyRef": "vhb:00005::R:j22:36",
//                                                 "trias:ServiceSection": {
//                                                     "trias:LineRef": "vhb:00005::R",
//                                                     "trias:DirectionRef": "return",
//                                                     "trias:Mode": {
//                                                         "trias:PtMode": "bus",
//                                                         "trias:BusSubmode": "localBus",
//                                                         "trias:Name": {
//                                                             "trias:Text": "Stadtbus",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:PublishedLineName": {
//                                                         "trias:Text": 5,
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:OperatorRef": "vhb:SWK"
//                                                 },
//                                                 "trias:OriginText": {
//                                                     "trias:Text": "",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:DestinationStopPointRef": "de:08335:11815",
//                                                 "trias:DestinationText": {
//                                                     "trias:Text": "Konstanz Bodensee-Therme",
//                                                     "trias:Language": "de"
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 ]
//                             }
//                         },
//                         {
//                             "trias:ResultId": "ID-CB9D3718-0824-4DB3-B48A-D4A98E368264",
//                             "trias:Trip": {
//                                 "trias:TripId": "ID-CB9D3718-0824-4DB3-B48A-D4A98E368264",
//                                 "trias:Duration": "PT20M",
//                                 "trias:StartTime": "2022-01-25T16:47:00Z",
//                                 "trias:EndTime": "2022-01-25T17:07:00Z",
//                                 "trias:Interchanges": 1,
//                                 "trias:Distance": 6718,
//                                 "trias:TripLeg": [
//                                     {
//                                         "trias:LegId": 1,
//                                         "trias:TimedLeg": {
//                                             "trias:LegBoard": {
//                                                 "trias:StopPointRef": "de:08335:11811:0:1",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Bodan",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceDeparture": {
//                                                     "trias:TimetabledTime": "2022-01-25T16:47:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 1
//                                             },
//                                             "trias:LegIntermediates": [
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11855:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Hardtstr./Fürstenberg",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:48:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:48:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 2
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11859:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Herosestraße",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:49:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:49:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 3
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11847:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Fürstenberg",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:50:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:50:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 4
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11922:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Taborweg",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:51:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:51:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 5
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11844:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Friedhof",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:52:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:52:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 6
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11809:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Bismarcksteig",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:53:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:53:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 7
//                                                 }
//                                             ],
//                                             "trias:LegAlight": {
//                                                 "trias:StopPointRef": "de:08335:11935:0:1",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Zähringerplatz",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceArrival": {
//                                                     "trias:TimetabledTime": "2022-01-25T16:55:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 8
//                                             },
//                                             "trias:Service": {
//                                                 "trias:OperatingDayRef": "2022-01-25",
//                                                 "trias:JourneyRef": "vhb:00002::H:j22:55",
//                                                 "trias:ServiceSection": {
//                                                     "trias:LineRef": "vhb:00002::H",
//                                                     "trias:DirectionRef": "outward",
//                                                     "trias:Mode": {
//                                                         "trias:PtMode": "bus",
//                                                         "trias:BusSubmode": "localBus",
//                                                         "trias:Name": {
//                                                             "trias:Text": "Stadtbus",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:PublishedLineName": {
//                                                         "trias:Text": 2,
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:OperatorRef": "vhb:SWK"
//                                                 },
//                                                 "trias:OriginText": {
//                                                     "trias:Text": "",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:DestinationStopPointRef": "de:08335:11934",
//                                                 "trias:DestinationText": {
//                                                     "trias:Text": "Wollmatingen Radolfzeller Str.",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:SituationFullRef": {
//                                                     "siri:ParticipantRef": "AllgemeineMeldungenNVBW",
//                                                     "siri:SituationNumber": "25796_ICSNVBW"
//                                                 }
//                                             }
//                                         }
//                                     },
//                                     {
//                                         "trias:LegId": 2,
//                                         "trias:TimedLeg": {
//                                             "trias:LegBoard": {
//                                                 "trias:StopPointRef": "de:08335:11935:0:1",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Zähringerplatz",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceDeparture": {
//                                                     "trias:TimetabledTime": "2022-01-25T16:56:00Z",
//                                                     "trias:EstimatedTime": "2022-01-25T16:56:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 1
//                                             },
//                                             "trias:LegIntermediates": [
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11918:0:2",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Sternenplatz",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:58:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:58:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T16:58:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T16:58:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 2
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11903:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Schottenplatz",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T17:00:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T17:00:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T17:00:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T17:00:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 3
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11839:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Ellenrieder Gymnasium",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T17:01:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T17:01:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T17:01:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T17:01:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 4
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11849:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Gartenstraße",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T17:02:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T17:02:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T17:02:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T17:02:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 5
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11821:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Brüelstraße",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T17:04:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T17:04:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T17:04:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T17:04:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 6
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11905:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Schulthaißstraße",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T17:05:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T17:05:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T17:05:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T17:05:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 7
//                                                 },
//                                                 {
//                                                     "trias:StopPointRef": "de:08335:11835:0:1",
//                                                     "trias:StopPointName": {
//                                                         "trias:Text": "Konstanz Döbelestraße",
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:ServiceArrival": {
//                                                         "trias:TimetabledTime": "2022-01-25T17:06:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T17:06:00Z"
//                                                     },
//                                                     "trias:ServiceDeparture": {
//                                                         "trias:TimetabledTime": "2022-01-25T17:06:00Z",
//                                                         "trias:EstimatedTime": "2022-01-25T17:06:00Z"
//                                                     },
//                                                     "trias:StopSeqNumber": 8
//                                                 }
//                                             ],
//                                             "trias:LegAlight": {
//                                                 "trias:StopPointRef": "de:08335:11913:0:1",
//                                                 "trias:StopPointName": {
//                                                     "trias:Text": "Konstanz Döbele",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:ServiceArrival": {
//                                                     "trias:TimetabledTime": "2022-01-25T17:07:00Z",
//                                                     "trias:EstimatedTime": "2022-01-25T17:07:00Z"
//                                                 },
//                                                 "trias:StopSeqNumber": 9
//                                             },
//                                             "trias:Service": {
//                                                 "trias:OperatingDayRef": "2022-01-25",
//                                                 "trias:JourneyRef": "vhb:00009::H:j22:103",
//                                                 "trias:ServiceSection": {
//                                                     "trias:LineRef": "vhb:00009::H",
//                                                     "trias:DirectionRef": "outward",
//                                                     "trias:Mode": {
//                                                         "trias:PtMode": "bus",
//                                                         "trias:BusSubmode": "localBus",
//                                                         "trias:Name": {
//                                                             "trias:Text": "Stadtbus",
//                                                             "trias:Language": "de"
//                                                         }
//                                                     },
//                                                     "trias:PublishedLineName": {
//                                                         "trias:Text": 9,
//                                                         "trias:Language": "de"
//                                                     },
//                                                     "trias:OperatorRef": "vhb:SWK"
//                                                 },
//                                                 "trias:OriginText": {
//                                                     "trias:Text": "",
//                                                     "trias:Language": "de"
//                                                 },
//                                                 "trias:DestinationStopPointRef": "de:08335:11925",
//                                                 "trias:DestinationText": {
//                                                     "trias:Text": "Konstanz Universität",
//                                                     "trias:Language": "de"
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 ]
//                             }
//                         }
//                     ]
//                 }
//             }
//         }
//     }
// };


export default{

}
</script>
