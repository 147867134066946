var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vertical-component"},[_c('div',{staticClass:"trip-overview"}),_c('div',{staticClass:"vertical",style:({
      'min-height': _vm.verticalMinHeight,
      display: !_vm.detailOpen ? 'block' : 'none',
    })},[_c('div',{staticClass:"background-grid",style:({
        minWidth: _vm.gridMinWidth
      })},[(_vm.nowElement)?_c('div',{staticClass:"nowEl",style:({ top: _vm.nowElement, minWidth: _vm.gridMinWidth })},[_c('div',{staticClass:"slot-label"},[_vm._v("Jetzt")])]):_vm._e(),_vm._l((_vm.gridSlots),function(gridSlot,index){return _c('div',{key:index,staticClass:"line",class:{
          now: gridSlot.type == 'now',
          'fat-line': gridSlot.type == 'fat-line',
        },style:({
          height: gridSlot['height'],
        })},[_c('div',{staticClass:"slot-label"},[_vm._v(" "+_vm._s(gridSlot.label ? gridSlot.label : " ")+" ")])])})],2),_c('div',{staticClass:"timeslots"},[_c('div',{staticClass:"slot-wrapper"},[_c('div',{staticClass:"slot first"},[_c('div',{staticClass:"slot-label",attrs:{"tabindex":0},on:{"click":function($event){return _vm.$emit('resultsBefore')},"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13)return null;return _vm.$emit('resultsBefore')}}},[_vm._v("Früher")])]),_vm._l((_vm.computedTrips.trips),function(trip,index){return _c('div',{key:index,staticClass:"slot",class:{
            'vertical-dateElement': trip.isDateElement,
          }},[_c('div',{staticClass:"slot-label"},[(!trip.isDateElement)?_c('div',[_c('b',[_vm._v(_vm._s(trip.dauerStdMin))]),_c('span',[_vm._v(_vm._s(trip.zeitenFrom)+" - "+_vm._s(trip.zeitenTo))])]):_c('div',[_c('span',[_vm._v(_vm._s(trip.formattedDate))])])]),_c('div',{staticClass:"times-wrap",style:({
              marginTop: trip.offsetTopPx,
            }),attrs:{"tabindex":0,"aria-label":'Verbindung Nr. ' + (index + 1) + ' anzeigen'},on:{"click":function($event){_vm.openDetailComponent(trip['key']);
              _vm.detailOpen = true;},"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13)return null;_vm.openDetailComponent(trip['key']);
              _vm.detailOpen = true;}}},_vm._l((trip.legs),function(leg,index){return _c('div',{key:index,class:{
                'waiting-time': leg.mode && leg.mode == 'waitingTime',
              },style:({
                top: leg.offsetTopPx,
                height: leg.heightPx,
                backgroundColor: leg.iconColor,
              })},[(leg.icon == 'walk' || leg.icon == 'cycle')?_c('img',{staticClass:"bus-icon-normal",attrs:{"src":leg.iconSrc,"alt":""}}):_vm._e(),(leg.icon == 'walk' || leg.icon == 'cycle')?_c('img',{staticClass:"bus-icon-contrast",attrs:{"src":leg.iconContrastSrc,"alt":""}}):_vm._e(),_vm._v(" "+_vm._s(leg.icon != "walk" && leg.icon != "cycle" ? _vm.getInterchangeTranslationFnc(leg.icon) : "")+" ")])}),0)])})],2),_c('div',{staticClass:"slot-wrapper alternatives"},_vm._l((_vm.computedTrips.alternatives),function(trip,index){return _c('div',{key:index,staticClass:"slot"},[_c('div',{staticClass:"slot-label"},[_c('div',[_c('b',[_vm._v(_vm._s(trip.durationStdMin))]),_c('span',[_vm._v(_vm._s(trip.zeitenFrom))])])]),_c('div',{staticClass:"times-wrap",style:({
              marginTop: trip.offsetTopPx,
            })},_vm._l((trip.legs),function(leg,index){return _c('div',{key:index,class:{
                'waiting-time': leg.mode && leg.mode == 'waitingTime',
                'transition-bottom':leg.transitionBottom
              },style:({
                top: leg.offsetTopPx,
                height: leg.heightPx,
                backgroundColor: leg.iconColor,
              }),attrs:{"tabindex":0},on:{"click":function($event){return _vm.openLink(trip.link)},"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13)return null;return _vm.openLink(trip.link)}}},[(leg.icon == 'walk' || leg.icon == 'cycle')?_c('img',{attrs:{"src":leg.iconSrc,"alt":""}}):_vm._e(),_vm._v(" "+_vm._s(leg.icon != "walk" && leg.icon != "cycle" ? leg.icon : "")+" ")])}),0)])}),0),_c('div',{staticClass:"after-absolute-bottom"},[_c('div',{staticClass:"slot-label",attrs:{"tabindex":0},on:{"click":function($event){return _vm.$emit('resultsAfter')},"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13)return null;return _vm.$emit('resultsAfter')}}},[_vm._v("Später")])])])]),(_vm.detailOpen)?_c('div',{staticClass:"vertical-detail"},[_c('div',{staticClass:"back-button",attrs:{"tabindex":0},on:{"click":function($event){_vm.detailOpen = false},"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13)return null;_vm.detailOpen = false}}},[_c('div',{staticClass:"chevron-left"}),_vm._v(" zur Übersicht ")]),_vm._l((_vm.computedTrips.trips),function(trip,index){return _c('div',{key:index},[_c('list-expansion-component',{directives:[{name:"show-slide",rawName:"v-show-slide",value:(_vm.expandedObjects[trip['key']]),expression:"expandedObjects[trip['key']]"}],attrs:{"trip":trip,"expandedObjects":_vm.expandedObjects}})],1)})],2):_vm._e(),_c('div',{staticClass:"center"},[(_vm.laterLoading)?_c('LoadSpinnerComponent'):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }