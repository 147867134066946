<script lang="ts">
import { XMLParser } from "fast-xml-parser";
import {  altTags, BusIcons } from "./../components/SvgComponent.vue";
import { BusIconColor } from "./../components/SvgComponent.vue";

// const GEO_LOCATION_REQUEST = require('./../requests/requests.vue');
import {
  GEO_LOCATION_REQUEST,
  TEXT_LOCATION_REQUEST,
  BUS_LINES_REQUEST,
} from "../requests/requests.js";

const interchangeModes = {
  walk: "Zu Fuß",
  cycle: "Fahrrad",
  taxi: "Taxi",
  "self-drive-car": "Auto",
  "others-drive-car": "Chauffeur",
  motorcycle: "Motorrad",
  truck: "LKW",
  demandResponsive: "Nachfrageabhängig",
  replacementService: "Ersatzverkehr",
  parkAndRide: "Park and Ride",
  bikeAndRide: "Bike and Ride",
  carHire: "Autovermietung",
  bikeHire: "Fahrradverlei",
  protectedConnection: "Geschützte Verbindung",
  guaranteedConnection: "Garantierte Verbindung",
  remainInVehicle: "Im Fahrzeug bleiben",
  changeWithinVehicle: "Innerhalb des Fahrzeugs wechseln",
  checkIn: "Check-In",
  checkOut: "Check-Out",
};
export function getInterchangeTranslation(key) {
  if (typeof interchangeModes[key] !== "undefined") {
    return interchangeModes[key];
  } else {
    if (["walk", "cycle"].includes(key)) {
      return "";
    } else {
      return key;
    }
  }
}

export class RequestService {
  async getLocationsForInput(search = "Konstanz", position = null) {
    console.log(`search`, search);
    console.log(`position`, position);

    let body = "";


    if (position) {
      body = GEO_LOCATION_REQUEST.replace(
        "$LONGITUDE",
        position.coords.longitude
      ).replace("$LATITUDE", position.coords.latitude);
    } else if (search) {
      body = TEXT_LOCATION_REQUEST.replace("$LOCATIONNAME", search);
    }

    body = body.replace("$TIMESTAMP", new Date().toISOString());

    let promises = [
      this.postData(body.replace('$LOCALITYREF', `<LocalityRef>08335043;22; #Konstanz</LocalityRef>`)), 
      this.postData(body.replace('$LOCALITYREF', `<LocalityRef>23021671;22; #Kreuzlingen</LocalityRef>`))
    ];

    let result = await Promise.all(promises);

    let labelAlreadyExists = [];
    result = result.map((r) => {
      // if (r.status === "fulfilled") {
        // console.log(`r.value`, r.value);
        // this.validateTRIASResult("getLocationsForInput",r.value);
        let res = [];
        try {
          res = this.parseTRIASBodyToArrayLocation(r);
        } catch (error) {
          return [];
        }
        return res;
      // } else {
      //   return [];
      // }
    })
    .reduce((prev, curr) => prev.concat(curr) , [])
    .filter(el => {
      if(labelAlreadyExists.includes(el.label)){
        return false;
      } else {
        labelAlreadyExists.push(el.label);
        return true;
      }
    })
    .sort((a, b) => {
          return b['trias:Probability'] - a['trias:Probability'];
        });
        console.log(`labelAlreadyExists`, labelAlreadyExists);
    return result;
  }

  async getTripRequest(
    from_obj,
    to_object,
    transferOptions,
    departureTime,
    arrivalTime,
    // extensionMode = null
  ) {
    console.log(`from_obj`, from_obj);
    console.log(`to_object`, to_object);
    // console.log(`BUS_LINES_REQUEST`, BUS_LINES_REQUEST);

    const fromStopPointRef =
      from_obj["trias:Location"]["trias:StopPoint"]["trias:StopPointRef"];
    const toStopPointRef =
      to_object["trias:Location"]["trias:StopPoint"]["trias:StopPointRef"];

    let body = BUS_LINES_REQUEST.replace(
      "$OriginStopPointRef",
      fromStopPointRef
    )
      .replace("$OriginStopPointName", from_obj["label"])
      .replace("$DestinationStopPointRef", toStopPointRef)
      .replace("$DestinationStopPointName", to_object["label"]);

    console.log(`transferOptions`, transferOptions);
    let transferOptionsString = "";
    for (let key of Object.keys(transferOptions)) {
      if (transferOptions[key]) {
        transferOptionsString += `<${key}>${transferOptions[key]}</${key}>`;
      }
    }
    body = body.replace("$TransferOptions", transferOptionsString);

    if (departureTime) {
      body = body
        .replace("$DepartureTime", `<DepArrTime>${departureTime}</DepArrTime>`)
        .replace("$ArrivalTime", "");
    } else {
      body = body
        .replace("$ArrivalTime", `<DepArrTime>${arrivalTime}</DepArrTime>`)
        .replace("$DepartureTime", "");
    }

    // console.log(`extensionMode`, extensionMode);

    body = body.replace(
      "$NumberOfResults",
      // `<NumberOfResultsBefore>10</NumberOfResultsBefore>`
      `
      <NumberOfResultsBefore>5</NumberOfResultsBefore>
      <NumberOfResultsAfter>5</NumberOfResultsAfter>
      `
    );


    body = body.replace("$TIMESTAMP", new Date().toISOString());

    console.log(`getTripRequest body`, body);

    let result = await this.postData(body);

    //check for errors

    console.log(`getTripRequest result`, result);
    this.validateTRIASResult("getTripRequest", result);

    return result;
  }

  validateTRIASResult(func_name, result) {
    if (!this.getKey(result, ["trias:Trias"])) {
      console.error(func_name + " trias:Trias header fehlt", result);
      throw new Error(JSON.stringify(result));
    }

    let error = this.findVal(result, "trias:ErrorMessage");
    if (error) {
      console.error("getTripRequest trias:Trias header fehlt", result);
      throw new Error(JSON.stringify(error));
    }
  }

  // www4.efa-bw.de
  async postData(body = "", url = "/trias.php") {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "same-origin", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "omit", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/xml",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body, // body data type must match "Content-Type" header
    });
    // return toJSON(new window.DOMParser().parseFromString(await response.text(), "text/xml")); // parses JSON response into native JavaScript objects
    const parser = new XMLParser();
    // console.log(`await response.text()`, await response.text());
    const responseText = await response.text();
    console.log(`responseText`, responseText);
    if (response.status != 200) {
      let responseParsed = parser.parse(responseText);
      console.error("postData error: ", responseText, responseParsed);
      throw new Error(JSON.stringify(responseParsed));
    }

    return parser.parse(responseText); // parses JSON response into native JavaScript objects
  }

  async getAlternativeRoutesFromGoogle(origin, destination, mode) {
    // bicycling
    let url = `https://maps.googleapis.com/maps/api/directions/json?key=$API_KEY&mode=${mode}&origin=${origin}&destination=${destination}&language=de`;
    console.log(`url`, url);
    // const response = await fetch("/wp-json/timetable-information/direction", {
    const response = await fetch("/directions.php", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "same-origin", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: "omit", // include, *same-origin, omit
      // referrerPolicy: "no-referrer",
      body: JSON.stringify({ request_url: url }),
    });

    console.log(`getAlternativeRoutesFromGoogle response`, response);
    // console.log(`getAlternativeRoutesFromGoogle response body`, await response.text());

    let res = await response.json();
    console.log(`getAlternativeRoutesFromGoogle res`, res);

    res["mode"] = mode;
    res["origin"] = origin;
    res["destination"] = destination;
    return res;
  }

  findVal(object, key, self = this) {
    let value;
    Object.keys(object).some(function (k) {
      if (k === key) {
        value = object[k];
        return true;
      }
      if (object[k] && typeof object[k] === "object") {
        value = self.findVal(object[k], key, self);
        return typeof value !== undefined;
      }
    });
    return value;
  }

  parseTRIASBodyToArrayLocation(searchresult) {
    console.log(`searchresult`, searchresult);
    let res =
      searchresult["trias:Trias"]["trias:ServiceDelivery"][
        "trias:DeliveryPayload"
      ]["trias:LocationInformationResponse"]["trias:LocationResult"];
    if (Array.isArray(res)) {
      res = res
        .map((el) => {
          el["label"] = this.getNameFromLocationElement(el);
          return el;
        })
    } else {
      res["label"] = this.getNameFromLocationElement(res);
      res = [res];
    }
    return res;
  }

  parseTRIASBodyToArrayTrip(searchresult, selected_from_item) {
    console.log(`searchresult`, searchresult);
    let res = this.getKey(
      searchresult,
      [
        "trias:Trias",
        "trias:ServiceDelivery",
        "trias:DeliveryPayload",
        "trias:TripResponse",
        "trias:TripResult",
      ],
      true
    );
    // console.log(`parseTRIASBodyToArrayTrip res`, res);

    if (!res) return [];

    let situations = this.getKey(
      searchresult,
      [
        "trias:Trias",
        "trias:ServiceDelivery",
        "trias:DeliveryPayload",
        "trias:TripResponse",
        "trias:TripResponseContext",
        "trias:Situations",
        "trias:PtSituation",
      ],
      true
    );

    // if(!Array.isArray(res)){
    //   res = [res]
    // }

    // console.log(`parseTRIASBodyToArrayTrip res`, res);

    //transformation here
    res = res.map((trip) => {
      console.log(
        `parseTRIASBodyToArrayTrip selected_from_item`,
        selected_from_item
      );

      let lat = this.getKey(
        selected_from_item,
        ["trias:Location", "trias:GeoPosition", "trias:Latitude"],
        false
      );
      let lng = this.getKey(
        selected_from_item,
        ["trias:Location", "trias:GeoPosition", "trias:Longitude"],
        false
      );
      trip[
        "navigateToStartLink"
        // ] = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}&language=de`;
      ] = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}&language=de&travelmode=walking`;

      trip["key"] = trip["trias:Trip"]["trias:TripId"];
      // trip["zeiten"] =
      //   "<b>" +
      //   this.convertTime(trip["trias:Trip"]["trias:StartTime"]) +
      //   "</b>" +
      //   " - " +
      //   this.convertTime(trip["trias:Trip"]["trias:EndTime"]);
      trip["zeitenFrom"] = this.convertTime(
        trip["trias:Trip"]["trias:StartTime"]
      );
      trip["zeitenTo"] = this.convertTime(trip["trias:Trip"]["trias:EndTime"]);

      trip["dauer"] = this.parseDuration(trip["trias:Trip"]["trias:Duration"]);
      trip["dauerStdMin"] = this.durationToStdMin(trip["dauer"]);
      trip["startTime"] = trip["trias:Trip"]["trias:StartTime"];
      trip["endTime"] = trip["trias:Trip"]["trias:EndTime"];

      
      trip["legs"] = this.getTripLegs(trip["trias:Trip"], situations);

      trip["hasSituations"] = trip["legs"].filter(
        (leg) => leg["situationsForLeg"] && leg["situationsForLeg"].length
      ).length;

      // trip["verlauf"] = trip["legs"].filter(el => el.icon);
      //wartezeiten zwischen TimedLegs einfügen
      if (trip.legs.length > 1) {
        for (let i = trip.legs.length - 2; i >= 0; i--) {
          let earlier = new Date(trip.legs[i]["endTime"]).getTime();
          console.log(`earlier`, earlier);
          let later = new Date(trip.legs[i + 1]["startTime"]).getTime();

          let laterMinusEarlier = later - earlier;
          console.log(`laterMinusEarlier`, laterMinusEarlier);
          if (laterMinusEarlier > 0) {
            let insertObj = {
              mode: "waitingTime",
              startTime: earlier,
              endTime: later,
              duration: laterMinusEarlier,
              durationStdMin: this.millisecondsToStdMin(laterMinusEarlier),
              iconColor: "#ACACAC",
              borderColor: "#ACACAC",
            };
            console.log(`insertObj`, insertObj);
            trip.legs.splice(i + 1, 0, insertObj);
          }
        }
      }
      if (trip.legs.length > 2) {
        //remainInVehicle entfernen
        for (let i = trip.legs.length - 2; i > 0; i--) {
          console.log(`trip.legs[i]['mode']`, trip.legs[i]["mode"]);
          if (trip.legs[i]["icon"] == "remainInVehicle") {
            trip.legs[i - 1]["endTime"] = trip.legs[i + 1]["endTime"];
            trip.legs[i - 1]["endTimeFormatted"] =
              trip.legs[i + 1]["endTimeFormatted"];
            trip.legs[i - 1]["endPoint"] = trip.legs[i + 1]["endPoint"];
            //TODO: Raphael nochmal fragen ob das so gut ist -> remain in Vehicle war vorallem da weil sich die Richtung ändert.
            trip.legs[i - 1]["vehicleEndPoint"] =
              trip.legs[i - 1]["vehicleEndPoint"] +
              ", " +
              trip.legs[i + 1]["vehicleEndPoint"];

            trip.legs[i - 1]["intermediates"] = (
              trip.legs[i - 1]["intermediates"]
                ? trip.legs[i - 1]["intermediates"]
                : []
            )
              .concat([trip.legs[i + 1]["startPoint"]])
              .concat(
                trip.legs[i + 1]["intermediates"]
                  ? trip.legs[i + 1]["intermediates"]
                  : []
              );

            let dur1 = trip.legs[i - 1]["duration"].split(":");
            let dur2 = trip.legs[i + 1]["duration"].split(":");
            let newHH = Number.parseInt(dur1[0]) + Number.parseInt(dur2[0]);
            let newMM = Number.parseInt(dur1[1]) + Number.parseInt(dur2[1]);
            newHH += Math.floor(newMM / 60);
            newMM = newMM % 60;
            trip.legs[i - 1]["duration"] = newHH + ":" + newMM;
            trip.legs.splice(i, 2);
          }
        }
      }

      //border colors anpassen
      // for(let i = 0; i < trip["legs"].length-1; i++){
      //   let borderColor = trip["legs"][i+1].iconColor;
      //   trip["legs"][i]['borderColor'] = borderColor;
      // }
      trip["verlauf"] = trip["legs"].filter((el) => el.icon);

      this.setTimedLegsBooleanBeforeAfter(trip.legs);

      for (let i = 0; i < trip.legs.length; i++) {
        if (i == 0) {
          trip.legs[i]["beforeColor"] = "transparent";
        } else {
          trip.legs[i]["beforeColor"] = trip.legs[i - 1]["borderColor"];
        }

        if (i + 1 < trip.legs.length) {
          trip.legs[i]["afterColor"] = trip.legs[i + 1]["borderColor"];
        } else {
          trip.legs[i]["afterColor"] = "transparent";
        }
      }

      const filteredTripLegs = trip['legs'].filter((tl) => tl.mode == 'trias:TimedLeg');
      trip['umsteigen'] = filteredTripLegs.length > 0? filteredTripLegs.length - 1: 0;

      console.log(`parse tripe trip`, trip);
      return trip;
    });

    // filter results which have no timedLegs (fahrplan) -> show as alternatives from google
    res = res.filter(
      (el) => el.legs.filter((leg) => leg.mode == "trias:TimedLeg").length
    );

    console.log(`res`, res);
    return res;
  }

  setTimedLegsBooleanBeforeAfter(legs) {
    for (let i = 0; i < legs.length; i++) {
      legs[i]["timedLegBefore"] =
        typeof legs[i - 1] !== "undefined" &&
        legs[i - 1]["mode"] == "trias:TimedLeg";
      legs[i]["timedLegAfter"] =
        typeof legs[i + 1] !== "undefined" &&
        legs[i + 1]["mode"] == "trias:TimedLeg";
    }
  }

  //transformation functions start
  getTripLegs(trip, situations = []) {
    let result = [];
    let tripLegs = this.getKey(trip, ["trias:TripLeg"], true);
    if (tripLegs) {
      console.log(`getTripLegs tripLegs`, tripLegs);
      result = tripLegs.map((tl) => {
        let icon =
          this.getKey(tl, [
            "trias:TimedLeg",
            "trias:Service",
            "trias:ServiceSection",
            "trias:PublishedLineName",
            "trias:Text",
          ]) ||
          // InterchangeLeg (Umstieg zwischen Verkehrsmittel)
          this.getKey(tl, ["trias:InterchangeLeg", "trias:InterchangeMode"]) ||
          this.getKey(tl, ["trias:InterchangeLeg", "trias:ContinuousMode"]) ||
          //continousLeg (Ausprägung  des  Teilwegs  als  Fortbewegung  mit einem kontinuierlich verfügbaren Verkehrsmittel. )
          //Fahrplanungebunden
          this.getKey(tl, [
            "trias:ContinuousLeg",
            "trias:Service",
            "trias:ContinuousMode",
          ]) ||
          this.getKey(tl, [
            "trias:ContinuousLeg",
            "trias:Service",
            "trias:IndividualMode",
          ]);

        let iconSrc = false;
        let iconContrastSrc = false;
        if (icon) {
          iconSrc = this.getBusIcon(icon);
          iconContrastSrc = this.getBusIcon(icon, true);
          if (typeof iconSrc !== "undefined") {
            iconSrc = this.getBase64(iconSrc);
          } else {
            iconSrc = false;
          }
          if (typeof iconContrastSrc !== "undefined") {
            iconContrastSrc = this.getBase64(iconContrastSrc);
          } else {
            iconContrastSrc = false;
          }
        }

        let iconColor = false;
        if (icon) {
          if (typeof interchangeModes[icon] !== "undefined") {
            iconColor = "var(--swk-grey)";
          } else {
            // iconColor = this.getIconColor(this.getBusIcon(icon));
            iconColor = this.getIconColor(icon);
          }
        }

        let altTag = "";
        if(icon){
          if(typeof altTags[icon] !== 'undefined') {
            altTag = altTags[icon];
          } else if (icon) {
            altTag = `Linie ${icon}`
          }

        }

        let vehicleEndPoint = this.getKey(tl, [
          ["trias:TimedLeg", "trias:InterchangeLeg", "trias:ContinuousLeg"],
          "trias:Service",
          "trias:DestinationText",
          "trias:Text",
        ]);

        let vehicleMode = this.getKey(tl, [
          ["trias:TimedLeg", "trias:InterchangeLeg", "trias:ContinuousLeg"],
          "trias:Service",
          "trias:ServiceSection",
          "trias:Mode",
          "trias:Name",
          "trias:Text",
        ]);

        let startTimeFormatted = "";
        let startTime =
          this.getKey(tl, [
            "trias:TimedLeg",
            "trias:LegBoard",
            "trias:ServiceDeparture",
            "trias:TimetabledTime",
          ]) ||
          this.getKey(tl, [
            ["trias:InterchangeLeg", "trias:ContinuousLeg"],
            "trias:TimeWindowStart",
          ]);
        if (startTime) {
          let date = new Date(startTime);
          startTimeFormatted =
            ("00" + date.getHours()).slice(-2) +
            ":" +
            ("00" + date.getMinutes()).slice(-2);
        }
        let startTimeEstimated =
          this.getKey(tl, [
            "trias:TimedLeg",
            "trias:LegBoard",
            "trias:ServiceDeparture",
            "trias:EstimatedTime",
          ])
          console.log(`startTimeEstimated`, startTimeEstimated);

          let startTimeEstimatedFormatted = "";
          if(startTimeEstimated){
            let date = new Date(startTimeEstimated);
            startTimeEstimatedFormatted =
              ("00" + date.getHours()).slice(-2) +
              ":" +
              ("00" + date.getMinutes()).slice(-2);
          }
          let startEstimatedDifferenceMinutes = 0;
          if(startTimeEstimated && startTime){
            startEstimatedDifferenceMinutes = Math.round(Math.abs((new Date(startTimeEstimated).getTime() - new Date(startTime).getTime()) / 1000 / 60));
          }
          console.log(`startEstimatedDifferenceMinutes`, startEstimatedDifferenceMinutes);
        

        let endTimeFormatted = "";
        let endTime =
          this.getKey(tl, [
            "trias:TimedLeg",
            "trias:LegAlight",
            "trias:ServiceArrival",
            "trias:TimetabledTime",
          ]) ||
          this.getKey(tl, [
            ["trias:InterchangeLeg", "trias:ContinuousLeg"],
            "trias:TimeWindowEnd",
          ]);
        if (endTime) {
          let date = new Date(endTime);
          endTimeFormatted =
            ("00" + date.getHours()).slice(-2) +
            ":" +
            ("00" + date.getMinutes()).slice(-2);
        }
        let endTimeEstimated =
          this.getKey(tl, [
            "trias:TimedLeg",
            "trias:LegAlight",
            "trias:ServiceArrival",
            "trias:EstimatedTime",
          ])

        let endTimeEstimatedFormatted = "";
          if(endTimeEstimated){
            let date = new Date(endTimeEstimated);
            endTimeEstimatedFormatted =
              ("00" + date.getHours()).slice(-2) +
              ":" +
              ("00" + date.getMinutes()).slice(-2);
          }
          let endEstimatedDifferenceMinutes = 0;
          if(endTimeEstimated && endTime){
            endEstimatedDifferenceMinutes = Math.round(Math.abs((new Date(endTimeEstimated).getTime() - new Date(endTime).getTime()) / 1000 / 60));
          }


        //
        let startPoint =
          this.getKey(tl, [
            "trias:TimedLeg",
            "trias:LegBoard",
            "trias:StopPointName",
            "trias:Text",
          ]) ||
          this.getKey(tl, [
            ["trias:InterchangeLeg", "trias:ContinuousLeg"],
            "trias:LegStart",
            "trias:LocationName",
            "trias:Text",
          ]);
        let endPoint =
          this.getKey(tl, [
            "trias:TimedLeg",
            "trias:LegAlight",
            "trias:StopPointName",
            "trias:Text",
          ]) ||
          this.getKey(tl, [
            ["trias:InterchangeLeg", "trias:ContinuousLeg"],
            "trias:LegEnd",
            "trias:LocationName",
            "trias:Text",
          ]);

        let intermediates = this.getKey(
          tl,
          ["trias:TimedLeg", "trias:LegIntermediates"],
          true
        );
        console.log(`intermediates`, intermediates);
        let intermediateNames = [];
        if (intermediates) {
          intermediateNames = intermediates
            .map((el) => this.getKey(el, ["trias:StopPointName", "trias:Text"]))
            .filter((el) => el);
        }

        // involved stoppoints START
        let involvedStopPointsIds = [];
        //start
        involvedStopPointsIds.push(
          this.getKey(tl, [
            "trias:TimedLeg",
            "trias:LegBoard",
            "trias:StopPointRef",
          ]) ||
            this.getKey(tl, [
              ["trias:InterchangeLeg", "trias:ContinuousLeg"],
              "trias:LegStart",
              "trias:StopPointRef",
            ])
        );

        //end
        involvedStopPointsIds.push(
          this.getKey(tl, [
            "trias:TimedLeg",
            "trias:LegAlight",
            "trias:StopPointRef",
          ]) ||
            this.getKey(tl, [
              ["trias:InterchangeLeg", "trias:ContinuousLeg"],
              "trias:LegEnd",
              "trias:StopPointRef",
            ])
        );

        //intermediates
        if (intermediates) {
          involvedStopPointsIds = involvedStopPointsIds.concat(
            intermediates.map((el) => this.getKey(el, ["trias:StopPointRef"]))
          );
        }
        console.log(`involvedStopPointsIds`, involvedStopPointsIds);
        console.log(`situations`, situations);

        let situationsForLeg = [];
        if (situations && situations.length) {
          for (let situation of situations) {
            let stopPoints = this.getKey(
              situation,
              ["siri:Affects", "siri:StopPoints", "siri:AffectedStopPoint"],
              true
            );
            console.log(`stopPoints`, stopPoints);

            if (!stopPoints || !stopPoints.length) {
              if (situation["siri:Summary"] && situation["siri:Detail"]) {
                situationsForLeg.push({
                  title: situation["siri:Summary"]
                    ? situation["siri:Summary"]
                    : "",
                  description: situation["siri:Detail"],
                });
              }
            } else {
              stopPoints = stopPoints.map((el) => el["siri:StopPointRef"]);
              console.log(`stopPoints`, stopPoints);
              if (involvedStopPointsIds.length) {
                // console.log(`stopPoints.filter(sp => involvedStopPointsIds.includes(sp)).length`, stopPoints.filter(sp => involvedStopPointsIds.includes(sp)));
                const involvedStopPointsIdsJoinedString =
                  involvedStopPointsIds.join("");
                if (
                  stopPoints.filter((sp) =>
                    involvedStopPointsIdsJoinedString.includes(sp)
                  ).length
                ) {
                  if (situation["siri:Summary"] && situation["siri:Detail"]) {
                    situationsForLeg.push({
                      title: situation["siri:Summary"]
                        ? situation["siri:Summary"]
                        : "",
                      description: situation["siri:Detail"],
                    });
                  }
                }
              }
            }
          }
        }
        console.log(`situationsForLeg`, situationsForLeg);

        // involved stoppoints END

        let duration = this.getKey(tl, [
          ["trias:InterchangeLeg", "trias:ContinuousLeg"],
          "trias:Duration",
        ]);
        if (!duration && startTime && endTime) {
          let date1 = new Date(startTime);
          let date2 = new Date(endTime);
          let diff = date2.getTime() - date1.getTime();
          duration = this.millisecondsToHHMM(diff);

          //start - end
        } else if (duration) {
          duration = this.parseDuration(duration);
          //parseTime
        }

        let id = this.getKey(tl, ["trias:LegId"]);

        let durationStdMin = this.durationToStdMin(duration);

        let legKeys = Object.keys(tl);
        //timedleg -> fahrplan wie bus etc
        //interchange -> bushaltestelle umsteigen etc.
        //continousleg -> immerverfügbare transportmittel wie auto, taxi etc?
        let mode = legKeys.filter((key) =>
          [
            "trias:TimedLeg",
            "trias:InterchangeLeg",
            "trias:ContinuousLeg",
          ].includes(key)
        )[0];

        let borderColor = iconColor ? iconColor : "var(--swk-grey)";

        return {
          id,
          icon,
          iconSrc,
          iconContrastSrc,
          iconColor,
          borderColor,
          startTime,
          startTimeFormatted,
          startTimeEstimatedFormatted,
          startEstimatedDifferenceMinutes,
          endTime,
          endTimeFormatted,
          endTimeEstimatedFormatted,
          endEstimatedDifferenceMinutes,
          startPoint,
          endPoint,
          intermediates: intermediateNames,
          duration,
          durationStdMin,
          vehicleMode,
          vehicleEndPoint,
          mode,
          involvedStopPointsIds,
          situationsForLeg,
          altTag
        };
      });
    }
    console.log(`result after parse`, result);
    return result;
  }

  parseDirectionsAPIBody(jsonBody, departureArrivalMode, time) {
    console.log(`jsonBody`, jsonBody);
    let title = jsonBody.mode == "walking" ? "Zu Fuß" : "Fahrrad";
    let icon = jsonBody.mode == "walking" ? "walk" : "cycle";
    let durationS = this.getKey(jsonBody, [
      "routes",
      0,
      "legs",
      0,
      "duration",
      "value",
    ]);
    let duration = this.secondsToHHMM(durationS);
    let durationStdMin = this.durationToStdMin(duration);
    let iconSrc = this.getBase64(
      this.getBusIcon(jsonBody.mode == "walking" ? "walk" : "cycle")
    );
    let iconContrastSrc = this.getBase64(
      this.getBusIcon(jsonBody.mode == "walking" ? "walk" : "cycle", true)
    );
    let link = `https://www.google.com/maps/dir/?api=1&origin=${jsonBody["origin"]}&destination=${jsonBody["destination"]}&travelmode=${jsonBody["mode"]}`;
    console.log("parseDirectionsAPIBody");
    // console.log(`time`, time);
    console.log(`durationS`, durationS);
    let currentTimeMS = new Date(time).getTime();
    console.log(`currentTimeMS`, currentTimeMS);
    console.log(`departureArrivalMode`, departureArrivalMode);
    let startTimeMS =
      departureArrivalMode == "Abfahrt"
        ? currentTimeMS
        : currentTimeMS - durationS * 1000;
    return {
      title,
      duration,
      durationS,
      durationStdMin,
      startTimeMS,
      icon,
      iconSrc,
      iconContrastSrc,
      link,
      zeitenFrom: title,
      mode: "alternative",
    };
  }

  convertTime(timeString) {
    let date = new Date(timeString);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    return hours + ":" + ("00" + minutes).slice(-2);
  }
  parseDuration(timeString) {
    let durationRegex =
      /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;
    let matches = timeString.match(durationRegex);
    let result = {
      sign: matches[1] === undefined ? "+" : "-",
      years: matches[2] === undefined ? 0 : matches[2],
      months: matches[3] === undefined ? 0 : matches[3],
      weeks: matches[4] === undefined ? 0 : matches[4],
      days: matches[5] === undefined ? 0 : matches[5],
      hours: matches[6] === undefined ? 0 : matches[6],
      minutes: matches[7] === undefined ? 0 : matches[7],
      seconds: matches[8] === undefined ? 0 : matches[8],
    };
    let formattedString =
      "" +
      (result.days ? result.days + " T " : "") +
      (result.hours + ":" + ("00" + result.minutes).slice(-2)) +
      " h";
    return formattedString;
  }
  getKey(el, path, resultShouldBeArray = false) {
    let copy = JSON.parse(JSON.stringify(el));

    for (let key of path) {
      if (Array.isArray(key)) {
        let inter = key.filter((el) => typeof copy[el] !== "undefined");
        if (inter.length) {
          key = inter[0];
        } else {
          return false;
        }
      }
      if (
        typeof copy[key] !== "undefined" &&
        copy[key] &&
        copy[key] != "" &&
        (!Array.isArray(copy[key]) || copy[key].length)
      ) {
        copy = copy[key];
      } else {
        return false;
      }
    }
    if (resultShouldBeArray && !Array.isArray(copy)) {
      copy = [copy];
    }
    return copy;
  }
  millisecondsToHHMM(diff) {
    return (
      ("00" + ((diff / (1000 * 60 * 60)) % 24).toFixed(0)).slice(-2) +
      ":" +
      ("00" + ((diff / (1000 * 60)) % 60).toFixed(0)).slice(-2)
    );
  }
  secondsToHHMM(diff) {
    return (
      ("00" + Math.floor(diff / (60 * 60)).toFixed(0)).slice(-2) +
      ":" +
      ("00" + Math.round(diff / 60).toFixed(0)).slice(-2)
    );
  }
  millisecondsToStdMin(diff) {
    let [std, min] = [
      Math.floor((diff / (1000 * 60 * 60)) % 24),
      Math.floor((diff / (1000 * 60)) % 60),
    ];
    let string = "";
    if (std) {
      string += std.toFixed(0) + " h ";
    }
    string += min.toFixed(0) + " min ";
    return string;
  }
  durationToStdMin(duration) {
    let split = duration.split(":");
    if (split.length != 2) return "";
    let string = "";
    if (Number.parseInt(split[0])) {
      string += Number.parseInt(split[0]) + " h ";
    }
    string += Number.parseInt(split[1]) + " min ";
    return string;
  }
  getBusIcon(name, contrast = false) {
    if(!name) return "";
    if (name) {
      name = ("" + name).replace("/", "-");
    }
    if(BusIcons[name] && BusIcons[name][contrast ? "contrast" : "normal"]) {
      return BusIcons[name][contrast ? "contrast" : "normal"];
    } else {
      return undefined
    }
  }
  getIconColor(name) {
    try {
      if (name) {
      name = ("" + name).replace("/", "-");
    }
    if(typeof BusIconColor[name] !== "undefined") {
      return BusIconColor[name];
      // ['normal']
    } else {
      return "var(--swk-primary)";
    }

      // let doc = new DOMParser().parseFromString(svgIcon, "image/svg+xml");
      // console.log(`doc`, doc);
      // // TODO move this all to after download
      // let colorRes;
      // colorRes = doc.getElementsByTagName("rect");
      // console.log(`colorRes`, colorRes);
      // colorRes = colorRes.length ? colorRes[0].style.fill : "";
      // if (colorRes != "") return colorRes;
      // colorRes = doc.getElementsByTagName("path");
      // colorRes = colorRes.length ? colorRes[0].style.fill : "";
      // if (colorRes != "") return colorRes;
      // colorRes = doc.getElementsByTagName("stroke");
      // colorRes = colorRes.length ? colorRes[0].style.fill : "";
      // if (colorRes != "") return colorRes;
      // return "var(--swk-primary)";
    } catch (error) {
      return "var(--swk-primary)";
    }
  }
  getBase64(svgString) {
    if (typeof svgString === "undefined") {
      return false;
    } else {
      return "data:image/svg+xml;base64," + btoa(svgString);
    }
  }
  //transformation functions end

  getNameFromLocationElement(triasElement) {
    let stop_point_name = triasElement["trias:Location"]["trias:StopPoint"]
      ? triasElement["trias:Location"]["trias:StopPoint"][
          "trias:StopPointName"
        ]["trias:Text"]
      : null;
    let location_name = null;
    if (triasElement["trias:Location"]["trias:LocationName"]) {
      location_name =
        triasElement["trias:Location"]["trias:LocationName"]["trias:Text"];
    }
    if (triasElement["trias:Location"]["trias:PointOfInterest"]) {
      location_name =
        triasElement["trias:Location"]["trias:PointOfInterest"][
          "trias:PointOfInterestName"
        ]["trias:Text"];
    }
    let combined = [stop_point_name, location_name].filter(
      (el) => el != null && el != ""
    );
    return combined.join(", ");
  }
}

export const PND = {};
export default PND;
</script>