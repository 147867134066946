var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show-slide",rawName:"v-show-slide",value:(_vm.expandedObjects[_vm.trip['key']]),expression:"expandedObjects[trip['key']]"}],staticClass:"list-expansion-wrap"},[_c('div',{staticClass:"list-expansion"},[_c('div',{staticClass:"left"},_vm._l((_vm.trip.legs),function(leg,index){return _c('div',{key:index,staticClass:"list-el"},[_c('div',[(!leg.timedLegBefore)?_c('div',{staticClass:"part"},[_c('div',{staticClass:"left"},[_c('div',[_vm._v(" "+_vm._s(leg.startTimeFormatted)+" "),_c('span',{staticClass:"tti-delay font-size-12",class:{
                    'tti-green': leg.startEstimatedDifferenceMinutes < 2,
                    'tti-orange': leg.startEstimatedDifferenceMinutes >= 2 && leg.startEstimatedDifferenceMinutes < 5,
                    'tti-red': leg.startEstimatedDifferenceMinutes >= 5,
                  }},[_vm._v("+"+_vm._s(leg.startEstimatedDifferenceMinutes)+" "),_c('br'),(leg.startEstimatedDifferenceMinutes)?_c('span',{staticClass:"font-size-16"},[_vm._v(_vm._s(leg.startTimeEstimatedFormatted))]):_vm._e()])])]),_c('div',{staticClass:"right no-border"},[_c('div',{staticClass:"border-wrapper"},[_c('div',{staticClass:"border",style:({
                    backgroundColor: leg.beforeColor,
                  })}),_c('div',{staticClass:"border",style:({
                    backgroundColor: leg.borderColor,
                  })})]),_c('div',{staticClass:"point",style:({
                  backgroundColor: leg.iconColor,
                })}),_vm._v(" "+_vm._s(leg.startPoint)+" ")])]):_vm._e(),_c('div',{staticClass:"part middle-part"},[_c('div',{staticClass:"left"}),_c('div',{staticClass:"right",style:({
                borderColor: leg.borderColor,
              })},[(leg.icon)?_c('div',{staticClass:"icon-left"},[(leg.iconSrc)?_c('div',{staticClass:"verlauf-logo-wrapper",style:({'backgroundColor': leg.iconColor})},[_c('img',{staticClass:"bus-icon-normal",attrs:{"src":leg.iconSrc,"alt":leg.altTag}}),_c('img',{staticClass:"bus-icon-contrast",attrs:{"src":leg.iconContrastSrc,"alt":leg.altTag}})]):_c('div',{staticClass:"alternative-logo",class:{
                    'smaller-font': leg.icon && leg.icon.length > 2,
                  }},[_vm._v(" "+_vm._s(leg.icon)+" ")])]):_vm._e(),_c('div',{staticClass:"middle-part-description"},[(leg.vehicleMode)?_c('b',[_vm._v(_vm._s(leg.vehicleMode))]):_vm._e(),(leg.vehicleEndPoint)?_c('span',[_vm._v(" Richtung "+_vm._s(leg.vehicleEndPoint))]):_vm._e(),_c('div',{staticClass:"time-inter",class:{
                    'center-time': leg.mode != 'trias:TimedLeg',
                  }},[(leg.durationStdMin)?_c('span',[_vm._v(_vm._s(leg.durationStdMin))]):_vm._e(),(leg.intermediates && leg.intermediates.length)?_c('span',{staticClass:"zwischenhalte"},[_vm._v(_vm._s(leg.intermediates.length)+" Zwischenhalte")]):_vm._e(),(leg.intermediates && leg.intermediates.length)?_c('div',{staticClass:"right chevron-right",class:{
                      rotate:
                        _vm.forceRefreshExpanded &&
                        _vm.expandedIntermediates[_vm.trip['key'] + leg.id],
                    },attrs:{"tabindex":0,"aria-label":_vm.expandedIntermediates[_vm.trip['key'] + leg.id] ? 'Zwischenhalte ausblenden' : 'Zwischenhalte anzeigen'},on:{"click":function($event){_vm.expandedIntermediates[_vm.trip['key'] + leg.id] =
                        !_vm.expandedIntermediates[_vm.trip['key'] + leg.id];
                      _vm.forceRefreshExpanded++;},"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13)return null;_vm.expandedIntermediates[_vm.trip['key'] + leg.id] =
                        !_vm.expandedIntermediates[_vm.trip['key'] + leg.id];
                      _vm.forceRefreshExpanded++;}}}):_vm._e()]),(leg.intermediates && leg.intermediates.length)?_c('div',{directives:[{name:"show-slide",rawName:"v-show-slide",value:(_vm.expandedIntermediates[_vm.trip['key'] + leg.id]),expression:"expandedIntermediates[trip['key'] + leg.id]"}],staticClass:"intermediates",class:{'intPaddingBottom': _vm.expandedIntermediates[_vm.trip['key'] + leg.id]}},_vm._l((leg.intermediates),function(int){return _c('div',{key:int,staticClass:"el"},[_vm._v(" "+_vm._s(int)+" ")])}),0):_vm._e()]),(leg.situationsForLeg && leg.situationsForLeg.length)?_c('div',_vm._l((leg.situationsForLeg),function(sit,index){return _c('div',{key:index,staticClass:"situations-box"},[_c('div',{staticClass:"left-right"},[_c('div',{staticClass:"leftt"},[_c('exclamation-mark-red')],1),_c('div',{staticClass:"rightt"},[_c('span',{domProps:{"innerHTML":_vm._s(sit.title)}}),(sit.description && sit.description != '')?_c('span',{staticClass:"right chevron-right",class:{
                          rotate:
                            _vm.forceRefreshExpanded &&
                            _vm.expandedIntermediates[_vm.trip['key'] + leg.id + 'sit' + index ],
                        },on:{"click":function($event){_vm.expandedIntermediates[_vm.trip['key'] + leg.id + 'sit' + index ] =
                            !_vm.expandedIntermediates[_vm.trip['key'] + leg.id + 'sit' + index ];
                          _vm.forceRefreshExpanded++;}}}):_vm._e()])]),(sit.description && sit.description != '')?_c('div',{directives:[{name:"show-slide",rawName:"v-show-slide",value:(_vm.expandedIntermediates[_vm.trip['key'] + leg.id + 'sit' + index ]),expression:"expandedIntermediates[trip['key'] + leg.id + 'sit' + index ]"}],staticClass:"expansion",domProps:{"innerHTML":_vm._s(sit.description)}}):_vm._e()])}),0):_vm._e()])]),(!leg.timedLegAfter)?_c('div',{staticClass:"part"},[_c('div',{staticClass:"left"},[_c('div',[_vm._v(" "+_vm._s(leg.endTimeFormatted)+" "),_c('span',{staticClass:"tti-delay font-size-12",class:{
                    'tti-green': leg.endEstimatedDifferenceMinutes < 2,
                    'tti-orange': leg.endEstimatedDifferenceMinutes >= 2 && leg.endEstimatedDifferenceMinutes < 5,
                    'tti-red': leg.endEstimatedDifferenceMinutes >= 5,
                  }},[_vm._v("+"+_vm._s(leg.endEstimatedDifferenceMinutes)+" "),_c('br'),(leg.endEstimatedDifferenceMinutes)?_c('span',{staticClass:"font-size-16"},[_vm._v(_vm._s(leg.endTimeEstimatedFormatted))]):_vm._e()])])]),_c('div',{staticClass:"right no-border"},[_c('div',{staticClass:"border-wrapper"},[_c('div',{staticClass:"border",style:({
                    backgroundColor: leg.borderColor,
                  })}),_c('div',{staticClass:"border",style:({
                    backgroundColor: leg.afterColor,
                  })})]),_c('div',{staticClass:"point",style:({
                  backgroundColor: leg.iconColor,
                })}),_vm._v(" "+_vm._s(leg.endPoint)+" ")])]):_vm._e()])])}),0),_c('div',{staticClass:"right"},[_c('div',{staticClass:"navigate-and-fares"},[_c('a',{attrs:{"href":_vm.trip.navigateToStartLink,"target":"_blank"}},[_vm._v("Zur Starthaltestelle navigieren")]),_c('a',{attrs:{"href":_vm.$parent.$parent.farespage,"target":"_blank"}},[_vm._v("Tarife anzeigen")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }